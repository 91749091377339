import LinearProgress from '@mui/material/LinearProgress';

import React from 'react';

import { compose } from 'redux';
import { Colors } from '../../utils/style/colors';

export default compose(
)(({ isVisible }) => (
	<LinearProgress
		className="progressBar"
		style={{
			position: 'absolute',
			transition: 'all .3s',
			zIndex: 1,
			width: '100%',
			bottom: -6,
			opacity: isVisible ? 1 : 0,
			transform: 'translateY(-5px)',
			borderRadius: 0
		}}
		classes={{
			colorPrimary: Colors.primary(),
			barColorPrimary: Colors.primary(),
		}}
	/>
));