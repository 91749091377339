import Immutable, {List} from 'immutable';
import { LatLng, LatLngBounds } from 'leaflet';

const SiteModel = Immutable.Record({
	id: 0,
	description: '',
	topLeft: [],
	topRight: [],
	bottomLeft: [],
	url: '',
	altitude: 0,
	accountId: 0,
	accountName: '',
	opacity: 0
});

export default class Site extends SiteModel {
	id: number;
	description: string;
	topLeft: [];
	topRight: [];
	bottomLeft: [];
	url: string;
	altitude: number;
	accountId: number;
	accountName: string;
	opacity: number;

	get hasLocation() {
		return (this.get('topLeft') || []).length > 0;
	}
	get bounds() : [][] {
		return [
			this.get('topLeft').map(p => +p),
			this.get('topRight').map(p => +p),
			this.get('bottomLeft').map(p => +p)
		]
	}

	get center() {
		const positions = this.bounds.map(b => new LatLng(b[0], b[1], 0));
		const bounds = new LatLngBounds(positions);
		return Object.values(bounds.getCenter());
	}
	
	filterString() {
		return [
			this.description,
			this.accountName,
			this.id
		].join('').lc();
	}

	clone() {
		return this.set('id', this.id);
	}

	static closestToAltitude(sites: List<Site>, altitude) {
		if(!sites.size) return null;
		const exactMatch = sites.find(s => s.altitude === altitude);
		if(exactMatch) return exactMatch;
		if(altitude === 0) return null;
		
		
		const first = sites.first();
		let bestMatch = {
			site: first,
			diff: Math.abs(+first.get('altitude') - +altitude)
		};
		sites.forEach(site => {
			const diff = Math.abs(+site.get('altitude') - +altitude);
			if(diff < bestMatch.diff) bestMatch = { site, diff };
		});
		return bestMatch.site;
	}

	static create(props) {
		return new Site(props);
	}
}
