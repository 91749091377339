import React, { useEffect } from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { isSupportedBrowser } from '../..';
import { selectCurrentUser } from '../../redux/selectors/app.selectors';
import {consoleWarn, consoleDebug} from "../../utils/log";

let playAudio = null;
const onFocus = () => {
  if(!isSupportedBrowser) return;
  if(playAudio) setTimeout(playAudio);
}
window.addEventListener('focus', onFocus);

function mapDispatchToProps() {
  return {};
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser()
});

const DisconnectedSound = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(({mute}) => {
  if(!isSupportedBrowser) return null;
  const audioRef = React.useRef();

  useEffect(() => {
    if(!audioRef?.current) return;
    playAudio = () => {
      setTimeout(() => {
        audioRef.current?.play().then( 
            (_) => consoleDebug('Starting audio'),
            (e) => consoleWarn(e)
        );
      }, 50);
    };
    window.addEventListener('focus', playAudio);
    playAudio();
    return () => window.removeEventListener('focus', playAudio);
  }, [audioRef]);

  return (
      <div style={{position: 'fixed', zIndex: -100, left: -100, top: -100, width: 0, height: 0}}>
        <audio
            autoPlay
            muted={mute}
            ref={audioRef}
            loop>
          <source src={require('../../assets/sound/disconnected.mp3')} type="audio/mpeg" />
        </audio>
      </div>
  );

});

export default DisconnectedSound;