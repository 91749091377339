import BaseAPI from './BaseAPI';
import SalesItem from "../models/Administration/SalesItem";
import Customer from "../models/Administration/Customer";
import SalesInvoice from "../models/Administration/SalesInvoice";
import type PricingRule from "../models/Administration/PricingRule";

export class AdministrationAPI extends BaseAPI {

    async sendSalesInvoice(salesInvoice, recipient) {
        return this.request(`/sec/administration/salesInvoices/${salesInvoice.id}/send`, 'POST', {
            recipient
        });
    }
    async getPricingRules() {
        return this.request('/sec/administration/pricingRules', 'GET');
    }
    async deletePricingRule(id) {
        return this.request(`/sec/administration/pricingRules/${id}`, 'DELETE');
    }
    async savePricingRule(pricingRule) {
        return this.request('/sec/administration/pricingRules', pricingRule.id ? 'PUT' : 'POST', pricingRule);
    }
    async getCountries() {
        return this.request('/sec/administration/country', 'GET');
    }
    async getCustomerDetails(customerName) {
        return this.request(`/sec/administration/customers/${customerName}`, 'GET');
    }
    async getGeneralLedgerAccounts() {
        return this.request('/sec/administration/ledger', 'GET');
    }
    async getPaymentTermsTemplates() {
        return this.request('/sec/administration/paymentTermsTemplate', 'GET');
    }
    async getTaxTemplates() {
        return this.request('/sec/administration/taxTemplate', 'GET');
    }
    async getSubscriptions() {
        return this.request('/sec/administration/subscriptions', 'GET');
    }
    async saveSubscription(subscription) {
        return this.request('/sec/administration/subscriptions', subscription.id ? 'PUT' : 'POST', subscription.toJS());
    }
    async downloadSalesInvoice(salesInvoice: SalesInvoice) {
        return this.requestPlain(`/sec/administration/salesInvoices/${salesInvoice.id}/download`, 'GET', undefined, true);
    }
    async downloadSalesInvoiceSpecification(salesInvoice: SalesInvoice) {
        return this.requestPlain(`/sec/administration/salesInvoices/${salesInvoice.id}/specification/download`, 'GET', undefined, true);
    }
    async getSalesInvoices() {
        return this.request('/sec/administration/salesInvoices', 'GET');
    }
    async getSalesInvoice(name) {
        return this.request(`/sec/administration/salesInvoices/${name}`, 'GET');
    }
    async saveSalesInvoice(salesInvoice) {
        return this.request('/sec/administration/salesInvoices', salesInvoice.get('id') ? 'PUT' : 'POST', salesInvoice.toJS());
    }
    async submitSalesInvoice(salesInvoice) {
        return this.request(`/sec/administration/salesInvoices/${salesInvoice.id}/submit`, 'POST');
    }
    
    async calculatePrices(salesInvoice) {
        return this.request('/sec/administration/salesInvoices/calculate', 'PUT', salesInvoice.toJS());
    }
    async getCustomers() {
        return this.request('/sec/administration/customers', 'GET');
    }

    async deleteCustomer(customer: Customer) {
        await this.request('/sec/administration/customers/' + customer.id, 'DELETE');		
    }
    async saveCustomer(customer, billing, shipping, contact) {
        try {
            const body = { customer, billing, shipping, contact };
            await this.request('/sec/administration/customers', customer.id ? 'PUT' : 'POST', body);
            return true;
        } catch {
            return false;
        }
    }

    async getSalesItems() {
        return this.request('/sec/administration/salesItems', 'GET');
    }
    async getSalesItem(itemId) {
        return this.request(`/sec/administration/salesItems/${itemId}`, 'GET');
    }

    async saveSalesItem(salesItem: SalesItem, pricingRule: ?PricingRule) {
        try {
            await this.request('/sec/administration/salesItems', salesItem.id ? 'PUT' : 'POST', {
                salesItem: salesItem.toJS(),
                pricingRule: pricingRule?.toJS()
            });
            return true;
        } catch {
            return false;
        }
    }

    async deleteSalesItem(salesItem: SalesItem) {
        await this.request('/sec/administration/salesItems/' + salesItem.id, 'DELETE');
    }
}
