import muiTheme from './muiTheme';

export class Responsive {

	static _query(prop, size, css) {
		return `
			@media (${prop}: ${size}px) {
				${css}
			}
		`;
	}
	static _maxWidth(size, css) {
		return this._query('max-width', size, css);
	}
	static _minWidth(size, css) {
		return this._query('min-width', size, css);
	}

	static desktop(css) {
		return Responsive._minWidth(muiTheme().breakpoints.values.md, css);
	}

	static compact(css) {
		return Responsive._maxWidth(muiTheme().breakpoints.values.lg, css);
	}

	static mobile(css) {
		return Responsive._maxWidth(muiTheme().breakpoints.values.sm, css);
	}

	static isCompact() {
		return window.innerWidth < muiTheme().breakpoints.values.lg;
	}

	static isMobile() {
		return window.innerWidth < muiTheme().breakpoints.values.sm;
	}

}