import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../utils/style/colors';
import { Text } from '../../utils/style/texts';
import { ExternalLinkIcon } from '../../utils/icons';

export default class ExternalLink extends React.PureComponent {
  render() {

    return (
      <Text>
        <Link {...this.props} target={this.props.target || "_blank"}>
          <span>{this.props.children}</span>
          <ExternalLinkIcon size={.6} />
        </Link>
      </Text>
    );
  }
}

const Link = styled.a`
	transition: all .1s;
	color: ${Colors.gray50};
	text-decoration: none;
	border-bottom: 1px solid transparent;
	&:hover {
		color: ${Colors.gray60};
		border-bottom: 1px solid ${Colors.gray40};
	}
	
	span { 
		margin-right: 5px;
		vertical-align: middle; 
	}
`;