export const ADMINISTRATION = 'ADMINISTRATION';

export const GET_CUSTOMERS = `${ADMINISTRATION}/GET_CUSTOMERS`;
export const DELETE_CUSTOMER = `${ADMINISTRATION}/DELETE_CUSTOMER`;
export const GET_PRICING_RULES = `${ADMINISTRATION}/GET_PRICING_RULES`;
export const DELETE_PRICING_RULE = `${ADMINISTRATION}/DELETE_PRICING_RULE`;
export const PRICING_RULES_CHANGED = `${ADMINISTRATION}/PRICING_RULES_CHANGED`;
export const GET_SALES_ITEMS = `${ADMINISTRATION}/GET_SALES_ITEMS`;
export const GET_SALES_INVOICES = `${ADMINISTRATION}/GET_SALES_INVOICES`;
export const GET_GENERAL_LEDGER_ACCOUNTS = `${ADMINISTRATION}/GET_GENERAL_LEDGER_ACCOUNTS`;
export const GET_TAX_TEMPLATES = `${ADMINISTRATION}/GET_TAXES`;
export const GET_PAYMENT_TERMS_TEMPLATES = `${ADMINISTRATION}/GET_PAYMENT_TERMS`;
export const GENERAL_LEDGER_ACCOUNTS_CHANGED = `${ADMINISTRATION}/GENERAL_LEDGER_ACCOUNTS_CHANGED`;
export const SALES_ITEMS_CHANGED = `${ADMINISTRATION}/SALES_ITEMS_CHANGED`;
export const SALES_INVOICES_CHANGED = `${ADMINISTRATION}/SALES_INVOICES_CHANGED`;
export const CUSTOMERS_CHANGED = `${ADMINISTRATION}/CUSTOMERS_CHANGED`;
export const TAX_TEMPLATES_CHANGED = `${ADMINISTRATION}/TAX_TEMPLATES_CHANGED`;
export const PAYMENT_TERMS_TEMPLATES_CHANGED = `${ADMINISTRATION}/PAYMENT_TERMS_TEMPLATES_CHANGED`;
export const GET_SUBSCRIPTIONS = `${ADMINISTRATION}/GET_SUBSCRIPTIONS`;
export const SUBSCRIPTIONS_CHANGED = `${ADMINISTRATION}/SUBSCRIPTIONS_CHANGED`;