import UISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import React from 'react';

import styled from 'styled-components';
import {Secondary, Text} from '../../utils/style/texts';
import Checkbox from "./Checkbox";

const Select = props => {
    const ref = React.useRef();

    const {
        style,
        classes,
        editable,
        value,
        label,
        onChange,
        name,
        selectAllLabel,
        multiple,
        fullWidth,
        helperText,
        placeholder,
        onValueNotFound,
        renderOnValueNotFound,
        ...other
    } = props;
    const items = props.items?.toArray ? props.items.toArray() : props.items;

    if (onValueNotFound && Array.isArray(items) && (value || value === 0)) {
        if (!items.find(i => i.value === value)) {
            setTimeout(onValueNotFound);
            if(renderOnValueNotFound === false) return null;
        }
    }

    const allSelected = selectAllLabel ? value.length === Object.keys(items).length : false;
    const shrink = multiple ? value.length : !!value;

    return (
        <Wrapper
            style={style}
            editable={editable !== false}
            shrink={!!shrink}
            fullWidth={fullWidth}
        >
            <FormControl fullWidth>
                <InputLabel shrink={!!shrink} htmlFor={(ref || {}).current}>
                    {label}
                </InputLabel>
                <UISelect
                    value={value || ''}
                    multiple={!!multiple}
                    displayEmpty={props.displayEmpty || !!placeholder}
                    onChange={(e, source) => {
                        if(source.props.value === '#all') return;
                        if(Array.isArray(e.target.value)) {
                            onChange(e.target.value.filter(v => !!v));
                        } else {
                            onChange(e.target.value);
                        }
                    }}
                    variant="standard"
                    input={<Input name={name} inputRef={ref} />}
                    {...other}>
                    {placeholder && (
                        <MenuItem value="" disabled><Secondary>{placeholder}</Secondary></MenuItem>
                    )}
                    {!!selectAllLabel && !!multiple && (
                        <MenuItem value="#all" onClick={() => onChange(allSelected ? [] : items.map(i => i.value))}>
                            <Checkbox checked={allSelected}/>
                            <Text>{selectAllLabel}</Text>
                        </MenuItem>
                    )}
                    {items.map((item, i) => (
                        <MenuItem key={i} value={item.value} disabled={item.disabled}>
                            {!!multiple && (
                                <Checkbox
                                    checked={value.indexOf(item.value) >= 0}
                                    indeterminate={allSelected}
                                    color={allSelected ? 'action' : 'secondary'}
                                />
                            )}
                            <Text>{item.label}</Text>
                        </MenuItem>
                    ))}
                </UISelect>
                {helperText && (
                    <FormHelperText>{helperText}</FormHelperText>
                )}
            </FormControl>
        </Wrapper>
    );
}

export default Select;

export const Wrapper = styled.div`
    min-width: 150px;
    height: 50px;
    margin-bottom: 10px;
    display: inline-block;
    ${props => props.fullWidth && `
		width: 100%;
	`};

    label {
        transform: translate(0, 19px) scale(1);
        ${props => props.shrink && `
			transform: translate(0) scale(0.75);
		`};
    }

    ${props => !props.editable && `
		pointer-events: none;
		*:after,*:before { opacity: 0; }
		svg { display: none !important; }
	`};
`;