import {
    TEST_SCENARIO_ACTION,
    SET_IS_LOADING,
    SAVE_SCENARIO_TEMPLATE,
    GET_SCENARIO_TEMPLATES,
    SCENARIO_TEMPLATES_CHANGED,
} from './constants';

export const testScenario = scenario => ({ type: TEST_SCENARIO_ACTION, scenario});
export const setIsLoading = isLoading => ({ type: SET_IS_LOADING, isLoading});
export const saveScenarioTemplate = (name, scenario, callback) => ({type: SAVE_SCENARIO_TEMPLATE, name, scenario, callback});
export const getScenarioTemplates = () => ({type: GET_SCENARIO_TEMPLATES});
export const scenarioTemplatesChanged = templates => ({type: SCENARIO_TEMPLATES_CHANGED, templates});