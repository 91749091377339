import { fromJS, Map } from 'immutable';
import { SET_SELECTED_SITES, WS_DATA_SITE, WS_DATA_SITES } from '../constants/site.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';
import Site from '../../utils/models/Site';

export const initialState = fromJS({
	data: {},
	fetched: false,
	selectedSite: null
});

export default function siteReducer(state: Map = initialState, action) {
	let sites = state.get('data');
	switch (action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_SITE:
			return state.setIn(['data', +action.data.id], new Site(action.data));
		case WS_DATA_SITES:
			sites = Map(action.data.map(d => new Site(d)).map(d => [+d.id, d]));
			return state.set('data', sites).set('fetched', true);
		case SET_SELECTED_SITES:
			return state.set('selectedSite', action.site);
		default:
			return state;
	}
}