import { put, select, takeLatest } from 'redux-saga/effects';
import { SAVE_BEACON, SELECT_BEACON } from '../constants/beacon.constants';
import { BeaconAPI } from '../../utils/API/BeaconAPI';
import { SET_SELECTED_SITES } from '../constants/site.constants';
import { selectSelectedSite } from '../selectors/site.selector';
import { selectSelectedBeacon } from '../selectors/beacon.selector';
import { selectBeacon } from '../actions/beacon.actions';
import { getNavigator } from '../../Navigator';
import { getActivePage } from '../../utils/functions';

const api = new BeaconAPI();

function* saveBeacon({beacon}) {
	yield api.updateBeacon(beacon);
}
function* selectBeaconPage({beacon}) {
	const page = getActivePage();
	if(page === 'beacons') {
		getNavigator()(beacon ? `/beacons/${beacon.id}` : '/beacons');
	}
	yield Promise.resolve();
}

function* deselectBeacon() {
	const beacon = yield select(selectSelectedBeacon());
	if(!beacon) return;
	const site = yield select(selectSelectedSite());
	if(!site) return;
	if(beacon.siteId !== site.id) {
		yield put(selectBeacon());
	}
}



export const beaconSagas = [
	takeLatest(SAVE_BEACON, saveBeacon),
	takeLatest(SET_SELECTED_SITES, deselectBeacon),
	takeLatest(SELECT_BEACON, selectBeaconPage),
];