import Immutable from 'immutable';

const ModuleModel = Immutable.Record({
	id: 0,
	key: '',
	color: '',
	parentId: 0,
	enabled: false,
	visible: false,
	type: 1
});

export default class Module extends ModuleModel {
	id: number;
	key: string;
	color: string;
	parentId: number;
	enabled: boolean;
	visible: boolean;
	type: number;

	get forCustomerAccounts() {
		return this.get('type') === 1;
	}
	get forAccountManagers() {
		return this.get('type') === 2;
	}

	getName(t) {
		return t(`module.name.${this.id}`) || this.name;
	}
	getDescription(t) {
		return t(`module.description.${this.id}`) || this.description;
	}
}

export const moduleIds = {
	TND_SignInAndOut: 7,
	Viggo_DockingAttendance: 8,
	Viggo_Buttons: 11,
	SequrixAlarm: 12,
	Credits: 15, //voice message
	Geofences: 21,
	Beacons: 22,
	Indoor: 23,
	Sites: 23,
	DeviceActivityReport: 24,
	Teams: 25,
	EgdomAlarm: 26,
	// PresenceRegistration: 27, //merged with geofences
	PreviousPositions: 29,
	Safeguard: 32,
	TrackingPermanent: 33,
	TrackingScenario: 34,
	LeaveVoiceMessage: 39,
	AssistanceRequests: 40,
	ScenarioTile: 41,
	LowPrioSIA: 44,
	Webhook: 57,
	MultiTenant: 60,
	EchoService: 61,
	
	EditAccounts_AM: 45,
	Beacons_AM: 46,
 	EditBeacons_AM: 47,
	Sites_AM: 48,
	EditSites_AM: 49,
	SimCards_AM: 50,
	Financial_AM: 51,
	Wiki_AM: 52,
	Reporting_AM: 53,
	EditDevices_AM: 54,
	Credits_AM: 55,
	EchoService_AM: 62,
	Scenarios_AM: 63,
	Multitenant_AM: 65
};

export const accountManagerModules = [
	moduleIds.Beacons,
	moduleIds.Sites,
	moduleIds.AssistanceRequests,
	moduleIds.Credits,
	moduleIds.Geofences,
	moduleIds.PreviousPositions,
	moduleIds.DeviceActivityReport,
	moduleIds.Teams,
	moduleIds.TrackingScenario,
	moduleIds.TrackingPermanent,
	moduleIds.ScenarioTile,
	moduleIds.Credits_AM,
	moduleIds.MultiTenant,
	moduleIds.Webhook
];