import UISnackbar from '@mui/material/Snackbar';

import React from 'react';
import {Text} from '../../utils/style/texts';
import {Responsive} from '../../utils/style/responsive';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {compose} from 'redux';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import TextButton from '../Controls/TextButton';
import {
    selectCurrentUser,
    selectDisconnectedSoundEnabled,
    selectIsConnected, selectIsOnline
} from '../../redux/selectors/app.selectors';
import DisconnectedSound from "../DisconnectedSound";
import {Alert} from "@mui/material";


let reloadInterval = null;
const reconnectTime = 5;
const DisconnectedSnackbar = (({user, isOnline, isConnected, enabled}) => {
    const [t] = useTranslation();
    const show = !!user && (!isConnected || !isOnline);
    const reconnect = () => window.dispatchEvent(new Event('focus'));
    
    React.useEffect(() => {
        if(reloadInterval) {
            clearInterval(reloadInterval);
        }
        reloadInterval = setInterval(() => {
            if(!show) return;
            if(user.isPresentation) {
                window.location.reload();
            } else {
                reconnect();
            }
        }, reconnectTime * 1000);
    }, [show, user.isPresentation]);
    
    return (
        <>
            <DisconnectedSound mute={!show || !enabled} />
            <StyledSnackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={show}>
                <Alert
                    action={(
                        <TextButton size="small" onClick={() => reconnect()}>
                            {t('reconnectNow')}
                        </TextButton>
                    )}
                    severity="warning">
                    <Text>{t('lostLiveConnection')}</Text>
                </Alert>
            </StyledSnackbar>
        </>
    );
});

export const StyledSnackbar = styled(UISnackbar)`
  ${Responsive.desktop`
		transform: translateY(-30px);
	`}
`;

function mapDispatchToProps(_) {
    return {};
}

const mapStateToProps = createStructuredSelector({
    enabled: selectDisconnectedSoundEnabled(),
    isConnected: selectIsConnected(),
    isOnline: selectIsOnline(),
    user: selectCurrentUser()
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)    
)(DisconnectedSnackbar);