export const scenarioTriggers = {
    allAlarmTypes: 'allAlarmTypes',
    genericAlarm: 'genericAlarm',
    sosButton: 'sosButton',
    manDown: 'manDown',
    ripcord: 'ripcord',
    timer: 'timer',
    configError: 'configError',
    alarmClosed: 'alarmClosed',
    assistanceRequestClosed: 'assistanceRequestClosed',
    connectionChanged: 'connectionChanged',
    quickButton: 'quickButton',
    beaconBattery: 'beaconBattery',
    geofenceEntered: 'geofenceEntered',
    geofenceExited: 'geofenceExited',
    docked: 'docked',
    undocked: 'undocked',
    lowBattery: 'lowBattery',
    smsMessage: 'smsMessage',
    webhook: 'webhook',
    ioChange: 'ioChange',
    email: 'email',
    espa: 'espa',
    multiTenant: 'multiTenant',
    messageNotAcknowledged: 'messageNotAcknowledged',
    checkAssistanceRequestResponses: 'checkAssistanceRequestResponses',
    arcAlarm: 'arcAlarm',
    scenarioDisabled: 'scenarioDisabled',
};