import {fromJS, Map} from 'immutable';
import Device from '../../utils/models/Device';
import {
	SELECT_DEVICE,
	TOGGLE_DEVCON,
	WS_DATA_DEVICE,
	WS_DATA_DEVICE_PING,
	WS_DATA_DEVICES,
	SET_TRACK_SINGLE_DEVICE_ID
} from '../constants/device.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	data: {},
	fetched: false,
	selectedDevice: null,
	trackSingleDeviceId: null,
	devConOpen: false
});

export default function deviceReducer(state: Map = initialState, action) {
	let devices = state.get('data');
	
	let dev;
	switch (action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_DEVICE_PING:
			if(!action.data) return state;
			dev = state.get('data').find(d => d.id === action.data.id);
			dev = dev.set('lastUpdate', action.data.lastUpdate).set('status', action.data.status);
			if(state.get('selectedDevice') && dev.id === state.get('selectedDevice').id)
				state = state.set('selectedDevice', dev);
			return state.setIn(['data', action.data.id], dev);
		case WS_DATA_DEVICE:
			if(!action.data) return state;
			dev = new Device(action.data);
			if(state.get('selectedDevice') && dev.id === state.get('selectedDevice').id)
				state = state.set('selectedDevice', dev);
			return state.setIn(['data', action.data.id], dev);
		case WS_DATA_DEVICES:
			devices = Map(action.data.map(d => new Device(d)).map(d => [d.id, d]));
			return state
				.set('data', devices)
				.set('fetched', true);
		case SELECT_DEVICE:
			return state
				.set('selectedDevice', action.device)
				.set('devConOpen', action.device ? state.get('devConOpen') : false);
		case SET_TRACK_SINGLE_DEVICE_ID:
			return state
				.set('trackSingleDeviceId', action.deviceId);
		case TOGGLE_DEVCON:
			return state.set('devConOpen', action.isOpen);
		default:
			return state;
	}
}