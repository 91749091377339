import BaseAPI from './BaseAPI';
import Beacon from '../models/Beacon';


export class BeaconAPI extends BaseAPI {

	async updateBeacon(beacon: Beacon) {
		return await this.request('/sec/beacon', 'PUT', beacon.toJS());
	}

}
