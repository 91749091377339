import { put, takeLatest } from 'redux-saga/effects';
import { GET_PRESENTATION_PAGE } from './constants';
import { urlChanged } from './actions';
import { AdminAPI } from '../../utils/API/AdminAPI';

var api = new AdminAPI();

function *getURL({pageType, opts}) {

  const url = yield api.getPresentationPage(pageType, opts);
  yield put(urlChanged(url));

}

export default function* sendSaga() {
	yield takeLatest(GET_PRESENTATION_PAGE, getURL);
}
