import {DEVICES} from "../redux/constants/device.constants";
import {ADMIN_PAGE} from "../containers/AdminPage/constants";
import {APP} from "../redux/constants/app.constants";
import * as Sentry from '@sentry/browser';
import {fromJS} from "immutable";
import {consoleError} from "./log";

// noinspection JSUnusedGlobalSymbols
export const sentryOptions = {
	enableTracing: false,
	autoSessionTracking: false,
	getUserContext: state => {
		state = fromJS(state || {}).toJS();
		return state[APP]?.currentUser;
	},
	filterBreadcrumbActions: action => {
		try {
			if(action.noBreadcrumb) return;
			return JSON.stringify(action).indexOf('password') === -1;
		} catch (e) {
			consoleError(e);
			Sentry.captureException(e);
			return false;
		}
	},
	stateTransformer: state => {
		state = fromJS(state || {}).toJS();
		delete state[APP]?.theme;
		delete state[ADMIN_PAGE];
		if(state[DEVICES]?.data && Object.values(state[DEVICES]?.data)?.length > 20) {
			state[DEVICES].data = Object.values(state[DEVICES].data).map(d => d.id);
		}
		return state;
	},
	actionTransformer: action => {
		let json = "[circular structure]";
		try {
			json = JSON.stringify(action);
		} catch (e) {
			//ignore
		}
		if(json.length > 2000) {
			return {
				type: action.type,
				truncatedPayload: json.substring(0, 100)
			};
		}
		return action;
	}
};