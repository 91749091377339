import { createSelector } from 'reselect';
import { GEOFENCES } from '../constants/geofence.constants';
import { initialState } from '../reducers/geofence.reducer';
import {ADMIN_PAGE} from "../../containers/AdminPage/constants";
import {fromJS, List} from "immutable";

const selectState = state => state[GEOFENCES] || initialState;
const selectAdminState = state => state[ADMIN_PAGE] || fromJS({});

export const selectGeofences = () => createSelector([selectState, selectAdminState], (state, adminState) => {
    let geofences = state.get('data').toList();
    if(!geofences?.size) {
        geofences = List(adminState.get('geofences'));
    }
    return geofences.sortBy(g => g.accountName + g.name);
});

export const selectPresence = () => createSelector(selectState, state => state.get('presence'));
export const selectDetailedGeofence = () => createSelector(selectState, state => state.get('detailedGeofence'));
export const selectLoadingDetailedGeofence = () => createSelector(selectState, state => state.get('loading'));
export const selectGeofencesFetched = () => createSelector(selectState, state => state.get('fetched'));