import BaseAPI from './BaseAPI';
import Account from '../models/Account';
import Device from '../models/Device';
import jwtDecode from 'jwt-decode';
import User from '../models/User';
import Prom from '../models/Prom';
import {deviceTypes} from '../models/DeviceType';
import Beacon from '../models/Beacon';
import Site from '../models/Site';
import * as env from '../../env';
import {getStore} from '../../configureStore';
import {newAdminToken} from '../../redux/actions/app.actions';
import Geofence from "../models/Geofence";
import Team from "../models/Team";
import ARC from "../models/ARC";
import {List} from "immutable";

export class AdminAPI extends BaseAPI {

    async parseTwigMessages(messages, dates) {
        return await this.request('/sec/admin/twig/parse', 'POST', {messages, dates: dates.map(d => d.unix())});
    }
    async saveARC(arc) {
        if(arc.id) {
            return await this.request(`/sec/arc`, 'PUT', arc);
        }
        return await this.request(`/sec/arc`, 'POST', arc);
    }
    async getWhitelabelOverrides(key) {
        return this.request(`/pub/whitelabel/${key}/overrides`, 'GET');
    }
    async getDeviceLogs(deviceId, from, to) {
        return this.request(`/sec/admin/logs/${deviceId}/${from}/${to}`, 'GET');
    }
    async getBlockedIPs() {
        return this.request('/sec/admin/blocked-ips', 'GET');
    }
    async blockIP(ip) {
        return this.request('/sec/admin/block-ip', 'POST', {ip});
    }
    
    async getWhitelabels() {
        return this.request('/sec/whitelabel', 'GET');
    }

    async getJasperCustomers() {
        return this.request('/sec/jasper/customers', 'GET');
    }

    async getCosts() {
        return this.request('/sec/account/costs', 'GET');
    }

    async getCompatibility() {
        return this.request('/sec/device/compatibility', 'GET');
    }

    async setCompatibility(deviceTypeId, capabilities) {
        return this.request('/sec/device/compatibility', 'PUT', {deviceTypeId, ...capabilities});
    }

    async getPresentationPage(pageType, opts) {
        return this.request('/sec/presentation', 'PUT', {pageType, opts});
    }

    async clearCache() {
        return this.request('/pub/admin/cache/clear', 'GET');
    }

    async addProms(proms, arc) {
        return this.request('/sec/prom', 'POST', {proms, arc});
    }

    async getDevices(filter, accountId) {
        const devices = await this.request(`/sec/device?filter=${filter?.trim() || ''}&accountId=${accountId || 0}`, 'GET');
        return devices.map(d => new Device(d)).sort((a, b) => a.id < b.id ? 1 : -1);
    }

    async getCustomConfigs(deviceIds) {
        return this.request('/sec/device/config/custom',  'POST', { deviceIds });
    }
    async addDevice(device: Device, smsServerConfig: boolean) {
        return this.request(`/sec/device?smsServerConfig=${smsServerConfig ? 1 : 0}`, 'POST', device.toJS());
    }

    async updateDevice(device: Device) {
        return this.request('/sec/device/admin', 'PUT', device.toJS());
    }

    async deleteDevice(deviceId: number) {
        return this.request('/sec/device', 'DELETE', deviceId);
    }

    async addBeacon(beacon: Beacon) {
        return this.request('/sec/beacon', 'POST', beacon.toJS());
    }

    async updateBeacon(beacon: Beacon) {
        return this.request(`/sec/beacon/admin`, 'PUT', beacon.toJS());
    }

    async deleteBeacon(beacon: Beacon) {
        return this.request('/sec/beacon', 'DELETE', beacon.toJS());
    }

    async updateSite(site: Site) {
        return this.request('/sec/site', 'PUT', site.toJS());
    }

    async addSite(site: Site) {
        return this.request('/sec/site', 'POST', site.toJS());
    }

    async deleteSite(site: Site) {
        return this.request('/sec/site', 'DELETE', site.toJS());
    }

    async enableDeviceModule(device: Device, moduleId: number, isEnabled: boolean) {
        return this.request('/sec/device/admin/module', 'PUT', {
            deviceId: device.get('id'),
            moduleId,
            on: isEnabled
        });
    }

    async resetDevicePosition(device: Device, latitude, longitude) {
        return this.request('/sec/device/admin/resetPosition', 'PUT', {
            deviceId: device.get('id'),
            latitude,
            longitude
        });
    }

    async getPromForm(device: Device, prom: Prom, date: string, time: string) {
        const deviceType = deviceTypes[device.get('deviceTypeId')];
        return await this.requestPlain('/sec/device/admin/arc/form', 'POST', {
            date,
            time,
            deviceType,
            prom: prom.get('prom') || device.get('prom'),
            device: device.toJS(),
        });
    }

    async setProm(device: Device, prom: string, arcKey: string) {
        return this.request('/sec/device/admin/arc', 'PUT', {
            deviceId: device.get('id'),
            prom: prom || null,
            arcKey
        });
    }

    async setARC(device, arcKey) {
        return this.request('/sec/device/admin/arc', 'PUT', {
            deviceId: device.get('id'),
            arcKey
        });
    }

    async getAccountManagers() {
        const accountManagers = await this.request('/sec/accountManager', 'GET');
        return Object.values(accountManagers).map(r => new Account(r));
    }

    async updateAccountManager(accountManager) {
        return this.request('/sec/accountManager', 'PUT', {...accountManager.toJS()});
    }

    async addAccountManager(accountManager) {
        return this.request('/sec/accountManager', 'POST', {...accountManager.toJS()});
    }

    async deleteAccountManager(accountManager) {
        await this.request(`/sec/accountManager/${accountManager.id}`, 'DELETE');
    }

    async getProms(): Prom[] {
        const res = await this.request('/sec/prom', 'GET');
        return res.map(p => new Prom(p));
    }
    async getARCs() {
        const res = await this.request('/sec/arc', 'GET');
        return List(res.map(arc => new ARC(arc)));
    }
    
    async getAccounts(billingCycle): Account[] {
        const res = await this.request(`/sec/account?billingCycle=${billingCycle || ''}`, 'GET');
        return res.map(u => new Account(u));
    }

    async getBeacons(): Beacon[] {
        const res = await this.request(`/sec/beacon`, 'GET');
        return res.map(u => new Beacon(u));
    }
    async getGeofences(): Geofence[] {
        const res = await this.request(`/sec/geofence`, 'GET');
        return res.map(u => new Geofence(u));
    }
    async getTeams(): Team[] {
        const res = await this.request(`/sec/team`, 'GET');
        return res.map(t => new Team(t));
    }

    async getSites(): Site[] {
        const res = await this.request('/sec/site', 'GET');
        return res.map(u => new Site(u));
    }

    async deleteAccount(account) {
        await this.request(`/sec/account`, 'DELETE', account.id);
    }

    async updateAccount(account) {
        const res = await this.request('/sec/account', 'PUT', {...account.toJS()});
        await this.updateAccountManagerForAccount(account);
        return res;
    }

    async updateAccountARC(account) {
        return await this.request('/sec/account/arc', 'PUT', {...account.toJS()});
    }

    async updateAccountProm(accountId, prom) {
        if (!prom) prom = null;
        return this.request('/sec/account/prom', 'PUT', {accountId, prom});
    }

    async updateAccountManagerForAccount(account) {
        return this.request('/sec/account/accountManager', 'PUT', {
            accountManagerId: account.get('accountManagerId'),
            accountId: account.get('id')
        });
    }

    async addAccount(account) {
        return this.request('/sec/account', 'POST', {...account.toJS()});
    }

    async archiveAccount(account, isArchived) {
        return this.request('/sec/account/archive', 'POST', {
            accountId: account.id,
            isArchived,
        });
    }

    async loginAccount(accountId, adminToken, storageKey) {
        const user = localStorage.getItem('user');
        localStorage.setItem(storageKey, user);
        const token = await this.request('/sec/login', 'POST', {
            account: accountId,
            adminToken
        });
        const decoded = jwtDecode(token);
        decoded.user.token = token;
        return new User(decoded.user);
    }

    async loginUser(userId, adminToken, storageKey) {
        const user = localStorage.getItem('user');
        localStorage.setItem(storageKey, user);
        const token = await this.request('/sec/login', 'POST', {
            user: userId,
            adminToken
        });
        const decoded = jwtDecode(token);
        decoded.user.token = token;
        return new User(decoded.user);
    }

    async loginAdmin() {
        await this.refreshAdminJWT();
        let userJson = localStorage.getItem('accountManagerUser');
        if (!userJson) userJson = localStorage.getItem('adminUser');
        if (!userJson) return;
        localStorage.setItem('user', userJson);
        const user = new User(JSON.parse(userJson));
        localStorage.removeItem(user.isSuperAdmin ? 'adminUser' : 'accountManagerUser');
        const decoded = jwtDecode(user.token);
        decoded.user.token = user.token;
        return user;
    }

    async getJasperURL(deviceId) {
        return this.request(`/sec/device/${deviceId}/jasperUrl`);
    }

    async downloadProms() {
        return await this.requestPlain('/sec/prom/spreadsheet', 'GET', null, true);
    }

    async pushSetting(setting, value, notes, device: Device) {
        return this.request('/sec/devcon/push', 'PUT', {setting, value, notes, device});
    }
    async deleteSetting(setting, device: Device) {
        return this.request(`/sec/devcon/delete/${device.id}/${setting}`, 'DELETE');
    }

    async getSettings(deviceId) {
        return this.request(`/sec/devcon/settings/${deviceId}`, 'GET');
    }

    async translate(translation) {
        return this.request(`/sec/translation/translate`, 'POST', {translation});
    }

    async getTranslations() {
        return this.request(`/sec/translation`, 'GET');
    }

    async updateTranslation(translation) {
        return this.request(`/sec/translation`, 'POST', translation);
    }

    async forceReload(doLogout) {
        return this.request(`/sec/user/forceReload`, 'POST', {doLogout});
    }

    async getUserLogins(accountId) {
        return this.request(`/sec/user/lastLogins/${accountId}`, 'GET');
    }

    async uploadSiteImg(img, site) {
        return this.request(`/sec/site/upload`, 'PUT', {img, site});
    }

    async downloadAccountManagerReport(month, year) {
        return await this.requestPlain(`/sec/accountManager/report/${year}/${month}`, 'GET', null, true);
    }

    async runDiagnostics(device) {
        return this.request(`/sec/device/diagnose/${device.id}`, 'GET');
    }

    async createTrial(email, language, timezone, accountManagerId) {
        return this.request(`/sec/account/trial`, 'POST', {email, language, timezone, accountManagerId});
    }

    async refreshAdminJWT() {
        let adminUser = localStorage.getItem('adminUser');
        if (!adminUser) return;
        adminUser = JSON.parse(adminUser);
        const res = await fetch(`${env.apiBase()}/pub/user/keepAlive/admin`, {
            method: 'POST',
            body: JSON.stringify({
                adminToken: adminUser.token,
                jwt: BaseAPI.token
            })
        });
        if (res.status === 200) {
            const text = await res.text();
            getStore().dispatch(newAdminToken(text));
            return text;
        }
    }
}
