import React from 'react';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Flex } from './Container/Flex';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    const {enqueueSnackbar, t, onError} = this.props;
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    enqueueSnackbar((
      <Flex col>
        <span>{t('anErrorOccurred')}</span>
        <span style={{color: 'white', fontSize: 9}}>{t('weHaveBeenNotifiedOfThisIssue')}</span>
      </Flex>
    ), {
      variant: 'error'
    });
    if(onError?.catch) onError.catch(() => {
      setTimeout(() => this.setState({error: null}), 100);
    }, error);
  }

  render() {
    if (this.state.error) {
      return this.props.onError?.fallback || null;
    }

    return this.props.children;
  }
}
export default compose(
  withSnackbar,
  withTranslation()
)(ErrorBoundary);