import { takeLatest, put } from 'redux-saga/effects';
import { SAVE_GEOFENCE, DELETE_GEOFENCE, SAVE_GEOFENCE_DEVICES } from '../constants/geofence.constants';
import { GeofenceAPI } from '../../utils/API/GeofenceAPI';
import { setDetailedGeofence } from '../actions/geofence.actions';

const api = new GeofenceAPI();

function* saveGeofence({geofence}) {
	if(geofence.id) {
		yield api.updateGeofence(geofence);
	} else {
		yield api.addGeofence(geofence);
	}
	yield put(setDetailedGeofence(null));
}
function* saveGeofenceDevices({geofence, devices, teamIds}) {
	yield api.updateGeofenceDevices(geofence, devices.toJS(), teamIds);
}

function* deleteGeofence({geofence}) {
	yield api.deleteGeofence(geofence);
}

export const geofenceSagas = [
	takeLatest(SAVE_GEOFENCE, saveGeofence),
	takeLatest(SAVE_GEOFENCE_DEVICES, saveGeofenceDevices),
	takeLatest(DELETE_GEOFENCE, deleteGeofence),
];