import styled from 'styled-components';
import {Colors} from '../../utils/style/colors';

export const Wrapper = styled.div`
	border: 1px solid ${Colors.gray20};
`;

export const AvailabilityTable = styled.table`
	* {
		color: ${Colors.gray40};
	}
	width: calc(100% - 20px);
	user-select: none;
	margin: 5px 0;
	border-left: 1px solid ${Colors.gray40};
	border-top: 1px solid ${Colors.gray40};
	border-spacing: 0;
	th {
		padding: 5px;
		border-bottom: 1px solid;
		border-right: 1px solid;
		cursor: default;
		text-transform: capitalize;
	}
	${props => !props.editable && `
		pointer-events: none;
	`};
`;

export const AvailabilityCell = styled.td`
	${props => `
		border-right: 1px solid;
		border-bottom: 1px solid;
		cursor: pointer;
		font-size: 7pt;
		text-align: center;
		vertical-align: middle;
    
		&:nth-child(even) {
			border-right: 1px solid ${Colors.gray20};
		}
		${props.available && `
			background-color: ${Colors.lightGreen};
    	${props.day !== 6 && `
				border-bottom: 1px solid ${Colors.gray20};
			`};
		`};
		&:active {
			background-color: ${Colors.blue};
		}
		${props.isChanging && `
			background-color: ${Colors.blue} !important;
			border-color: ${Colors.lightBlue} !important;
		`};
	`};
`;