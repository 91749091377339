import Immutable from 'immutable';

import ScenarioAction, {notForSimpleViewActions, scenarioActionTypes} from './ScenarioAction';
import _sum from 'lodash/sum';
import {moduleIds} from './Module';
import {Colors} from '../style/colors';
import {presets} from '../../components/WeekAvailability';
import dayjs, {Dayjs} from 'dayjs';
import {sundayFirstToMondayFirst} from '../functions';
import validator from "validator";
import {getWhitelabel} from "../../env";
import {scenarioTriggers} from "./scenarios/scenarioTriggers";
import {triggerConfigs} from "./scenarios/triggerConfigs";
import {scenarioTriggersMap} from "./scenarios/triggerConfigMap";
import {triggerLabels} from "./scenarios/triggerLabels";

export const forAnyObject = -1;
export const forConcerningObject = -2;

export const triggerCategories = Object.fromEntries(Object.keys(scenarioTriggers).map(trigger => {
    return [
        trigger,
        Object.keys(scenarioTriggersMap)
            .find(category => scenarioTriggersMap[category].triggers.indexOf(trigger) >= 0)
    ];
}));

export const accountManagerTriggers = [
    scenarioTriggers.configError,
    scenarioTriggers.beaconBattery,
    scenarioTriggers.connectionChanged,
    scenarioTriggers.allAlarmTypes,
    scenarioTriggers.genericAlarm,
    scenarioTriggers.sosButton,
    scenarioTriggers.manDown,
    scenarioTriggers.ripcord,
    scenarioTriggers.timer,
    scenarioTriggers.arcAlarm
];

export const notForSimpleView = [
    scenarioTriggers.checkAssistanceRequestResponses,
    scenarioTriggers.multiTenant,
    scenarioTriggers.email
];
export const devicelessTriggers = [
    scenarioTriggers.webhook,
    scenarioTriggers.quickButton,
    scenarioTriggers.email,
    scenarioTriggers.multiTenant,
    scenarioTriggers.checkAssistanceRequestResponses, //not actually deviceless, but there is no compatibility entry for this trigger
    scenarioTriggers.scenarioDisabled
];
export const geofenceTriggers = [
    scenarioTriggers.geofenceExited,
    scenarioTriggers.geofenceEntered
];
export const beaconTrigger = [
    scenarioTriggers.beaconBattery
];
export const adminScenarioTriggers = [
    scenarioTriggers.email
];

export const colorForTrigger = trigger => {
    const category = Object.values(scenarioTriggersMap).find(t => t.triggers.indexOf(trigger) >= 0);
    return category ? category.color : Colors.gray30;
}
export const alertTriggers = [
    scenarioTriggers.connectionChanged,
    scenarioTriggers.docked,
    scenarioTriggers.undocked,
    scenarioTriggers.lowBattery,
    scenarioTriggers.quickButton,
    scenarioTriggers.ioChange,
    scenarioTriggers.geofenceEntered,
    scenarioTriggers.geofenceExited,
    scenarioTriggers.multiTenant,
    scenarioTriggers.espa,
]

const ScenarioModel = Immutable.Record({
    id: 0,
    name: '',
    trigger: '',
    triggerConfig: [],
    accounts: null,
    actions: [ScenarioAction.create('email')],
    enabled: true,
    locked: false,
    visible: true,
    schedule: []
});

export default class Scenario extends ScenarioModel {
    id: number;
    name: string;
    trigger: string;
    triggerConfig: any;
    accounts: number[];
    actions: ScenarioAction[];
    enabled: boolean;
    locked: boolean;
    visible: boolean;
    schedule: boolean[][];

    static compatibility: any = {};

    canSave(devices, teams, geofences): boolean {
        return !!(this.get('name') && this.get('trigger') && this.triggerConfigured(devices, teams, geofences));
    }

    canEdit(user): boolean {
        if (user.isShadow) return true;
        return !this.get('locked') && !user.isPortalUser;
    }

    get hasGeneratedConfig() {
        return [scenarioTriggers.webhook, scenarioTriggers.email].indexOf(this.get('trigger')) >= 0;
    }
    
    containsDevices(devices, user) {
        const deviceIds = devices.map(d => d.id);
        if(this.get('actions').find(a => a.containsDevices(devices, user, this))) 
            return true;
        if(this.get('triggerConfig')?.deviceIds?.indexOf(forAnyObject) >= 0) {
            return !!devices.find(d => d.isCompatibleWithTrigger(this.trigger));
        }
        return deviceIds.indexOf(this.get('triggerConfig')?.deviceId) >= 0 ||
            this.get('triggerConfig')?.deviceIds?.find(dId => deviceIds.indexOf(dId) >= 0);
    }
    containsTeamIds(teamIds) {
        if(this.get('actions').find(a => a.containsTeamIds(teamIds)))
            return true;
        return teamIds.indexOf(this.get('triggerConfig')?.teamId) >= 0;
    }
    containsGeofenceIds(geofenceIds) {
        if(this.get('actions').find(a => a.containsGeofenceIds(geofenceIds)))
            return true;
        return geofenceIds.indexOf(this.get('triggerConfig')?.geofenceId) >= 0
    }

    createCopy() {
        const copy = this
            .set('id', 0)
            .set('name', `${this.get('name')} [2]`);
        if (this.hasGeneratedConfig) {
            return copy.set('triggerConfig', null);
        }
        return copy;
    }

    get canSaveActions() {
        return !this.get('actions').find(a => !a.canSave);
    }

    triggerConfigured(devices, teams, geofences) {
        const triggerConfigType = triggerConfigs[this.get('trigger')];
        const config = this.get('triggerConfig');

        if (['beacons', 'geofences'].indexOf(triggerConfigType) >= 0) {
            return Array.isArray(config) && config.length > 0;
        } else if (['devices', 'connectionChanged'].indexOf(triggerConfigType) >= 0) {
            if(triggerConfigType === 'connectionChanged') {
                if(!(+config?.reasons?.length > 0)) return false;
            }
            if (!config.source) return false;
            if (config.source === 'devices') {
                if(!Array.isArray(config.deviceIds)) return false;
                return config.deviceIds.filter(dId => dId < 0 || devices.find(d => d.id === dId)).length > 0;
            }
            if (config.source === 'teams') return !!config.teamIds.map(tId => teams.find(t => t.id === tId)).length;
            if (config.source === 'geofences') return !!config.geofenceIds.map(gId => geofences.find(g => g.id === gId)).length;
            return false;
        } else if (triggerConfigType === 'multiTenant') {
            return config?.code?.length === 20;
        } else if (triggerConfigType === 'email') {
            if (config instanceof Array) return false;
            return !config.fromFilter || validator.isEmail(config.fromFilter);
        } else if (triggerConfigType === 'io') {
            return !!config?.deviceIds?.length && !!config?.ioTrigger;
        }
        return true;
    }

    get triggerName(): string {
        return triggerLabels[this.get('trigger')] || '';
    }

    placeholders(user): [] {
        const placeholders = {
            date: 'dateOfEvent',
            time: 'timeOfEvent'
        };
        const t = scenarioTriggers;
        if (user.isAccountManager) {
            placeholders.account = 'accountName';
        }
        if (t.webhook === this.get('trigger')) {
            placeholders.requestBody = 'requestBody';
        }
        if (t.smsMessage === this.get('trigger')) {
            placeholders.smsText = 'smsText';
        }
        if ([t.multiTenant, t.messageNotAcknowledged, t.checkAssistanceRequestResponses, t.assistanceRequestClosed].indexOf(this.get('trigger')) >= 0) {
            placeholders.message = 'message';
        }
        if (t.espa === this.get('trigger')) {
            placeholders.id = {
                tooltipRaw: 'ID',
                label: 'ID',
                key: 'id'
            };
            placeholders.address = {
                tooltipRaw: 'Address',
                label: 'Address',
                key: 'address'
            };
            placeholders.info = {
                tooltipRaw: 'Info',
                label: 'Info',
                key: 'info'
            };
            placeholders.priority = {
                tooltipRaw: 'Priority',
                label: 'Priority',
                key: 'priority'
            };
            placeholders.status = {
                tooltipRaw: 'Status',
                label: 'Status',
                key: 'status'
            };
            placeholders.bleep = {
                tooltipRaw: 'Bleep',
                label: 'Bleep',
                key: 'bleep'
            };
            placeholders.repeat = {
                tooltipRaw: 'Repeat',
                label: 'Repeat',
                key: 'repeat'
            };
            placeholders.callType = {
                tooltipRaw: 'Call type',
                label: 'Call type',
                key: 'callType'
            };
        }
        if (t.email === this.get('trigger')) {
            placeholders.contents = 'contents';
            placeholders.subject = 'subject';
            placeholders['from.email'] = {
                tooltip: 'from.email',
                key: 'from'
            };
        }
        if ([t.assistanceRequestClosed].indexOf(this.get('trigger')) >= 0) {
            placeholders.name = 'name';
        }
        if ([t.scenarioDisabled].indexOf(this.get('trigger')) >= 0) {
            placeholders.name = 'name';
            placeholders.reason = 'reason';
        }
        if ([t.lowBattery, t.smsMessage, t.genericAlarm, t.ioChange].indexOf(this.get('trigger')) >= 0) {
            placeholders.device = 'deviceName';
            placeholders.phone = 'devicePhone';
        }
        if (beaconTrigger.indexOf(this.get('trigger')) >= 0) {
            placeholders.beacon = 'beaconName';
        }
        if ([t.allAlarmTypes, t.manDown, t.timer, t.ripcord, t.sosButton, t.arcAlarm].indexOf(this.get('trigger')) >= 0) {
            placeholders.alarmType = 'alarmType';
            placeholders.alarmLocationUrl = 'alarmLocationUrl.explanation';
        }
        if ([t.geofenceEntered, t.geofenceExited].indexOf(this.get('trigger')) >= 0) {
            placeholders.device = 'deviceName';
            placeholders.phone = 'devicePhone';
            placeholders.geofence = 'geofenceName';
            placeholders.locationUrl = 'locationUrl.explanation';
        }
        if ([t.configError, t.lowBattery, t.allAlarmTypes, t.arcAlarm, t.manDown, t.timer, t.ripcord, t.sosButton, t.connectionChanged, t.quickButton, t.docked, t.undocked].indexOf(this.get('trigger')) >= 0) {
            placeholders.device = 'deviceName';
            placeholders.phone = 'devicePhone';
            placeholders.operatingAddress = 'operatingAddress';
            placeholders.tempAddress = 'tempAddress';
            placeholders.industry = 'industry';
        }
        if ([t.allAlarmTypes, t.manDown, t.timer, t.ripcord, t.sosButton, t.connectionChanged, t.quickButton, t.docked, t.undocked].indexOf(this.get('trigger')) >= 0) {
            placeholders.site = 'site';
            placeholders.beacons = 'beaconName.all';
            placeholders.beacon = 'beaconName.strongest';
            placeholders.geofences = 'allPresentGeofences';
            placeholders.geofence_sm = 'smallestPresentGeofence';
            placeholders.location = 'locationPlaceholder.explanation';
            placeholders.gmapsUrl = 'gmapsUrl.explanation';
            placeholders.locationUrl = 'locationUrl.explanation';
        }
        return placeholders;
    }

    creditCosts(devices, teams, geofences, beacons, alarmTypeNames) {
        const costs = this.get('actions').map(a => a.creditCosts(devices, teams, geofences, beacons, alarmTypeNames));
        return {
            min: _sum(costs.map(c => c.min)),
            max: _sum(costs.map(c => c.max)),
        }
    }

    get isAlarmTrigger() {
        const t = scenarioTriggers;
        return [t.allAlarmTypes, t.genericAlarm, t.manDown, t.timer, t.ripcord, t.sosButton, t.arcAlarm].indexOf(this.get('trigger')) >= 0;
    }

    canPerformAction(actionType, devices, user, adminMode) {
        if (getWhitelabel().simpleView === true && notForSimpleViewActions.indexOf(actionType) >= 0) return false;
        if (user.isAccountManager) {
            return [
                scenarioActionTypes.email,
                scenarioActionTypes.sms,
                scenarioActionTypes.sendTelegramMessage
            ].indexOf(actionType) >= 0;
        }
        if ([scenarioTriggers.messageNotAcknowledged, scenarioTriggers.checkAssistanceRequestResponses].indexOf(this.trigger) >= 0) {
            const specificTrigger = scenarioTriggers.messageNotAcknowledged === this.trigger ? scenarioActionTypes.resendLastOSM : scenarioActionTypes.assistanceRequest;
            return [
                specificTrigger,
                scenarioActionTypes.osm,
                scenarioActionTypes.sms,
                scenarioActionTypes.voiceMessage,
                scenarioActionTypes.sendTelegramMessage
            ].indexOf(actionType) >= 0;
        }
        const t = scenarioTriggers;
        if (actionType === scenarioActionTypes.safeguardAlarm) {
            return user.hasModule(moduleIds.Safeguard);
        }
        if (actionType === scenarioActionTypes.sendToARC) {
            return this.isAlarmTrigger && user.hasAlarmCenter && !this.actions.find(a => a.type === scenarioActionTypes.sendToARC);
        }
        if (actionType === scenarioActionTypes.showAsAlert) {
            return alertTriggers.indexOf(this.get('trigger')) >= 0 || this.get('trigger') === scenarioTriggers.webhook;
        }
        if (actionType === scenarioActionTypes.createAlarm) {
            return [
                scenarioTriggers.smsMessage,
                scenarioTriggers.ioChange,
                scenarioTriggers.email
            ].indexOf(this.get('trigger')) >= 0;
        }
        if (actionType === scenarioActionTypes.lowPrioSIA) {
            if (!user.hasModule(moduleIds.LowPrioSIA)) return false;
        }
        if (actionType === scenarioActionTypes.startTracking) {
            if (!user.hasModule(moduleIds.TrackingScenario)) return false;
            if (this.get('actions').find(a => a.get('type') === scenarioActionTypes.startTracking)) return false;
        }
        if (actionType === scenarioActionTypes.safeguardAlarm) {
            return this.isAlarmTrigger;
        }
        if (actionType === scenarioActionTypes.geofencePresence) {
            return [t.docked, t.undocked, t.connectionChanged, t.quickButton].indexOf(this.get('trigger')) >= 0;
        }
        if (actionType === scenarioActionTypes.webhook) {
            return this.get('trigger') !== t.webhook;
        }
        if (actionType === scenarioActionTypes.multiTenant) {
            return this.get('trigger') !== t.multiTenant;
        }
        if (actionType === scenarioActionTypes.setDigitalOutput) {
            return devices?.find(d => d.can('setDigitalOutput'));
        }
        if ([scenarioActionTypes.sendTelegramMessage, scenarioActionTypes.requestAppLogs].indexOf(actionType) >= 0) {
            return adminMode;
        }
        return true;
    }

    addAction(type, initConfig, language = 'nl-NL') {
        const actions = Array.from(this.get('actions'));
        actions.push(ScenarioAction.create(type, {
            waitForNewLocation: false,
            ...initConfig
        }, language));
        return this.set('actions', actions);
    }

    get scheduleArray() {
        return this.get('schedule')?.length ? this.get('schedule') : presets.always;
    }

    get isDeviceTrigger() {
        return [scenarioTriggers.webhook].indexOf(this.get('trigger')) === -1;
    }

    setTrigger(trigger) {
        if (triggerConfigs[trigger] === triggerConfigs[this.get('trigger')]) {
            return this.set('trigger', trigger);
        }
        return this.set('actions', []).set('triggerConfig', []).set('trigger', trigger);
    }

    isAvailable(date: Dayjs) {
        if (!date) date = dayjs();
        const slot = (date.hour() * 2) + (date.minute() > 30 ? 1 : 0);
        return this.isAvailableAtSlot(sundayFirstToMondayFirst(date.day()), slot);
    }

    isAvailableAtSlot(day: number, slot: number) {
        const dayAvailability = this.scheduleArray[day];
        return !!dayAvailability[slot];
    }

    constructor(props) {
        props.actions = props.actions.map(a => new ScenarioAction(a));
        super(props);
    }

    clone() {
        return this
            .set('id', this.get('id'))
            .set('actions', this.get('actions').map(a => a.clone()));
    }

    static create() {
        return new Scenario({
            id: 0,
            name: '',
            trigger: '',
            enabled: true,
            visible: true,
            locked: false,
            actions: []
        });
    }
}
