import { APP, WS_DATA } from './app.constants';


export const GEOFENCES = `${APP}_geofences`;

export const SAVE_GEOFENCE = `${GEOFENCES}/SAVE_GEOFENCE`;
export const SAVE_GEOFENCE_DEVICES = `${GEOFENCES}/SAVE_GEOFENCE_DEVICES`;
export const DELETE_GEOFENCE = `${GEOFENCES}/DELETE_GEOFENCE`;
export const SET_DETAILED_GEOFENCE = `${GEOFENCES}/SET_DETAILED_GEOFENCE`;

export const WS_DATA_GEOFENCE = `${WS_DATA}/geofence`;
export const WS_DATA_GEOFENCES = `${WS_DATA}/geofences`;

export const WS_DATA_GEOFENCE_PRESENCE = `${WS_DATA}/geofencePresence`;