import { createSelector } from 'reselect';
import { ALARMS } from '../constants/alarm.constants';
import { initialState } from '../reducers/alarm.reducer';

const selectState = state => state[ALARMS] || initialState;

export const selectAlarms = () => createSelector(selectState, state => state.get('data').toList().sortBy(a => -a.get('id')));
export const selectAlarmsFetched = () => createSelector(selectState, state => state.get('fetched'));
export const selectOpenAlarms = () => createSelector(selectState, state => state.get('data').toList().sortBy(a => -a.get('id')).filter(a => a.isOpen));
export const selectWizard = () => createSelector(selectState, state => state.get('wizard'));
export const selectAlarmSoundEnabled = () => createSelector(selectState, state => !!state.get('alarmSoundEnabled'));
export const selectAlertSoundEnabled = () => createSelector(selectState, state => !!state.get('alertSoundEnabled'));
export const selectAssistanceRequestSoundEnabled = () => createSelector(selectState, state => !!state.get('assReqSoundEnabled'));
export const selectSafetyRegions = () => createSelector(selectState, state => state.get('safetyRegions'));
export const selectDetailedAlarm = () => createSelector(selectState, state => state.get('detailedAlarm'));
export const selectLoadingDetailedAlarm = () => createSelector(selectState, state => state.get('loadingDetailedAlarm'));
export const selectRecording = () => createSelector(selectState, state => state.get('recording'));
export const selectShowARCAlarms = () => createSelector(selectState, state => state.get('showARCAlarms'));