import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import BaseAPI from './utils/API/BaseAPI';
import App from './containers/App';
import configureStore from './configureStore';
import { SnackbarProvider } from 'notistack';
import '../i18n';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { snackbarProps } from './utils/style/Snackbar';
import Splashscreen from './components/Splashscreen';
import ReactGA from 'react-ga4';
import {isDevelopment} from "./env";
import {consoleLog} from "./utils/log";

require('./mui');
require('leaflet/dist/leaflet.css');

if(!isDevelopment()) {
    const gaId = document.getElementsByTagName('meta')['ga-measurement-id']?.content;
    consoleLog('GA_MEASUREMENT_ID', gaId || undefined);
    if (gaId) {
        ReactGA.initialize([{
            trackingId: gaId,
            gaOptions: {
                anonymizeIp: true
            }
        }]);
    }
}

const { detect } = require('detect-browser');
const browserInfo = detect();
export const isSupportedBrowser = ['chrome','crios'].indexOf(browserInfo?.name) !== -1;
export const browserVersion = browserInfo.version;

require('leaflet-imageoverlay-rotated');
require('./global-styles');

dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(utc)
dayjs.extend(timezone)

const initialState = {};
const store = configureStore(initialState);

export const api = new BaseAPI(store.dispatch);

const container = document.getElementById('app');
const root = createRoot(container);

window.addEventListener('error', e => {
    if (/Loading( CSS)? chunk [\d]+ failed/.test(e.message)) {
        window.location.reload();
    }
    if (e.message?.indexOf('Missing required parameters: sitekey')) {
        window.location.reload();
    }
});

root.render(
  <SnackbarProvider {...snackbarProps}>
    <Provider store={store}>
        <Suspense fallback={<Splashscreen />}>
          <App/>
        </Suspense>
    </Provider>
  </SnackbarProvider>
);