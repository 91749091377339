import Immutable from "immutable";

const SubscriptionItemModel = Immutable.Record({
    item_code: '',
    item_name: '',
    qty: 1,
    discountPct: 0,
    rate: null
});

export default class SubscriptionItem extends SubscriptionItemModel {
    item_code: string;
    item_name: string;
    qty: number;
    discountPct: number;
    rate: number;
    
    get isValid() {
        return this.get('item_code') && this.get('item_name') && this.get('qty') >= 0;
    }
}