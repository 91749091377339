import * as env from '../env';

export const MapProviders = {
    normal: 'normal',
    satellite: 'satellite'
}
export const MapProvider = (type, lang) => {
    if(type === MapProviders.satellite) return '//{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&hl=' + (lang || 'en');
    if(env.isDevelopment()) return '//{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png';
    return '//api.mapbox.com/styles/v1/sosvolaris/cjw922yij12ez1cnergo5wirl/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic29zdm9sYXJpcyIsImEiOiJjanc5MXpoejMwYnk3NGFteHo3b3c2OWtoIn0.ZBe6E5mZzf0ueLaZrY0QTw';
}

export const getCentroid = arr => arr.reduce((x,y) => [x[0] + y[0]/arr.length, x[1] + y[1]/arr.length], [0,0]);