import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { PRESENTATION_PAGE } from './constants';

const selectState = state => state[PRESENTATION_PAGE] || initialState;

export const selectOptions = () => createSelector(selectState, state => state.get('options'));
export const selectPageType = () => createSelector(selectState, state => state.get('pageType'));


