import { fromJS, Map } from 'immutable';
import Event from '../../utils/models/Event';
import { WS_DATA_EVENT, WS_DATA_EVENTS } from '../constants/event.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	fetched: false,
	data: {},
});

function reducer(state: Map = initialState, action) {
	let events = state.get('data');
	switch(action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_EVENT:
			return state.setIn(['data', action.data.id], new Event(action.data));
		case WS_DATA_EVENTS:
			events = Map(action.data.map(d => new Event(d)).map(d => [d.id, d]));
			return state
				.set('data', events)
				.set('fetched', true);
		default:
			return state;
	}
}

export default reducer;
