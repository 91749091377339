import Immutable from 'immutable';

const TeamModel = Immutable.Record({
	id: 0,
	name: '',
	accountName: '',
	deviceIds: []
});

export default class Team extends TeamModel {
	id: number;
	name: string;
	accountName: string;
	deviceIds: number[];

	clone() {
		return this.set('id', this.get('id'));
	}
	
	filterString() {
		return this.name + this.accountName;
	}

	static create() {
		return new Team({
			id: 0,
			name: '',
			deviceIds: []
		});
	}
}
