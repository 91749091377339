import { takeLatest, put } from 'redux-saga/effects';
import { CLOSE_EVENTS, OPEN_EVENTS } from '../constants/event.constants';
import { EventAPI } from '../../utils/API/EventAPI';
import { updateEvent } from '../actions/event.actions';

const eventAPI: EventAPI = new EventAPI();

function* closeEvents({events}) {
	yield disableEvents(events);
	yield eventAPI.closeEvents(events);
}

function* openEvents({events}) {
	yield disableEvents(events);
	eventAPI.openEvents(events);
}

function* disableEvents(events: Event[]) {
	for(let i in events) {
		yield put(updateEvent(events[i].set('disabled', true).toJS()));
	}
}

export const eventSagas = [
	takeLatest(OPEN_EVENTS, openEvents),
	takeLatest(CLOSE_EVENTS, closeEvents),
];