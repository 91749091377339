import {
	DELETE_CUSTOMER, DELETE_PRICING_RULE,
	GET_CUSTOMERS,
	GET_GENERAL_LEDGER_ACCOUNTS, GET_PAYMENT_TERMS_TEMPLATES, GET_PRICING_RULES,
	GET_SALES_INVOICES,
	GET_SALES_ITEMS, GET_SUBSCRIPTIONS, GET_TAX_TEMPLATES
} from "../constants/administration.constants";
import {AdministrationAPI} from "../../utils/API/AdministrationAPI";
import { takeLeading, put, select } from 'redux-saga/effects';
import {
	customersChanged,
	generalLedgerAccountsChanged,
	paymentTermsChanged, pricingRulesChanged,
	salesInvoicesChanged,
	salesItemsChanged, subscriptionsChanged, taxTemplatesChanged
} from "../actions/administration.actions";
import SalesItem from "../../utils/models/Administration/SalesItem";
import SalesInvoice from "../../utils/models/Administration/SalesInvoice";
import Customer from "../../utils/models/Administration/Customer";
import {
	selectCustomers,
	selectGeneralLedgerAccounts, selectPaymentTermsTemplates, selectPricingRules,
	selectSalesInvoices,
	selectSalesItems, selectSubscriptions, selectTaxTemplates
} from "../selectors/administration.selector";
import {List} from "immutable";
import PricingRule from "../../utils/models/Administration/PricingRule";
import {getPricingRules as getPricingRulesAction} from '../actions/administration.actions';
import Subscription from "../../utils/models/Administration/Subscription";

const api = new AdministrationAPI();

function *getCustomers({force}) {
	const existing = yield select(selectCustomers());
	if(existing?.size && !force) return;
	const customers = yield api.getCustomers();
	yield put(customersChanged(new List(customers.map(c => new Customer(c)))));
}
function *getSalesItems({force}) {
	const existing = yield select(selectSalesItems());
	if(existing?.size && !force) return;
	const salesItems = yield api.getSalesItems();
	yield put(salesItemsChanged(new List(salesItems.map(i => new SalesItem(i)))));
}
function *getSalesInvoices({force, callback}) {
	const existing = yield select(selectSalesInvoices());
	if(existing?.size && !force) return;
	const res = yield api.getSalesInvoices();
	const salesInvoices=  new List(res.map(i => new SalesInvoice(i)));
	yield put(salesInvoicesChanged(salesInvoices));
	if(callback) callback(salesInvoices);
}
function *getSubscriptions({force}) {
	const existing = yield select(selectSubscriptions());
	if(existing?.size && !force) return;
	const res = yield api.getSubscriptions();
	const subscriptions=  new List(res.map(i => new Subscription(i)));
	yield put(subscriptionsChanged(subscriptions));
}
function *getGeneralLedgerAccounts({force}) {
	const existing = yield select(selectGeneralLedgerAccounts());
	if(existing?.length && !force) return;
	const generalLedgerAccounts = yield api.getGeneralLedgerAccounts();
	yield put(generalLedgerAccountsChanged(generalLedgerAccounts));
}
function *getTaxTemplates({force}) {
	const existing = yield select(selectTaxTemplates());
	if(existing?.length && !force) return;
	const taxTemplates = yield api.getTaxTemplates();
	yield put(taxTemplatesChanged(taxTemplates));
}
function *getPaymentTermsTemplates({force}) {
	const existing = yield select(selectPaymentTermsTemplates());
	if(existing?.length && !force) return;
	const paymentTerms = yield api.getPaymentTermsTemplates();
	yield put(paymentTermsChanged(paymentTerms));
}

function *deleteCustomer({customer}) {
	yield api.deleteCustomer(customer);
	yield getCustomers({force: true});
}
function *getPricingRules({force}) {
	const existing = yield select(selectPricingRules());
	if(existing?.size && !force) return;
	const pricingRules = yield api.getPricingRules();
	yield put(pricingRulesChanged(new List(pricingRules.map(c => new PricingRule(c)))));
}
function *deletePricingRule({id}) {
	yield api.deletePricingRule(id);
	yield put(getPricingRulesAction(true));
}

export const administrationSagas = [
	takeLeading(GET_CUSTOMERS, getCustomers),
	takeLeading(GET_PRICING_RULES, getPricingRules),
	takeLeading(DELETE_PRICING_RULE, deletePricingRule),
	takeLeading(GET_SALES_ITEMS, getSalesItems),
	takeLeading(GET_SALES_INVOICES, getSalesInvoices),
	takeLeading(GET_GENERAL_LEDGER_ACCOUNTS, getGeneralLedgerAccounts),
	takeLeading(GET_TAX_TEMPLATES, getTaxTemplates),
	takeLeading(GET_PAYMENT_TERMS_TEMPLATES, getPaymentTermsTemplates),
	takeLeading(DELETE_CUSTOMER, deleteCustomer),
	takeLeading(GET_SUBSCRIPTIONS, getSubscriptions),
];