import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import dayjs from 'dayjs';
import { longDateFormat } from '../../utils/style/formats';
import ExternalLink from '../../components/Controls/ExternalLink';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Comment, Text } from '../../utils/style/texts';
import TextButton from '../../components/Controls/TextButton';
import { Flex } from '../../components/Container/Flex';
import { generateRandomString } from '../../utils/functions';
import { selectCurrentUser } from '../../redux/selectors/app.selectors';
import { logout } from '../../redux/actions/app.actions';


class TrialExpiredDialog extends React.PureComponent {

	state = {
		forceHide: false,
		id: generateRandomString(10)
	}

	interval = null;

	componentDidMount() {
		if(this.show(this.props.user)) {
			this.startInterval();
		}
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		if(this.show(prevProps.user) && this.show(this.props.user)) {
			this.startInterval();
		}
	}

	startInterval() {
		if(this.interval) clearInterval(this.interval);
		this.interval = setInterval(() => {
			if(this.show(this.props.user) && !document.getElementById(this.state.id)) {
				clearInterval(this.interval);
				window.location.reload();
			}
		}, 200);
	}

	componentWillUnmount() {
		if(this.interval) clearInterval(this.interval);
	}

	show(user) {
		const { forceHide} = this.state;
		return !!user.trialEnded && !forceHide;
	}

	render() {
		const { user, t, logout } = this.props;

		return (
			<Dialog
				id={this.state.id}
				open={!!this.show(user)}>
				<DialogContent>
					<Text>
						<div style={{ whiteSpace: 'pre-line' }}>
							{t('trialEndedDialogMessage', { date: dayjs.unix(user.trialEnd).format(longDateFormat) })}
							<br/><br/>
							<ExternalLink href="mailto:info@vevigo.nl">{t('contactUs')}</ExternalLink>
						</div>
					</Text>
				</DialogContent>
				<DialogActions>
					{user.viaAdmin && (
						<TextButton onClick={() => {
							if (this.interval) clearInterval(this.interval);
							this.setState({forceHide: true})
						}}>
							<Flex col>
								<span>{t('ignore')}</span>
								<Comment>({t('adminsOnly')})</Comment>
							</Flex>
						</TextButton>
					)}
					<TextButton color="primary" onClick={() => {
						if (this.interval) clearInterval(this.interval);
						logout();
						window.location.reload();
					}}>
						{t('logout')}
					</TextButton>
				</DialogActions>
			</Dialog>
		);

	}

}


function mapDispatchToProps(dispatch) {
	return {
		logout: () => dispatch(logout()),
	};
}

const mapStateToProps = createStructuredSelector({
	user: selectCurrentUser(),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(TrialExpiredDialog);