import { fromJS, Map } from 'immutable';
import Dashboard from '../../utils/models/Dashboard';
import {
  SAVE_DASHBOARD,
  SET_EDIT_DASHBOARD,
  SET_LOADING,
  SET_SELECTED_DASHBOARD,
  WS_DATA_DASHBOARD,
  WS_DATA_DASHBOARDS,
} from '../constants/dashboard.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	data: {},
	editDashboard: null,
	loading: false,
	fetched: false,
	selected: localStorage.getItem('dashboard') || -1
});

export default function DashboardReducer(state: Map = initialState, action) {
	let dashboards = state.get('data');
	switch (action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_DASHBOARD:
			return state.setIn(['data', action.data.id], new Dashboard(action.data)).set('loading', false);
		case WS_DATA_DASHBOARDS:
			dashboards = Map(action.data.map(d => new Dashboard(d)).map(d => [d.id, d]));
			return state.set('data', dashboards).set('loading', false).set('fetched', true);
		case SET_LOADING:
			return state.set('loading', action.loading);
		case SET_EDIT_DASHBOARD:
			return state.set('editDashboard', action.dashboard ? action.dashboard.clone() : null);
		case SAVE_DASHBOARD:
			return state.set('loading', true);
		case SET_SELECTED_DASHBOARD:
			return state.set('selected', action.id);
		default:
			return state;
	}
}