import {compose} from 'redux';
import React from 'react';
import {Button, DialogTitle} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {selectLoading, selectOptions, selectPageType, selectShow, selectURL} from './selectors';
import {hidePresentationPage} from './actions';
import {useTranslation} from 'react-i18next';
import injectReducer from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import {PRESENTATION_PAGE_DIALOG} from './constants';
import injectSaga from '../../utils/injectSaga';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '../../components/Controls/TextField';
import {Flex} from '../../components/Container/Flex';
import {IconButton} from '../../components/IconButton';
import {CopyIcon} from '../../utils/icons';
import {Text} from '../../utils/style/texts';
import {useSnackbar} from 'notistack';
import Dialog from '../../components/Dialog';

const PresentationPageDialog = props => {
    const [t] = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const {show, url, loading, onClose, pageType, options} = props;

    const introParams = options && options.subject ? {subject: options.subject} : undefined;

    return (
        <Dialog
            onError={{
                catch: reset => {
                    onClose();
                    reset();
                }
            }}
            open={show}>
            <DialogTitle>{pageType && t(`presentationPage.type.${pageType}`)}</DialogTitle>
            <DialogContent>
                <Text>
                    {!!pageType && <p>{t(`presentationPage.intro.${pageType}`, introParams)}</p>}
                </Text>
                {loading && (<Flex justify="center"><CircularProgress size={25}/></Flex>)}
                {!loading && (
                    <Flex col>
                        <Flex row align="center">
                            <TextField
                                fullWidth
                                value={url}
                            />
                            <IconButton
                                onClick={() => {
                                    require('copy-to-clipboard')(url);
                                    enqueueSnackbar(t('copiedToClipboard'));
                                }}
                                tooltip={t('copy')}
                                size="large"><CopyIcon/></IconButton>
                        </Flex>
                    </Flex>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>{t('close')}</Button>
            </DialogActions>
        </Dialog>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        onClose: () => dispatch(hidePresentationPage())
    };
}

const mapStateToProps = createStructuredSelector({
    show: selectShow(),
    url: selectURL(),
    loading: selectLoading(),
    pageType: selectPageType(),
    options: selectOptions()
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectReducer({key: PRESENTATION_PAGE_DIALOG, reducer}),
    injectSaga({key: PRESENTATION_PAGE_DIALOG, saga})
)(PresentationPageDialog);