import BaseAPI from './BaseAPI';
import Team from '../models/Team';


export class TeamAPI extends BaseAPI {

	updateTeam(team: Team) {
		this.request('/sec/v2/team', 'PUT', { team: team.toJS() });
	}
	newTeam(team: Team) {
		this.request('/sec/v2/team', 'POST', { team: team.toJS() });
	}
	deleteTeam(team: Team) {
		this.request('/sec/team', 'DELETE', { id: team.id });
	}

}
