import BaseAPI from './BaseAPI';
import Alarm from '../models/Alarm';
import {getLocaleFromLang} from "../functions";


export class AlarmAPI extends BaseAPI {

	async saveExpirationTime(expiration) {
		return this.request(`/sec/account/alertExpiration`, 'PUT', {expiration});
	}
	async getSafetyRegions() {
		return this.request(`/sec/safetyRegions`);
	}
	async loadSnapshot(alarmId) {
		return this.request(`/sec/alarm/${alarmId}/snapshot`);
	}

	async getClosedBy(alarm: Alarm) {
		return this.request(`/sec/alarm/${alarm.id}/closedBy`);
	}

	async shareAlarm(alarm: Alarm, recipients: string, language: string) {
		return this.request('/sec/alarm/share', 'POST', { alarm: alarm.toJS(), recipients, language });
	}

	closeAlarm(alarm: Alarm, blackbox) : Promise {
		return this.request('/sec/alarm', 'PUT', {
			id: alarm.id,
			notes: alarm.get('notes'),
			done: true,
			blackbox
		});
	}

	openAlarm(alarm: Alarm) {
		this.request('/sec/alarm', 'PUT', {
			id: alarm.get('id'),
			done: false
		});
	}

	cancelAlarm(alarm: Alarm) {
		this.request('/sec/alarm', 'DELETE', {
			id: alarm.get('id')
		});
	}

	async getAlarmByToken(token: string) {
		const json = await this.request(`/sec/alarm/token/${token}`, 'GET');
		return new Alarm(json);
	}

	async getWizard(alarmId, lang) {
		lang = getLocaleFromLang(lang);
		try {
			return await this.request(`/sec/alarm/${alarmId}/wizard/${lang}`, 'GET');
		} catch (e) {}
	}
}
