import styled from 'styled-components';

export const Flex = styled.div`
	display: ${props => props.inline ? 'inline-flex' : 'flex'};
	${props => props.direction && `
		flex-direction: ${props.direction};
	`};
	${props => props.row && `
		flex-direction: row;
	`};
	${props => props.col && `
		flex-direction: column;
	`};
	${props => props.align && `
		align-items: ${props.align};
	`};
	${props => props.justify && `
		justify-content: ${props.justify};
	`};
	${props => props.fullWidth && `
		width: 100%;
	`};
	${props => props.fullHeight && `
		height: 100%;
		max-height: 100%;
	`};
	${props => props.gap && `
  		gap: ${props.gap}px;
	`};
`;