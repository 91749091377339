import BaseAPI from './BaseAPI';
import Scenario from '../models/Scenario';


export class ScenarioAPI extends BaseAPI {

	async getScenarios() {
		return this.request('/sec/scenario', 'GET');
	}
	async getMultiTenantCode() {
		return this.request('/sec/scenario/multiTenantCode', 'GET');
	}
	async getTemplates() {
		return this.request('/sec/scenario/template', 'GET');
	}
	async saveTemplate(name, scenario) {
		scenario = scenario.set('name', name);
		return this.request('/sec/scenario/template', 'PUT', {scenario: scenario.toJS()});
	}
	async createWebhookCode() {
		return this.request('/sec/webhook', 'GET');
	}
	async createEmail() {
		return this.request('/sec/scenario/email', 'GET');
	}
	async updateScenario(scenario: Scenario) {
		await this.request('/sec/scenario', 'PUT', { scenario: scenario.toJS() });
	}
	async newScenario(scenario: Scenario) {
		await this.request('/sec/scenario', 'POST', { scenario: scenario.toJS() });
	}
	async deleteScenario(scenario: Scenario) {
		await this.request('/sec/scenario', 'DELETE', { id: scenario.id });
	}
	async refreshScenario(scenario: Scenario) {
		await this.request(`/sec/scenario/${scenario.get('id')}`, 'GET');
	}
	async testAction(scenario: Scenario) {
		await this.request('/sec/scenario/test', 'POST', { scenario: scenario.toJS() });
	}
	async startScenario(scenarioIds, payload, pageId) {
		await this.request('/sec/scenario/start', 'POST', { scenarioIds, payload, pageId });
	}

	async getScenarioRecipients(actionConfig, scenarioId) {
		return await this.request(`/sec/scenario/${scenarioId}/action/recipients`, 'POST', actionConfig);
	}
}
