import Immutable from 'immutable';
import dayjs from 'dayjs';

const PositionModel = Immutable.Record({
	id: 0,
	latitude: 0,
	longitude: 0,
	altitude: 0,
	accuracy: 0,
	address: '',
	battery: 0,
	hasGPS: false,
	netloc: false,
	fixed: false,
	source: '',
	beaconId: 0,

	serverTime: 0,
	deviceTime: 0,
	fixTime: 0,

	deviceId: 0,
});

export default class Position extends PositionModel {
	id: number;
	latitude: number;
	longitude: number;
	altitude: number;
	accuracy: number;
	address: string;
	battery: number;
	hasGPS: boolean;
	netloc: boolean;
	fixed: boolean;
	source: string;
	beaconId: number;
	serverTime: number;
	deviceTime: number;
	fixTime: number;

	deviceId: number;

	get gMapsLink() : string {
		return `https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`;
	}

	get isOperatingAddress() : boolean {
		return !this.id && !!this.latitude;
	}

	get isValid() : boolean {
		if(this.isOperatingAddress) return true; //operating address
		if(this.get('fixed')) return true; //fixed location is always valid
		return this.hasValidCoordinates;
	}

	get hasValidCoordinates() : boolean {
		if(this.get('fixed')) return true; //fixed location is always valid
		return !!this.id && !!this.latitude && this.serverTimeDate.isValid() && this.fixTimeDate.isValid();
	}

	get serverTimeDate() : dayjs {
		return dayjs.unix(this.serverTime);
	}

	get fixTimeDate() : dayjs {
		return dayjs.unix(this.fixTime);
	}
}
