import { fromJS } from 'immutable';
import { SHOW_ALERT } from './constants';

export const initialState = fromJS({
	alert: false
});

function alertDialogReducer(state: Map = initialState, action) {
	switch (action.type) {
		case SHOW_ALERT:
			return state.set('alert', action.alert);
		default: return state;
	}
}

export default alertDialogReducer;
