import styled from 'styled-components';
import { alpha, Colors } from '../../utils/style/colors';


export const Wrapper = styled.div`
	overflow: hidden;
	
	left: 20px;
	right: 20px;
	top: 10px;
	bottom: 10px;
	position: absolute;
	
	.MuiDataGrid-row {
		position: relative;
	}
	.MuiDataGrid-row.connected { 		
		background: ${Colors.lightGreen} !important; 
	}
	.MuiDataGrid-row.configError { 
		background: ${alpha(Colors.danger, .2)} !important;
	}
	.MuiDataGrid-row.configuring {
		background: linear-gradient(90deg, ${Colors.lightYellow} 0%, ${Colors.gray30} 20%) !important;
		animation: requestFocus 1s infinite;
		&.connected {
			background: linear-gradient(90deg, ${Colors.lightYellow} 0%, ${Colors.lightGreen} 20%) !important;
		}
	}
`;

export const InfoContent = styled.div`
	table {
		width: 100%;
	}
	th {
		text-align: left;
	}
	hr {
		margin: 5px 0 !important;
	}
`;

export const StatusDot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.borderColor || props.color || 'transparent'};
    background-color: ${props => props.color};
    
    ${props => props.small && `
        width: 12px !important;
        height: 12px !important;
    `};
`;