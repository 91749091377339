import { createTheme } from '@mui/material';
import { getLocaleInfo } from '../../../i18n';
import { Colors } from './colors';

const muiTheme = (lang) => {
  let locale = lang ? getLocaleInfo(lang) : {};
  return createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
      typography: {
        fontSize: 12,
      },
      components: {
        MuiTooltip: {
          defaultProps: {
            arrow: true,
          },
          styleOverrides: {
            tooltip: {
              fontSize: '7pt',
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
          },
        },
        MuiTable: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            outlinedError: {
              backgroundColor: 'purple'
            }
          }
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              borderRadius: '0 !important',
            }
          },
        },
        MuiSwitch: {
          defaultProps: {
            color: 'primary',
          },
        },
        MuiCheckbox: {
          defaultProps: {
            color: 'primary',
          },
        },
        MuiSelect: {
          defaultProps: {
            variant: 'standard',
          },
        },
        MuiInputBase: {
          styleOverrides: {
            input: {
              font: '-',
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              borderRadius: 0,
              '& .MuiDataGrid-row:nth-of-type(even)': {
                backgroundColor: Colors.gray10
              }
            },
            toolbarContainer: {
              minHeight: 55,
              paddingLeft: 10
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontSize: '10pt',
              textTransform: 'none !important',
            },
            text: {
              fontSize: '10pt',
              textTransform: 'none !important',
            },
            label: {
              textTransform: 'none !important',
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              fontSize: '7pt',
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            input: {
              fontSize: '10pt'
            }
          }
        },
        MuiTextField: {
          defaultProps: {
            variant: "standard"
          }
        },
      },
      mixins: {},
      palette: {
        text: {
          hint: 'rgba(0, 0, 0, 0.38)',
        },
        mode: 'light',
        type: 'light',
        action: {
          main: Colors.gray50
        },
        primary: {
          main: Colors.primary(),
        },
        secondary: {
          main: Colors.secondary(),
          contrastText: Colors.gray60
        },
        status: {
          danger: Colors.danger,
          warning: Colors.warning,
          success: Colors.success,
        },
      },
    },
    locale.mui,
    locale.muiDataGrid
  );
}

export default muiTheme;