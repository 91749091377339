import {
	CREATE_DASHBOARD,
	DELETE_DASHBOARD,
	SAVE_DASHBOARD,
	SET_EDIT_DASHBOARD,
	SET_SELECTED_DASHBOARD,
	SET_LOADING, SUBSCRIBE_WS_TOPICS
} from "../constants/dashboard.constants";


export const createDashboard = name => ({type: CREATE_DASHBOARD, name});
export const setSelectedDashboard = id => ({type: SET_SELECTED_DASHBOARD, id});
export const setEditDashboard = dashboard => ({type: SET_EDIT_DASHBOARD, dashboard});
export const saveDashboard = name => ({type: SAVE_DASHBOARD, name});
export const deleteDashboard = id => ({type: DELETE_DASHBOARD, id});
export const subscribeToWsTopics = id => ({type: SUBSCRIBE_WS_TOPICS});
export const setLoading = loading => ({type: SET_LOADING, loading});
