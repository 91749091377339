import {
	CLOSE_ASS_REQUEST,
	SET_ACTIVE_ASS_REQUEST,
	SHOW_ASS_REQUEST_DIALOG,
	START_ASS_REQUEST,
} from '../constants/assistanceRequest.constants';

export const closeAssistanceRequest = (assistanceRequest, notes) => ({ type: CLOSE_ASS_REQUEST, assistanceRequest, notes });
export const setActiveAssistanceRequestId = id => ({ type: SET_ACTIVE_ASS_REQUEST, id });
export const showAssistanceRequestDialog = (show, tileConfig) => ({ type: SHOW_ASS_REQUEST_DIALOG, show, tileConfig });
export const startAssistanceRequest = config => ({ type: START_ASS_REQUEST, config });