import styled from 'styled-components';
import { alpha, Colors } from '../../utils/style/colors';
import { Text } from '../../utils/style/texts';

export const Wrapper = styled.div`
		padding: 15px; 10px;
		color: ${Colors.gray60};
		display: ${props => props.inline ? 'inline-flex' : 'flex'};
		align-items: center;
		
		.closeIcon {
			cursor: pointer;
			margin-left: ${props => props.small ? 5 : 10}px;
    		margin-right: 4px;
			opacity: .8;
		}
		
		.icon {
			margin-right: ${props => props.small ? 5 : 10}px;
			opacity: .7;
			display: inline-block;
			width: 18px !important;
		}
				
		${props => props.type === 'info' && `
			background-color: ${Colors.lightBlue};
		`};
		${props => props.type === 'warning' && `
			background-color: ${alpha(Colors.orange, .5)};
		`};
		${props => props.type === 'error' && `
			background-color: ${Colors.danger};
			color: white;
		`};
		${props => props.type === 'success' && `
			background-color: ${alpha(Colors.green, .5)};
		`};
		
		${props => props.small && `
			.icon { transform: scale(.7); }
			padding: 5px 8px;
		`};
		${props => props.iconRight && `
			justify-content: space-between;
			.icon { 
				margin-right: 0; 
				margin-left: ${props.small ? 5 : 10}px;
			}
		`};
		${props => props.animation && `
			animation: ${props.animation} .3s;
		`};
		${props => props.fullWidth && `
			width: 100%;
		`};
		
		@keyframes slideDown {
			from { transform: scaleY(0); transform-origin: top; }
			to { transform: scaleY(100%); transform-origin: top; }
		}
`;

export const Content = styled(Text)`
	flex: 1;
	margin: 0 5px;
`;
export const Clickable = styled.div`
	cursor: pointer;
	text-decoration: underline;
	${props => props.inline && `
		display: inline-block;
	`};
`;