import { fromJS, Map } from 'immutable';
import QuickText from '../../utils/models/QuickText';
import { WS_DATA_QUICK_TEXT, WS_DATA_QUICK_TEXTS } from '../constants/quickText.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	fetched: false,
	data: {},
});

function reducer(state: Map = initialState, action) {
	let quickTexts = state.get('data');
	switch(action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_QUICK_TEXT:
			return state.setIn(['data', action.data.id], new QuickText(action.data));
		case WS_DATA_QUICK_TEXTS:
			quickTexts = Map(action.data.map(q => new QuickText(q)).map(q => [q.id, q]));
			return state
				.set('data', quickTexts)
				.set('fetched', true);
		default:
			return state;
	}
}

export default reducer;
