import React from 'react';
import TextField from "./TextField";
import { NumericFormat, numericFormatter } from 'react-number-format';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props: any,
    ref,
) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />
    );
});

const getDefaultProps = (currency, isEnglish) => ({
    allowLeadingZeros: false,
    prefix: currency || '€',
    decimalScale: 2,
    fixedDecimalScale: true,
    valueIsNumericString: true,
    allowNegative: false,
    decimalSeparator: isEnglish ? '.' : ',',
    thousandSeparator: isEnglish ? ',' : '.'
})

export const formatCurrency = (amount, isEnglish, props = {}) => {
    let value = parseFloat(amount);
    if(isNaN(value)) value = 0;
    return numericFormatter(`${value}`, {
        ...getDefaultProps(props.currency, isEnglish),
        ...props
    });
};

const CurrencyInput = props => {
    const {
        isEnglish,
        currency,
        InputProps, //Ignore this one
        ...other
    } = props;
    return (
        <TextField
            {...other}
            onChange={other.onChange || (() => {})}
            disabled={other.disabled || !other.onChange}
            InputProps={{
                inputProps: {
                    ...getDefaultProps(currency, isEnglish),
                    ...other
                },
                inputComponent: NumericFormatCustom,
            }}
        />
    );

};

export default CurrencyInput;