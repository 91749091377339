import {
	ACCOUNT_EDIT,
	ARCHIVE_ACCOUNT,
	CREATE_TRIAL_ACCOUNT,
	DELETE_ACCOUNT,
	LOGIN_ACCOUNT,
} from './constants';

export const loginAccount = (account, device) => ({ type: LOGIN_ACCOUNT, account, device });
export const deleteAccount = account => ({ type: DELETE_ACCOUNT, account });
export const setEditAccount = account => ({ type: ACCOUNT_EDIT, account });
export const setArchived = (account, isArchived) => ({ type: ARCHIVE_ACCOUNT, account, isArchived });
export const createTrialAccount = (email, language, timezone, accountManagerId) => ({ type: CREATE_TRIAL_ACCOUNT, email, language, timezone, accountManagerId});
