import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';
import React from 'react';

const LoadingBar = (({barProps, ...props}) => (
	<Wrapper {...props}>
		<LinearProgress
			{...barProps}
			className="loadingBar"
			style={{ width: '100%', ...barProps?.style }}
			color="primary"
		/>
	</Wrapper>
));

const Wrapper = styled.div`
	
`;

export default LoadingBar