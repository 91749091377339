import UIListItem from '@mui/material/ListItem/ListItem';
import Grid from '@mui/material/Grid';

import React from 'react';
import Alarm from '../../../../utils/models/Alarm';
import { Bold, Danger, Secondary } from '../../../../utils/style/texts';
import { compactDateTimeFormat } from '../../../../utils/style/formats';
import styled from 'styled-components';
import Button from '../../../../components/Controls/Button';
import { ms_to_s } from '../../../../utils/time';
import { Flex } from '../../../../components/Container/Flex';
import { Tooltip } from '@mui/material';
import { SMSIcon } from '../../../../utils/icons';
import { useTranslation } from 'react-i18next';
import {Colors} from "../../../../utils/style/colors";

const ClickableAlarmListItem = props => {
	const {t} = useTranslation();

  const { onClick, date, user, accountName, typeName, isOpen, title, message, deviceName } = props;

  const alarm = props.alarm || new Alarm({});

  return (
    <ListItem divider onClick={() => onClick()}>
      <Grid container>
        <Grid item xs={3}>
          <Flex col justify="center">
            <Flex row align="center">
              <Secondary>{t(typeName ? 'type' : 'alarmType')}:&nbsp;</Secondary>
              <Danger>{title || t(typeName || alarm.typeName)}&nbsp;</Danger>
              {!!message && (
                <Tooltip title={message} style={{ marginLeft: 5 }}>
                  <span>
                      <SMSIcon color="action"/>
                  </span>
                </Tooltip>
              )}
            </Flex>
            <Secondary>{(date || alarm.alarmDate).format(compactDateTimeFormat)}</Secondary>
          </Flex>
        </Grid>
        <Grid item xs={4}>
          {user.get('accountId') === alarm.get('accountId') ? (
            <>
              <Secondary>{t('device')}: </Secondary>
              <Bold>{deviceName}</Bold>
            </>
          ) : (
            <>
              {user.isARC && (
                <>
                  <Secondary>{t('account')}: </Secondary>
                  <Bold>{accountName}</Bold><br/>
                </>
              )}
              <Secondary>{t('device')}: </Secondary>
              <Bold>{deviceName}</Bold>
            </>
          )}
        </Grid>
        <Grid item xs={3}>
          {!alarm.isOpen && (
            <div>
              <Secondary>{t('timeToClose')}: </Secondary>
              <Bold>{ms_to_s(alarm.checkedDate.diff(alarm.alarmDate))} {t('sec')}</Bold><br/>
            </div>
          )}
        </Grid>
        {(alarm.id || isOpen) && (
          <Grid item xs={2}>
            <FloatRight>
              <Button>{!alarm.isOpen || (alarm.arc && user.isMonitorUser) ? t('show') : t('close.alarm')}</Button>
            </FloatRight>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

export default ClickableAlarmListItem;

const FloatRight = styled.div`
	float: right;
`;
const ListItem = styled(UIListItem)`
	border-bottom: 1px solid ${Colors.gray30};
`;