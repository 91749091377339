import { all, put, select, takeLatest } from 'redux-saga/effects';
import { DashboardAPI } from '../../utils/API/DashboardAPI';
import {
	CREATE_DASHBOARD,
	DELETE_DASHBOARD,
	SAVE_DASHBOARD,
	SET_SELECTED_DASHBOARD, SUBSCRIBE_WS_TOPICS,
	WS_DATA_DASHBOARD,
	WS_DATA_PRESENTATION_PAGE,
} from '../constants/dashboard.constants';
import { setEditDashboard, setLoading, setSelectedDashboard } from '../actions/dashboard.actions';
import { selectEditDashboard } from '../selectors/dashboard.selector';
import { selectOptions } from '../../containers/PresentationPage/selectors';
import {selectCurrentUser} from "../selectors/app.selectors";
import _flatten from 'lodash/flatten';
import _uniq from 'lodash/uniq';
import {MyWebSocket} from "../../utils/MyWebSocket";
import Dashboard from "../../utils/models/Dashboard";
import {getData} from "../actions/app.actions";
import {APP_MOUNTED} from "../constants/app.constants";
import {AUTH_SUCCESS} from "../../containers/LoginPage/constants";
import {getActivePage} from "../../utils/functions";

const api = new DashboardAPI();

function *createDashboard({name}) {
	const dashboard = yield api.create(name);
	yield put(setSelectedDashboard(dashboard.id));
}

function *deleteDashboard({id}) {
	yield api.delete(id);
	yield put(setSelectedDashboard(-1));
}

function *saveSelectedDashboard({id}) {
	if(id > 0) localStorage.setItem('dashboard', id);
	else localStorage.removeItem('dashboard');
	yield Promise.resolve();
}

function *saveDashboard({name}) {
	let dashboard = yield select(selectEditDashboard());
	dashboard = dashboard.set('name', name);
	yield api.update(dashboard);
	yield all([
		put(setEditDashboard()),
		put(setLoading(false))
	]);
}
function *refreshPresentationPage({data}) {
	const page = yield select(selectOptions());
	if(page && +page.id === +data.id) {
		window.location.reload();
	}
}
function *refreshPresentationPage2({data}) {
	const page = yield select(selectOptions());
	if(page && page.get('dashboardIds').indexOf(+data.id) >= 0) {
		window.location.reload();
	}
}

function *subscribeWsTopics() {
	const user = yield select(selectCurrentUser());
	if(!user.isPresentation) return;
	const presentationPage = yield select(selectOptions());
	if(presentationPage) {
		const dashboards = yield api.getDashboards(presentationPage.get('dashboardIds'));
		const topics = _uniq(_flatten(dashboards.map(d => new Dashboard(d).wsTopics)));
		topics.push('dashboard');
		topics.push('dashboards');
		topics.map(topic => MyWebSocket.inst().subscribe(topic));
	} else if(['live-location', 'alarm-location'].indexOf(getActivePage()) >= 0) {
		MyWebSocket.inst().subscribe('*');
	}
	yield put(getData());
}

export const dashboardSagas = [
	takeLatest(CREATE_DASHBOARD, createDashboard),
	takeLatest(DELETE_DASHBOARD, deleteDashboard),
	takeLatest(SET_SELECTED_DASHBOARD, saveSelectedDashboard),
	takeLatest(SAVE_DASHBOARD, saveDashboard),
	takeLatest(WS_DATA_PRESENTATION_PAGE, refreshPresentationPage),
	takeLatest(WS_DATA_DASHBOARD, refreshPresentationPage2),
	takeLatest(APP_MOUNTED, subscribeWsTopics),
	takeLatest(AUTH_SUCCESS, subscribeWsTopics),
	takeLatest(SUBSCRIBE_WS_TOPICS, subscribeWsTopics)
];