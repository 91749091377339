import {
    GET_CUSTOMERS,
    DELETE_CUSTOMER,
    GET_SALES_INVOICES,
    GET_SALES_ITEMS,
    GET_GENERAL_LEDGER_ACCOUNTS,
    GENERAL_LEDGER_ACCOUNTS_CHANGED,
    SALES_ITEMS_CHANGED,
    SALES_INVOICES_CHANGED,
    CUSTOMERS_CHANGED,
    TAX_TEMPLATES_CHANGED,
    PAYMENT_TERMS_TEMPLATES_CHANGED,
    GET_TAX_TEMPLATES,
    GET_PAYMENT_TERMS_TEMPLATES,
    GET_PRICING_RULES,
    DELETE_PRICING_RULE,
    PRICING_RULES_CHANGED,
    GET_SUBSCRIPTIONS,
    SUBSCRIPTIONS_CHANGED
} from '../constants/administration.constants';

export const getGeneralLedgerAccounts = (force) => ({type: GET_GENERAL_LEDGER_ACCOUNTS, force});
export const getSalesItems = (force) => ({type: GET_SALES_ITEMS, force});
export const getSalesInvoices = (force, callback) => ({type: GET_SALES_INVOICES, force, callback});
export const getCustomers = (force) => ({type: GET_CUSTOMERS, force});
export const deleteCustomer = (customer) => ({type: DELETE_CUSTOMER, customer});
export const getPricingRules = (force) => ({type: GET_PRICING_RULES, force});
export const deletePricingRule = id => ({type: DELETE_PRICING_RULE, id});
export const getTaxTemplates = (force) => ({type: GET_TAX_TEMPLATES, force});
export const getPaymentTermsTemplates = (force) => ({type: GET_PAYMENT_TERMS_TEMPLATES, force});
export const generalLedgerAccountsChanged = data => ({type: GENERAL_LEDGER_ACCOUNTS_CHANGED, data});
export const salesItemsChanged = data => ({type: SALES_ITEMS_CHANGED, data});
export const salesInvoicesChanged = data => ({type: SALES_INVOICES_CHANGED, data});
export const customersChanged = data => ({type: CUSTOMERS_CHANGED, data});
export const pricingRulesChanged = data => ({type: PRICING_RULES_CHANGED, data});
export const taxTemplatesChanged = data => ({type: TAX_TEMPLATES_CHANGED, data});
export const paymentTermsChanged = data => ({type: PAYMENT_TERMS_TEMPLATES_CHANGED, data});
export const getSubscriptions = (force) => ({type: GET_SUBSCRIPTIONS, force});
export const subscriptionsChanged = data => ({type: SUBSCRIPTIONS_CHANGED, data});