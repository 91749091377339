import { APP } from '../../redux/constants/app.constants';

export const LOGIN_PAGE = `${APP}/Login`;
export const AUTH_SEND = `${LOGIN_PAGE}/AUTH_SEND`;
export const AUTH_SUCCESS = `${LOGIN_PAGE}/AUTH_SUCCESS`;
export const AUTH_FAIL = `${LOGIN_PAGE}/AUTH_FAIL`;
export const CHANGE_EMAIL = `${LOGIN_PAGE}/CHANGE_EMAIL`;
export const CHANGE_PASSWORD = `${LOGIN_PAGE}/CHANGE_PASSWORD`;
export const TWO_FA_IMAGE_CHANGED = `${LOGIN_PAGE}/TWO_FA_IMAGE_CHANGED`;
export const LOGIN_2FA = `${LOGIN_PAGE}/LOGIN_2FA`;
export const CODE_ERROR = `${LOGIN_PAGE}/CODE_ERROR`;
export const RESET_2FA = `${LOGIN_PAGE}/RESET_2FA`;
export const BACK_TO_LOGIN = `${LOGIN_PAGE}/BACK_TO_LOGIN`;
export const REQUEST_CODE_VIA_EMAIL = `${LOGIN_PAGE}/REQUEST_CODE_VIA_EMAIL`;