import React from 'react';
import {useTranslation} from 'react-i18next';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {compose} from 'redux';
import FormControl from '@mui/material/FormControl';
import Checkbox from '../Controls/Checkbox';
import {forAnyObject} from '../../utils/models/Scenario';
import {selectGeofences, selectGeofencesFetched} from '../../redux/selectors/geofence.selector';
import {capitalize} from 'lodash/string';
import {CircularProgress} from '@mui/material';
import Alert from '../Alert';
import MuiAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {CheckBoxCheckedIcon, CheckBoxUncheckedIcon} from "../../utils/icons";
import {Flex} from "../Container/Flex";
import {moduleIds} from "../../utils/models/Module";
import {selectCurrentUser} from "../../redux/selectors/app.selectors";

/* eslint jsx-a11y/role-supports-aria-props: 0 */
const inputLabelRef = React.createRef();
const GeofenceSelector = props => {
    const [t] = useTranslation();
    const {user,fetched, geofences, accountIds, renderValue, disabled, isSource, isDeviceTrigger} = props;
    if(!user.hasModule(moduleIds.Geofences)) return null;
    let value = props.value;
    
    const onChange = newValue => {
        newValue = newValue.filter(v => v <= 0 || geofences.find(d => +d.id === +v));
        props.onChange(newValue);
    };
    
    const defaultLabel = () => {
        if (value.indexOf(forAnyObject) >= 0) return capitalize(isDeviceTrigger ? t('allPresentGeofences') : t('allGeofences'));
        const items = value.filter(v => v <= 0 || geofences.find(d => +d.id === +v));
        if (items.length > 1) return t('geofences.count', {count: items.length});
        if (items.length === 1) return (geofences.find(d => d.id === items[0]) || {}).name;
        return '';
    }

    if (!Array.isArray(value)) {
        setTimeout(() => onChange([]));
        return null;
    }

    const options = [{
        label: capitalize(isDeviceTrigger ? t('allPresentGeofences') : isSource ? t('anyGeofence') : t('allGeofences')),
        value: forAnyObject,
    }, ...geofences.map(g => ({
        label: g.name,
        value: g.id,
        geofence: g
    }))];
    const geofenceMap= {};
    
    geofences.map(g => geofenceMap[g.id] = g);
    
    if (value && value.length) {
        value = value.filter(v => v <= 0 || !geofences[v]);
    }

    const forAny = value.indexOf(forAnyObject) >= 0;
    
    return (
        <Flex>
            {geofences.size ? (
                <FormControl ref={inputLabelRef} fullWidth disabled={!!disabled}>
                    <MuiAutocomplete
                        multiple
                        value={value}
                        renderOption={({dataOptionIndex, ...props}, o, {selected}) => (
                            <li {...props}
                                aria-selected={false}
                                data-geofenceid={o.value}
                                data-checked={value.indexOf(o.value) >= 0 ? 'yes' : 'no'}
                            >
                                <Checkbox
                                    icon={<CheckBoxUncheckedIcon/>}
                                    checkedIcon={<CheckBoxCheckedIcon/>}
                                    indeterminate={forAny && o.value > 0}
                                    color={o.value > 0 ? 'primary' : 'secondary'}
                                    style={{marginRight: 8, pointerEvents: 'none'}}
                                    checked={value.indexOf(o.value) >= 0 || forAny}
                                />
                                {o.label}
                            </li>
                        )}
                        isOptionEqualToValue={(o, v) => value.indexOf(v) >= 0}
                        disabled={!!disabled}
                        disableCloseOnSelect
                        renderTags={() => renderValue ? renderValue() : defaultLabel()}
                        getOptionLabel={o => o.geofence?.filterString() || 'any'}
                        groupBy={o => accountIds?.length > 1 ? o.geofence?.accountName : null}
                        onChange={e => {
                            const clickedGeofenceId = +e.target.dataset.geofenceid;
                            if(isNaN(clickedGeofenceId)) return onChange([]);
                            const wasChecked = e.target.dataset.checked === 'yes';
                            if(wasChecked) {
                                if(clickedGeofenceId === forAnyObject) {
                                    onChange([]);
                                } else {
                                    onChange(value.filter(v => v !== clickedGeofenceId));
                                }
                            } else {
                                if(clickedGeofenceId === forAnyObject) {
                                    onChange([forAnyObject]);
                                } else {
                                    if(forAny) {
                                        onChange(options.filter(o => o.value > 0 && o.value !== clickedGeofenceId).map(o => o.value));
                                    } else {
                                        onChange([...value, clickedGeofenceId]);
                                    }
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                {...params}
                                label={t('geofences')}
                            />
                        )}
                        options={options}
                    />
                </FormControl>
            ) : (
                <>
                    {fetched ? <Alert type="info" dismissable={false}>{t('noGeofences')}</Alert> : (
                        <CircularProgress size={16}/>
                    )}
                </>
            )}
        </Flex>
    );
}


export const mapDispatchToProps = () => ({});

const mapStateToProps = createStructuredSelector({
    geofences: selectGeofences(),
    fetched: selectGeofencesFetched(),
    user: selectCurrentUser()
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(GeofenceSelector);