import { takeLatest, all } from 'redux-saga/effects';
import { TeamAPI } from '../../utils/API/TeamAPI';
import { SAVE_TEAM, DELETE_TEAM } from '../constants/team.constants';

const api = new TeamAPI();

function* saveTeam({team}) {
	if(team.id) {
		yield api.updateTeam(team);
	} else {
		yield api.newTeam(team);
	}
}
function* deleteTeam({team}) {
	yield all(team.map(t => api.deleteTeam(t)));
}

export const teamSagas = [
	takeLatest(SAVE_TEAM, saveTeam),
	takeLatest(DELETE_TEAM, deleteTeam)
];