import Immutable from 'immutable';
import validator from 'validator';

const ARCModel = Immutable.Record({
	id: 0,
	key: '',
	name: '',
	color: '',
	label: '',
	email: '', //only used for creation form
	proms: [],
	whitelistNumbers: [],
	variables: [],
	salesItem: null,
	
	accounts: 0,
	devices: 0
});

export default class ARC extends ARCModel {
	id: number;
	key: string;
	name: string;
	color: string;
	label: string;
	email: string;
	proms: any[];
	whitelistNumbers: string[];
	variables: any[];
	salesItem: ?string;
	
	accounts: number;
	devices: number;
	
	forKey(key) : boolean {
		if(!key) return false;
		return this.key.trim().lc() === key.split(':')[0].trim().lc();
	}
	
	get hasProms() {
		return !!this.proms?.length;
	}
	get isValid() {
		if(this.key?.length < 3) return false;
		if(this.name?.length < 3) return false;
		if(!this.color) return false;
		if(!this.label) return false;
		if(!this.id && !validator.isEmail(this.email)) return false;
		if(this.salesItem !== null && this.salesItem?.length < 10) return false;
		return true;
	}
	get filterString() {
		return [
			this.key,
			this.name,
			this.label
		].join('').lc();
	}
	
}
