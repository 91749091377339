import UIChip from '@mui/material/Chip';

import React from 'react';
import * as styles from './style';

const Chip = props => {

  const { onClick, color, collapse, small, disabled, borderColor, ...other } = props;
  return (
    <styles.Wrapper
      color={color}
      medium={props.avatar || props.onDelete}
      disabled={disabled}
      clickable={!disabled && !!props.onClick}
      collapsible={collapse !== undefined}
      collapse={collapse ? 1 : 0}
      small={small ? 1 : 0}>
      <UIChip
        onClick={() => {
          if (!disabled && onClick) onClick();
        }}
        style={{
          border: borderColor ? `2px solid ${borderColor}` : undefined,
        }}
        {...other}
      />
    </styles.Wrapper>
  );
};
// noinspection JSUnusedLocalSymbols
export default React.forwardRef((props, ref) => <Chip {...props} />);