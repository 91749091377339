import {fromJS, List, Map} from 'immutable';
import {
	CUSTOMERS_CHANGED,
	DELETE_CUSTOMER,
	DELETE_PRICING_RULE, GENERAL_LEDGER_ACCOUNTS_CHANGED,
	GET_CUSTOMERS, GET_GENERAL_LEDGER_ACCOUNTS,
	GET_PRICING_RULES,
	GET_SALES_INVOICES,
	GET_SALES_ITEMS, GET_SUBSCRIPTIONS,
	PAYMENT_TERMS_TEMPLATES_CHANGED,
	PRICING_RULES_CHANGED,
	SALES_INVOICES_CHANGED,
	SALES_ITEMS_CHANGED, SUBSCRIPTIONS_CHANGED,
	TAX_TEMPLATES_CHANGED
} from "../constants/administration.constants";
import GeneralLedger from "../../utils/models/Administration/GeneralLedger";

export const initialState = fromJS({
	customersFetched: false,
	salesItemsFetched: false,
	salesInvoicesFetched: false,
	pricingRulesFetched: false,
	generalLedgerAccountsFetched: false,
	subscriptionsFetched: false,
	
	customers: [],
	salesItems: [],
	salesInvoices: [],
	generalLedgerAccounts: [],
	taxTemplates: [],
	paymentTermsTemplates: [],
	pricingRules: [],
	subscriptions: []
});

function reducer(state: Map = initialState, action) {
	switch(action.type) {
		case GET_SUBSCRIPTIONS:
			return state.set('subscriptionsFetched', !action.force && state.get('subscriptionsFetched'));
		case SUBSCRIPTIONS_CHANGED:
			return state.set('subscriptions', action.data).set('subscriptionsFetched', true);
		case GET_CUSTOMERS:
			return state.set('customersFetched', !action.force && state.get('customersFetched'));
		case DELETE_CUSTOMER:
			return state.set('customersFetched', false);
		case CUSTOMERS_CHANGED:
			return state.set('customers', action.data).set('customersFetched', true);
		case GET_GENERAL_LEDGER_ACCOUNTS:
			return state.set('generalLedgerAccountsFetched', !action.force && state.get('generalLedgerAccountsFetched'));
		case GENERAL_LEDGER_ACCOUNTS_CHANGED:
			return state
				.set('generalLedgerAccounts', List(action.data.map(r => new GeneralLedger(r))))
				.set('generalLedgerAccountsFetched', true);
		case GET_PRICING_RULES:
			return state.set('pricingRulesFetched', !action.force && state.get('pricingRulesFetched'));
		case DELETE_PRICING_RULE:
			return state.set('pricingRulesFetched', false);
		case PRICING_RULES_CHANGED:
			return state.set('pricingRules', action.data).set('pricingRulesFetched', true);
		case GET_SALES_ITEMS:
			return state.set('salesItemsFetched', !action.force && state.get('salesItemsFetched'));
		case SALES_ITEMS_CHANGED:
			return state.set('salesItems', action.data).set('salesItemsFetched', true);
		case GET_SALES_INVOICES:
			return state.set('salesInvoicesFetched', !action.force && state.get('salesInvoicesFetched'));
		case SALES_INVOICES_CHANGED:
			return state.set('salesInvoices', action.data).set('salesInvoicesFetched', true);
		case TAX_TEMPLATES_CHANGED:
			return state.set('taxTemplates', action.data);
		case PAYMENT_TERMS_TEMPLATES_CHANGED:
			return state.set('paymentTermsTemplates', action.data);
		default:
			return state;
	}
}

export default reducer;
