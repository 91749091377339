import {
	BTIcon, NFCIcon, RadioSignalIcon,
	WiFiIcon,
} from '../icons';

import React from 'react';
import Immutable from 'immutable';
import { Colors } from '../style/colors';
import Geofence, {getCenterArray, setGeofenceShape} from './Geofence';
import {decToHex, hexToDec} from "../functions";

const BeaconModel = Immutable.Record({
	id: 0,
	name: '',
	key: '',
	mac: '',
	latitude: 0,
	longitude: 0,
	radius: 0,
	type: '',
	subtype: '-',
	accountId: null,
	accountName: '',
	siteId: null,
	hidden: false,
	batteryLowSince: null,
	major: null,
	minor: null,
	points: [],
	shape: 'circle'
});

export default class Beacon extends BeaconModel {
	id: number;
	name: string;
	key: string;
	mac: string;
	latitude: number;
	longitude: number;
	radius: number;
	type: string;
	subtype: string;
	accountId: number;
	accountName: string;
	siteId: number;
	hidden: boolean;
	batteryLowSince: number;
	major: number;
	minor: number;
	points: [];
	shape: string;
	
	get canResetBattery() {
		return this.get('type') === 'srd';
	}
	
	decToHex() {
		return this.set('major', this.get('major') || this.get('major') === 0 ? decToHex(this.get('major'), 4) : this.get('major'))
			.set('minor', this.get('minor') || this.get('minor') === 0 ? decToHex(this.get('minor'), 4) : this.get('minor'))
	}
	hexToDec() {
		return this.set('major', this.get('major') ? hexToDec(this.get('major')) : this.get('major'))
			.set('minor', this.get('minor') ? hexToDec(this.get('minor')) : this.get('minor'))
	}

	get positionArray() {
		return [this.get('latitude'), this.get('longitude')];
	}
	get isSRD() {
		return this.get('type').indexOf('srd') === 0;
	}
	get typeIcon() {
		if(this.get('type') === 'nfc') return <NFCIcon />;
		if(this.get('type') === 'bt') return <BTIcon />;
		if(this.isSRD) return <RadioSignalIcon />;
		if(this.get('type') === 'wifi') return <WiFiIcon />;
		return null;
	}
	get iconColor() {
		return Colors.beacons[this.get('type')];
	}
	get typeName() {
		if(this.get('type') === 'bt') return 'bluetoothBeacon';
		if(this.isSRD) return 'radioBeacon';
		if(this.get('type') === 'wifi') return 'wifiBeacon';
		return null;
	}

	get geofence() {
		return new Geofence(this.toJS());
	}
	setShape(shape) {
		return setGeofenceShape(this, shape, this.radius);
	}
	get centerArray() : [] {
		return getCenterArray(this);
	}
	
	get majorMinor() {
		if(!this.get('major') || !this.get('minor'))
			return null;
		if(hexToDec(this.get('major')) === 0 || hexToDec(this.get('minor')) === 0)
			return null;
		return `${this.get('major')}:${this.get('minor')}`;
	}
	
	get displayKey() {
		if(!this.get('type')) return '';
		if(this.isSRD) return this.get('key');
		if(this.get('type') === 'wifi') return this.get('mac');
		if(this.get('type') === 'bt') {
			if(this.get('subtype') === '-') {
				return this.majorMinor || this.get('mac');
			}
		}
		return '';
	}

	filterString() {
		return [
			this.get('id'),
			this.get('type'),
			this.get('name'),
			this.get('accountName'),
			this.get('mac'),
			this.get('key'),
			this.majorMinor,
		].join('|').lc();
	}

	constructor(props) {
		if(props.range && !props.radius) {
			props.radius = props.range;
		}
		super(props);
	}

	clone() {
		return this.set('id', this.id);
	}

	static create(props) {
		return new Beacon({
			radius: 20,
			...props
		});
	}
}
