import Immutable from "immutable";

const SalesItemModel = Immutable.Record({
    id: '',
    item_name: '',
    ledger: '',
    editable: false,
    dynamic: false
});

export default class SalesItem extends SalesItemModel {
    id: string;
    item_name: string;
    ledger: string;
    editable: boolean;
    dynamic: boolean;

    get filterString() {
        return JSON.stringify([
            this.get('id'),
            this.get('name'),
            this.get('ledger')
        ]).lc();
    }

}