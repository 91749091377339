import { fromJS } from 'immutable';
import { AUTH_SUCCESS } from '../LoginPage/constants';
import { GET_PRESENTATION_PAGE, HIDE_PRESENTATION_PAGE, URL_CHANGED } from './constants';
import { USER_CHANGED } from '../../redux/constants/app.constants';

export const initialState = fromJS({
  show: false,
  loading: false,
  pageType: null,
  url: null,
  options: null
});

function appReducer(state: Map = initialState, action) {
  switch (action.type) {
    case USER_CHANGED:
    case AUTH_SUCCESS:
      return state.set('currentUser', action.user)
    case HIDE_PRESENTATION_PAGE:
      return state
        .set('show', false);
    case GET_PRESENTATION_PAGE:
      return state
        .set('show', true)
        .set('url', null)
        .set('pageType', action.pageType)
        .set('options', action.opts)
        .set('loading', true);
    case URL_CHANGED:
      return state
        .set('url', action.url)
        .set('loading', false);
    default: return state;
  }
}

export default appReducer;
