import React from 'react';
import MUIDialog from '@mui/material/Dialog';
import ErrorBoundary from './ErrorBoundary';

const Dialog = ({onError, ...other}) => {

  return (
    <ErrorBoundary onError={onError}>
      <MUIDialog {...other} />
    </ErrorBoundary>
  );

};
export default Dialog;