import { createSelector } from 'reselect';
import { BEACONS } from '../constants/beacon.constants';
import { initialState } from '../reducers/beacon.reducer';
import {ADMIN_PAGE} from "../../containers/AdminPage/constants";
import {fromJS, List} from "immutable";

const selectState = state => state[BEACONS] || initialState;
const selectAdminState = state => state[ADMIN_PAGE] || fromJS({});

export const selectBeacons = () => createSelector([selectState, selectAdminState], (state, adminState) => {
    let beacons = state.get('data').toList();
    if(!beacons?.size) {
        beacons = List(adminState.get('beacons'));
    }
    return beacons.sortBy(d => d.accountName + d.name);
});

export const selectBeaconsFetched = () => createSelector(selectState, state => state.get('fetched'));
export const selectSelectedBeacon = () => createSelector(selectState, state => state.get('selectedBeacon'));