// noinspection JSUnfilteredForInLoop

export default class Presence {

  _byGeofence = {};
  _byDevice = {};

  constructor(data) {
    this._byGeofence = data;
    for(let gId in data) {
      for(let dId in data[gId]) {
        if(!this._byDevice[dId]) this._byDevice[dId] = {};
        this._byDevice[dId][gId] = !!data[gId][dId];
      }
    }
  }

  byGeofence(gId) {
    return this._byGeofence[gId] || {};
  }
  byDevice(dId) {
    return this._byDevice[dId] || {};
  }

  presentDevices(gId, devices) {
    const deviceIds = Object.keys(this.byGeofence(gId)).filter(dId => this.byGeofence(gId)[dId]).map(dId => +dId);
    return devices.filter(d => deviceIds.indexOf(d.id) >= 0 && d.connected);
  }

}
