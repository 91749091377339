import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { setEditAccount } from '../../containers/AccountPage/actions';
import {accountsChanged, getProms, setSelectedAccount} from '../../containers/AdminPage/actions';
import { UPDATE_ACCOUNT } from '../constants/account.constants';
import { AdminAPI } from '../../utils/API/AdminAPI';
import { LOGIN_ACCOUNT } from '../../containers/AccountPage/constants';
import { selectCurrentUser } from '../selectors/app.selectors';
import * as Sentry from "@sentry/browser";
import {enqueueSnackbar} from "../actions/app.actions";
import {getI18n} from "react-i18next";
import Account from "../../utils/models/Account";

const api = new AdminAPI();

function* updateAccount({account}) {
	try {
		if (account.id) {
			yield api.updateAccount(account);
		} else {
			const result = yield api.addAccount(account);
			account = new Account(result);
		}
	} catch (e) {
		yield put(enqueueSnackbar(getI18n().t('somethingWentWrong'), { variant: 'error' }));
		Sentry.captureException(e);
		return;
	}
	yield put(setEditAccount(null));
	yield put(getProms());
	const newAccounts = yield api.getAccounts();
	yield put(accountsChanged(newAccounts));
	yield put(setSelectedAccount(account));
	yield put(enqueueSnackbar(getI18n().t('accountSaved')));
}

function* loginAccount({account, device}) {
	const adminUser = yield select(selectCurrentUser());
	const user = yield api.loginAccount(account.id, adminUser.get('token'), adminUser.isSuperAdmin ? 'adminUser' : 'accountManagerUser');
	localStorage.setItem('user', JSON.stringify(user.toJS()));
	window.location.href = device ? `/devices/${device.id}` : '/';
}

export const accountSagas = [
	takeEvery(LOGIN_ACCOUNT, loginAccount),
	takeLatest(UPDATE_ACCOUNT, updateAccount)
];