import styled from 'styled-components';
import { isDark } from '../../utils/style/colors';

export const Wrapper = styled.div`
	> div {
		${props => props.small && `
			width: 30px;
    		height: 30px;
		`};
		transition: all .3s;
		${props => props.color && `
			background-color: ${props.color};
			${isDark(props.color) && `
				> * { filter: brightness(100); }
			`}
		`};
		${props => props.border && 'transform: scale(.7);'};
	}
`;