import { fromJS, Map } from 'immutable';
import Scenario from '../../utils/models/Scenario';
import {
	GET_SCENARIOS,
	SCENARIOS_CHANGED,
	SELECT_SCENARIO,
	WS_DATA_SCENARIO,
	WS_DATA_SCENARIOS,
} from '../constants/scenario.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	data: {},
	fetched: false,
	selectedScenario: null
});

export default function scenariosReducer(state: Map = initialState, action) {
	let scenarios = state.get('data');
	switch (action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case GET_SCENARIOS:
			return state.set('fetched', false);
		case WS_DATA_SCENARIO:
			return state
				.setIn(['data', action.data.id], new Scenario(action.data))
				.set('fetched', true);
		case WS_DATA_SCENARIOS:
			scenarios = Map(action.data.map(d => new Scenario(d)).map(d => [d.id, d]));
			return state
				.set('data', scenarios)
				.set('fetched', true);
		case SCENARIOS_CHANGED:
			return state
				.set('data', action.scenarios)
				.set('fetched', true);
		case SELECT_SCENARIO:
			return state.set('selectedScenario', action.scenario);
		default:
			return state;
	}
}