import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
	FACTORY_RESTORE,
	FORCE_SETTINGS,
	REBOOT, REQUEST_FIRMWARE,
	REQUEST_LOCATION,
	SAVE_DEVICES,
	SELECT_DEVICE,
	SEND_COMMAND,
	SEND_MESSAGE,
	SEND_SERVER_CONFIG,
	SEND_SMS, SHUT_DOWN,
	WS_DATA_DEVICE,
} from '../constants/device.constants';
import { DeviceAPI } from '../../utils/API/DeviceAPI';
import {selectSelectedDevice} from '../selectors/device.selector';
import {reselectDevice} from '../actions/device.actions';
import { selectBeacon } from '../actions/beacon.actions';
import { selectBeacons } from '../selectors/beacon.selector';
import {selectCurrentUser} from "../selectors/app.selectors";
import {APP_MOUNTED, USER_CHANGED} from "../constants/app.constants";
import Device from "../../utils/models/Device";

const api = new DeviceAPI();

function* saveDevices({devices}) {
	yield all(devices.map(d => api.saveDevice(d)));
}

function* sendMessage({message, devices}) {
	yield api.sendMessage(message, devices);
}

function* sendCommand({command, device}) {
	yield api.sendCommand(command, device);
}
function* sendSMS({command, device}) {
	yield api.sendSMS(command, device);
}

function* sendServerConfig({device, config}) {
	if(config?.toJS) config = config.toJS();
	if(device.toJS) device = device.toJS();
	yield api.sendServerConfig({
		...(config || device),
		id: device.id
	});
}

function* requestLocation({device}) {
	yield api.requestLocation(device);
}

function* requestFirmware({device}) {
	yield api.requestFirmware(device);
}

function* reboot({device}) {
	yield api.reboot(device);
}

function* _reselectDevice() {
	const selectedDevice = yield select(selectSelectedDevice());
	yield put(reselectDevice(selectedDevice));
}

function* getStatus() {
	const device = yield select(selectSelectedDevice());
	const selectedDeviceId = localStorage.getItem('selectedDeviceId');
	if(device && +device.id !== +selectedDeviceId) {
		localStorage.setItem('selectedDeviceId', device.id);
		yield api.getStatus(device);
	} else {
		localStorage.removeItem('selectedDeviceId');
	}
}

function *selectBeaconForDevice() {
	const device = yield select(selectSelectedDevice());
	if(device && device.get('beaconIds') && device.get('beaconIds').length) {
		const beacons = yield select(selectBeacons());
		const beacon = beacons.find(b => +b.id === +device.get('beaconIds')[0]);
		yield put(selectBeacon(beacon));
	}
}

function* forceSettings({device, onlyFailed}) {
	yield api.forceSettings(device, onlyFailed);
}
function *shutDown({device}) {
	yield api.shutDown(device.id);
}
function *factoryRestore({device}) {
	yield api.factoryRestore(device.id);
}
function* getDeviceProtocols() {
	if(Device.protocols) return;
	const user = yield select(selectCurrentUser());
	if(!user) return;
	Device.protocols = yield api.getDeviceProtocols();
}

export const deviceSagas = [
	takeLatest(APP_MOUNTED, getDeviceProtocols),
	takeLatest(USER_CHANGED, getDeviceProtocols),
	
	takeLatest(WS_DATA_DEVICE, _reselectDevice),
	takeLatest(SAVE_DEVICES, saveDevices),
	takeLatest(SEND_MESSAGE, sendMessage),
	takeLatest(SEND_COMMAND, sendCommand),
	takeLatest(SEND_SMS, sendSMS),
	takeLatest(SEND_SERVER_CONFIG, sendServerConfig),
	takeLatest(REQUEST_LOCATION, requestLocation),
	takeLatest(REQUEST_FIRMWARE, requestFirmware),
	takeLatest(SELECT_DEVICE, getStatus),
	takeLatest(SELECT_DEVICE, selectBeaconForDevice),
	takeLatest(REBOOT, reboot),
	takeLatest(FORCE_SETTINGS, forceSettings),
	takeLatest(SHUT_DOWN, shutDown),
	takeLatest(FACTORY_RESTORE, factoryRestore)
];