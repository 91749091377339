import React from 'react';

const SafeguardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 151 151"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill="#F63E43"
        d="M75.425 150.851C33.769 150.851 0 117.082 0 75.425 0 33.769 33.769 0 75.425 0c41.657 0 75.424 33.769 75.424 75.425 0 41.657-33.767 75.426-75.424 75.426z"
      />
      <path
        fill="#FFFFFE"
        d="M129.746 58.59c-.434-20.609-19.313-21.963-39.901-21.529-24.64.52-40.532 8.182-44.318 28.597C30.649 66.161 21 71.91 21 87.29c0 21.691 19.832 23.531 41.505 23.531 26.874 0 44.042-8.27 47.698-31.474 12.203-1.464 19.826-7.328 19.543-20.758"
      />
    </g>
  </svg>
);

export default SafeguardIcon;