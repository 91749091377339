
export function getBoundingBox(pLatitude, pLongitude, pDistanceInMeters) {
	const latRadian = pLatitude.toRad();

	const degLatKm = 110.574235;
	const degLongKm = 110.572833 * Math.cos(latRadian);
	const deltaLat = pDistanceInMeters / 1000.0 / degLatKm;
	const deltaLong = pDistanceInMeters / 1000.0 / degLongKm;

	const topLat = pLatitude + deltaLat;
	const bottomLat = pLatitude - deltaLat;
	const leftLng = pLongitude - deltaLong;
	const rightLng = pLongitude + deltaLong;

	const northWestCoords = [topLat, leftLng];
	const northEastCoords = [topLat, rightLng];
	const southWestCoords = [bottomLat, leftLng];
	const southEastCoords = [bottomLat, rightLng];
	return [northWestCoords, northEastCoords, southEastCoords, southWestCoords];
}

// eslint-disable-next-line
Number.prototype.toRad = function() {
	return this * Math.PI / 180;
};