import { APP, WS_DATA } from '../../redux/constants/app.constants';
import { ACCOUNT_PAGE } from '../AccountPage/constants';

export const ADMIN_PAGE = `${APP}/adminPage`;

export const GET_ADMIN_DATA = `${ADMIN_PAGE}/GET_ALL`;

export const GET_ACCOUNT_MANAGERS = `${ADMIN_PAGE}/ACCOUNT_MANAGERS/GET`;
export const ACCOUNT_MANAGERS_CHANGED = `${ADMIN_PAGE}/ACCOUNT_MANAGERS/CHANGED`;

export const GET_ACCOUNTS = `${ADMIN_PAGE}/ACCOUNTS/GET`;
export const ACCOUNTS_CHANGED = `${ADMIN_PAGE}/ACCOUNTS/CHANGED`;

export const GET_DEVICES = `${ADMIN_PAGE}/DEVICES/GET`;
export const DEVICE_CHANGED = `${ADMIN_PAGE}/DEVICE/CHANGED`;
export const DEVICES_CHANGED = `${ADMIN_PAGE}/DEVICES/CHANGED`;

export const GET_BEACONS = `${ADMIN_PAGE}/BEACONS/GET`;
export const BEACONS_CHANGED = `${ADMIN_PAGE}/BEACONS/CHANGED`;
export const GEOFENCES_CHANGED = `${ADMIN_PAGE}/GEOFENCES/CHANGED`;
export const TEAMS_CHANGED = `${ADMIN_PAGE}/TEAMS/CHANGED`;

export const GET_SITES = `${ADMIN_PAGE}/SITES/GET`;
export const SITES_CHANGED = `${ADMIN_PAGE}/SITES/CHANGED`;

export const GET_PROMS = `${ADMIN_PAGE}/PROMS/GET`;
export const PROMS_CHANGED = `${ADMIN_PAGE}/PROMS/CHANGED`;
export const SET_PROM = `${ADMIN_PAGE}/PROMS/SET`;

export const GET_ARCS = `${ADMIN_PAGE}/ARCS/GET`;
export const ARCS_CHANGED = `${ADMIN_PAGE}/ARCS/CHANGED`;

export const SET_DEVICE_FILTER = `${ADMIN_PAGE}/SET_DEVICE_FILTER`;

export const SET_SELECTED_ACCOUNT = `${ADMIN_PAGE}/SET_SELECTED_ACCOUNT`;
export const BILLING_CYCLE_CHANGED = `${ACCOUNT_PAGE}/BILLING_CYCLE_CHANGED`;
export const DOWNLOAD_ACCOUNT_MANAGER_REPORT = `${ACCOUNT_PAGE}/DOWNLOAD_ACCOUNT_MANAGER_REPORT`;

export const WS_DATA_DEVICE_COUNTS = `${WS_DATA}/deviceCounts`;

export const WS_DATA_SIM_CARDS = `${WS_DATA}/simCards`;
export const SIM_CARDS_CHANGED = `${ADMIN_PAGE}/SIM_CARDS/CHANGED`;
export const SET_SHOW_WIKI = `${ADMIN_PAGE}/SET_SHOW_WIKI`;

export const CHANGE_LAYOUT = `${ADMIN_PAGE}/CHANGE_LAYOUT`;

export const LAYOUT_NO_CONSOLE = 1;
// export const LAYOUT_CONSOLE_SECONDARY = 2;
// export const LAYOUT_CONSOLE_PRIMARY = 3;
// export const LAYOUT_ONLY_CONSOLE = 4;