import { put, select, takeLatest } from 'redux-saga/effects';
import { ScenarioAPI } from '../../utils/API/ScenarioAPI';
import {
  DELETE_SCENARIO,
  REFRESH_SCENARIO,
  SAVE_SCENARIO,
  START_SCENARIO,
  WS_COMPATIBILITY,
} from '../constants/scenario.constants';
import Scenario from '../../utils/models/Scenario';
import { selectOptions } from '../../containers/PresentationPage/selectors';
import { selectScenarios } from '../selectors/scenario.selector';
import { scenariosChanged } from '../actions/scenario.actions';
import * as Sentry from '@sentry/browser';
import {setOnMenuClicked} from "../actions/app.actions";
import {getScenarioTemplates, setIsLoading} from "../../containers/ScenariosPage/actions";

const api = new ScenarioAPI();

function* saveScenario({scenario}) {
	yield put(setIsLoading(true));
	if(scenario.id) {
		yield api.updateScenario(scenario);
	} else {
		yield api.newScenario(scenario);
	}
	yield put(setIsLoading(false));
}
function* deleteScenario({scenario}) {
	if(scenario == null) {
		Sentry.captureMessage("deleteScenario: 'scenario is null'");
		return;
	}
	yield put(setOnMenuClicked(null));
	const scenarios = yield select(selectScenarios());
	yield put(scenariosChanged(scenarios.filter(s => !!s && s.id !== scenario.id)));
	yield api.deleteScenario(scenario);
	yield put(getScenarioTemplates());
}
function* refreshScenario({scenario}) {
	yield api.refreshScenario(scenario);
}

function* saveCompatibility({data}) {
	Scenario.compatibility = data;
	yield Promise.resolve();
}
function* startScenario({scenarioId, payload}) {
	let page = yield select(selectOptions());
	yield api.startScenario(scenarioId, payload, page ? page.id : null);
}

export const scenarioSagas = [
	takeLatest(WS_COMPATIBILITY, saveCompatibility),
	takeLatest(SAVE_SCENARIO, saveScenario),
	takeLatest(DELETE_SCENARIO, deleteScenario),
	takeLatest(REFRESH_SCENARIO, refreshScenario),
	takeLatest(START_SCENARIO, startScenario),

];