import BaseAPI from './BaseAPI';

export class DashboardAPI extends BaseAPI {

	async create(name) {
		return this.request(`/sec/dashboard`, 'POST', {name});
	}
	async update(dashboard) {
		return this.request(`/sec/dashboard`, 'PUT', {dashboard: dashboard.toJS()});
	}
	async delete(id) {
		return this.request(`/sec/dashboard`, 'DELETE', {id});
	}

	async getPresentationPages() {
		return this.request(`/sec/presentation`, 'GET');
	}
	async savePresentationPage(page) {
		return this.request(`/sec/presentation`, 'POST', page);
	}
	async deletePresentationPage(page) {
		return this.request(`/sec/presentation`, 'DELETE', page);
	}
	
	async getDashboards(dashboardIds) {
		return this.request(`/sec/dashboard/${JSON.stringify(dashboardIds)}`, 'GET');
	}


}
