import {createGlobalStyle} from 'styled-components';
import {Colors} from './utils/style/colors';
import {getWhitelabel} from './env';

/* eslint no-unused-expressions: 0 */
export const GlobalStyle = createGlobalStyle`
    html,
    body {
        height: 100%;
        width: 100%;
        overflow: hidden;
        --primary-color: grey;
        margin: 0;
    }

*:not(svg,i) {
    font-size: 10pt;
}
ul :not(svg), label {
    font-size: 10pt !important;  
}

#app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


code {
    background: #fbe5e1;
    color: #c0341d;
    font-size: 90%;
    border-radius: 4px;
    padding: 2px 4px;
}

body, body * {
    font-family: ${getWhitelabel()?.style?.fontFamily || "'Roboto', sans-serif"} !important;
}

hr:not(.MuiDivider-root) { margin: 20px 0 !important; }
.MuiList-root hr:not(.MuiDivider-root) { margin: 0 !important; }

h1,h2,h3,h4,h5,h6 {
    font-weight: bold;
}

fieldset {
    border-radius: 0 !important;
}

#app {
    min-height: 100%;
    min-width: 100%;
}

button:not(.fab) {
    border-radius: 0 !important;
    font-weight: 600 !important;
}

p,
label {
    font-family: ${getWhitelabel()?.style?.fontFamily || "'Roboto', sans-serif"};
    line-height: 1.5em;
}

hidden {
    display: none;
}

@keyFrames pageFlyIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyFrames fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyFrames fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}
@keyFrames requestFocus {
    0 { opacity: 1; }
    50% { opacity: .5; }
    100% { opacity: 1; }
}
@keyFrames rotate {
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
}

*::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar {
    width: 6px;
    background-color: ${Colors.gray10};
}

*::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray50};
}

.no-borders-deep,
.no-borders,
.no-borders-deep *
{ border-radius: 0 !important; }

svg .svgFadeText {
    animation: 2s requestFocus infinite;
}

input[type="tel"] {
    cursor: pointer;
}

.size-9-deep, .size-9-deep *, .size-9 { font-size: 9pt !important; }

.loadingAnimated {
    animation: 2s requestFocus infinite;
}

.ui-switch-fix > span:first-child {
    padding: 9px !important;
}
.ui-switch-fix.small > span:first-child {
    padding: 4px !important;
}

audio:focus {
    outline: none;
}
.tooltip-content, .tooltip-content * {
    font-size: 7pt;
}

.MuiInputAdornment-root button {
    padding: 4px;
}
.MuiDateTextField {
    .MuiInputAdornment-root button {
        margin-right: 0;
    }
}
.leaflet-control-attribution {
    opacity: 0;
    display: none;
}
.MuiMenuItem-root.smallSecondary .MuiListItemText-secondary {
    font-size: 7pt !important;
    color: #999999;
}
.MuiDialogContent-root {
    padding-top: 6px !important;
}
.MuiFormHelperText-root {
    margin-left: 12px;
}
`;
