import { createSelector } from 'reselect';
import { APP } from '../constants/app.constants';
import { initialState } from '../reducers/app.reducer';
import { fromJS } from 'immutable';

export const selectState = state => fromJS(state[APP]) || initialState;

export const selectIsAppEnabled = () => createSelector(selectState, state => state.get('isEnabled'));
export const selectCurrentUser = () => createSelector(selectState, state => state.get('currentUser'));
export const selectPresentationMode = () => createSelector(selectState, state => state.get('presentationMode'));
export const selectDisconnectedSoundEnabled = () => createSelector(selectState, state => state.get('disconnectedSoundEnabled'));
export const selectTheme = () => createSelector(selectState, state => state.get('theme'));
export const selectIsConnected = () => createSelector(selectState, state => state.get('isConnected'));
export const selectIsOnline = () => createSelector(selectState, state => state.get('isOnline'));
export const selectChangeLog = () => createSelector(selectState, state => state.get('changeLog'));
export const selectModules = () => createSelector(selectState, state => state.get('modules'));
export const selectActivateModule = () => createSelector(selectState, state => state.get('activateModule'));
export const selectMapProvider = () => createSelector(selectState, state => state.get('mapProvider'));
export const selectCredits = () => createSelector(selectState, state => state.get('credits').toJS());
export const selectCreditsFetched = () => createSelector(selectState, state => state.get('creditsFetched'));
export const selectIsExpired = () => createSelector(selectState, state => !!state.get('isExpired'));
export const selectIsAnonymous = () => createSelector(selectState, state => !!state.get('isAnonymous'));
export const selectWelcomeDialog = () => createSelector(selectState, state => !!state.get('showNewsletterOptIn'));
export const selectEnqueueSnackbar = () => createSelector(selectState, state => state.get('enqueueSnackbar'));
export const selectDismissSnackbar = () => createSelector(selectState, state => state.get('dismissSnackbar'));
export const selectOnMenuClicked = () => createSelector(selectState, state => state.get('onMenuClicked'));
export const selectSoundSettingsOpen = () => createSelector(selectState, state => state.get('soundSettingsOpen'));
export const selectPalette = () => createSelector(selectState, state => state.get('palette'));
export const selectAdminMode = () => createSelector(selectState, state => state.get('adminMode'));
export const selectWsInit = () => createSelector(selectState, state => state.get('wsInit'));
