import React from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import {fromJS} from "immutable";
import TextField from "@mui/material/TextField";
import {Colors} from "../../utils/style/colors";
import {useTranslation} from "react-i18next";


const Autocomplete = props => {
    const [t] = useTranslation();
    const {inputProps, helperText, ...other} = props;
    
    const options = fromJS(props.items || []).toJS().map(i => ({
        label: i.label,
        id: i.value,
        object: i.object,
        group: i.group
    }));
    
    return (
        <MuiAutocomplete
            clearText={t('clear')}
            renderInput={(params) => (
                <TextField
                    label={props.label}
                    {...inputProps}
                    {...params}
                    helperText={helperText}
                />
            )}
            options={options}
            groupBy={o => o.group}
            isOptionEqualToValue={(o,v) => {
                if(v === null) return false;
                if(v?.id) return o.id === v.id;
                return o.id === v;
            }}
            {...other}
            value={props.value === undefined ? null : props.value}
            style={{
                marginBottom: 10,
                minWidth: 150,
                color: Colors.gray60,
                ...props.style
            }}
            editable={props.editable ? 'true':undefined}
            getOptionLabel={props.freeSolo ? undefined : option => option?.label || options.find(o => o.id === option)?.label || ''}
            onChange={(e,v, reason) => {
                if(reason === 'clear' && props.onClear) props.onClear();
                if(props.multiple) {
                    props.onChange(v?.map(i => i?.id || i || null));
                } else if(props.freeSolo) {
                    //ILB - use onInputChange
                } else {
                    props.onChange(v?.id || null);
                }
            }}
        />
    );
};

export default Autocomplete;