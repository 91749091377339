import styled from 'styled-components';
import { Colors } from './colors';

const sizes = {
	xxs: 6,
	xs: 7,
	s : 8,
	m : 10,
	l : 14,
	xl: 20,
	xxl: 24
};
export const fontSize = sizes;

export const Text = styled.span`
	font-size: ${props => sizes[props.size] || props.size || sizes['m']}pt;
	${props => props.preFormat && `
		white-space: pre-wrap;
	`};
	${props => props.align && `
		text-align: ${props.align};
	`};
	${props => props.italic && `
		font-style: italic;
	`};
	${props => props.bold && `
		font-weight: bold;
	`};
	${props => props.color && `
		color: ${props.color};
	`};
`;

export const Bold = styled(Text)`
	font-weight: bold;
`;
export const Danger = styled(Bold)`
	color: ${Colors.danger};
`;
// export const Warning = styled(Bold)`
// 	color: ${Colors.warning};
// `;
export const DangerSecondary = styled(Text)`
	color: ${Colors.danger};
`;
export const Secondary = styled(Text)`
	color: ${Colors.gray40};
`;
export const Comment = styled(Text)`
	color: ${Colors.gray40};
	font-size: ${props => sizes[props.size || 's']}pt;
	${props => (props.warning || props.type === 'warning') && `
		color: ${Colors.orange};
	`};
	${props => (props.danger || props.type === 'danger') && `
		color: ${Colors.danger};
	`};
`;

export const Head1 = styled(Text)`
	font-weight: 600;
	font-size: ${sizes.xl}pt;
	display: block;
`;

// export const Head2 = styled(Text)`
// 	font-weight: 600;
// 	font-size: ${sizes.xl}pt;
// 	display: block;
// `;

export const Head3 = styled(Text)`
	font-weight: 600;
	font-size: ${sizes.l}pt;
	display: block;
`;

export const Code = styled.code`
	font-size: ${props => sizes[props.size] || props.size || sizes['m']}pt;
	${props => props.onClick && `
		cursor: pointer;
	`};
	${props => props.color && `
		color: ${props.color};
	`};
	${props => props.bg && `
		background-color: ${props.bg};
	`};
`;

export const EllipsisStart = props => <Text {...props} style={{
	direction: 'rtl', textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden'
}} />;