import { APP, WS_DATA } from './app.constants';

export const SCENARIOS = `${APP}_scenarios`;
export const SELECT_SCENARIO = `${SCENARIOS}/SELECT_SCENARIO`;
export const SAVE_SCENARIO = `${SCENARIOS}/SAVE_SCENARIO`;
export const DELETE_SCENARIO = `${SCENARIOS}/DELETE_SCENARIO`;
export const REFRESH_SCENARIO = `${SCENARIOS}/REFRESH_SCENARIO`;
export const START_SCENARIO = `${SCENARIOS}/START_SCENARIO`;
export const SCENARIOS_CHANGED = `${SCENARIOS}/SCENARIOS_CHANGED`;
export const GET_SCENARIOS = `${SCENARIOS}/GET_SCENARIOS`;

export const WS_DATA_SCENARIO = `${WS_DATA}/scenario`;
export const WS_DATA_SCENARIOS = `${WS_DATA}/scenarios`;
export const WS_COMPATIBILITY = `${WS_DATA}/compatibility`;