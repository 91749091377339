import { fromJS } from 'immutable';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';
import {
  APP_LOGOUT,
  CONNECTION_CHANGED,
  NEW_TOKEN,
  REQUEST_ACTIVATE_MODULE,
  SET_ADMIN_MODE,
  SET_ANONYMOUS_MODE, SET_APP_ENABLED, SET_DISMISS_SNACKBAR,
  SET_ENQUEUE_SNACKBAR, SET_IS_ONLINE,
  SET_MAP_PROVIDER,
  SET_ON_MENU_CLICKED,
  SET_PALETTE,
  SET_SHOW_NEWSLETTER_OPT_IN,
  SET_SOUND_SETTINGS_OPEN,
  SET_THEME, TOGGLE_DISCONNECTED_SOUND, TOGGLE_PRESENTATION_MODE,
  USER_CHANGED,
  WS_DATA_CHANGELOG,
  WS_DATA_CREDITS,
  WS_DATA_INIT,
  WS_DATA_MODULES,
} from '../constants/app.constants';
import User from '../../utils/models/User';
import jwtDecode from 'jwt-decode';
import Module from '../../utils/models/Module';

import { MapProviders } from '../../utils/map';
import { isTokenValidLonger } from '../../utils/functions';
import { Colors } from '../../utils/style/colors';
import { isDevelopment } from '../../env';
import {consoleDebug} from "../../utils/log";

let userJson = false;
try {
  userJson = JSON.parse(localStorage.getItem('user'));
  const jwt = jwtDecode(userJson.token);
  if (jwt.exp < (Date.now() / 1000) && !localStorage.getItem('rt')) {
    userJson = false;
  }
} catch (e) {
  userJson = false;
}

export const initialState = fromJS({
  loading: false,
  isEnabled: true, 
  currentUser: userJson ? new User(userJson) : false,
  activePage: 'home',
  isConnected: true,
  isOnline: true,
  changeLog: {},
  modules: {},
  activateModule: null,
  credits: {},
  creditsFetched: false,
  mapProvider: MapProviders.normal,
  isAnonymous: true,
  showNewsletterOptIn: false,
  agreedToTermsOfUse: true,
  enqueueSnackbar: () => {},
  dismissSnackbar: () => {},
  onMenuClicked: null,
  soundSettingsOpen: false,
  disconnectedSoundEnabled: !!localStorage.getItem('disconnectedSoundEnabled'),
  palette: Colors.palette,
  presentationMode: true,
  adminMode: isDevelopment(),
  wsInit: false
});

function appReducer(state: Map = initialState, action) {
  if(action && !action.password) {
    consoleDebug('DISPATCH', action);
  } else {
    consoleDebug('DISPATCH [SCRATCHED]');
  }
  switch (action.type) {
    case SET_APP_ENABLED:
      return state.set('isEnabled', action.isEnabled);
    case USER_CHANGED:
    case AUTH_SUCCESS:
      if(typeof action.user !== 'object') action.user = !!action.user;
      return state.set('currentUser', action.user);
    case SET_ANONYMOUS_MODE:
      return state.set('isAnonymous', action.isAnonymous);
    case APP_LOGOUT:
      return state.set('currentUser', false);
    case SET_SHOW_NEWSLETTER_OPT_IN:
      return state.set('showNewsletterOptIn', action.show);
    case NEW_TOKEN:
      if (!state.get('currentUser')) return state;
      if(!isTokenValidLonger(action.token)) return state;
      const user = state.get('currentUser');
      return state.set('currentUser', user.set('token', action.token));
    case CONNECTION_CHANGED:
      return state.set('isConnected', action.isConnected);
    case WS_DATA_CHANGELOG:
      return state.set('changeLog', fromJS(action.data));
    case WS_DATA_MODULES:
      return state.set('modules', fromJS(action.data.map(m => new Module(m))));
    case REQUEST_ACTIVATE_MODULE:
      return state.set('activateModule', action.modId);
    case SET_THEME:
      return state.set('theme', fromJS(action.theme));
    case WS_DATA_CREDITS:
      return state.set('credits', fromJS(action.data)).set('creditsFetched', true);
    case SET_MAP_PROVIDER:
      return state.set('mapProvider', action.provider);
    case SET_ENQUEUE_SNACKBAR:
      return state.set('enqueueSnackbar', action.enqueueSnackbar);
    case SET_DISMISS_SNACKBAR:
      return state.set('dismissSnackbar', action.dismissSnackbar);
    case SET_ON_MENU_CLICKED:
      return state.set('onMenuClicked', action.onClick);
    case SET_SOUND_SETTINGS_OPEN:
      return state.set('soundSettingsOpen', action.isOpen);
    case SET_PALETTE:
      return state.set('palette', action.palette);
    case TOGGLE_PRESENTATION_MODE:
      return state.set('presentationMode', !state.get('presentationMode'));
    case SET_ADMIN_MODE:
      return state.set('adminMode', action.isEnabled);
    case WS_DATA_INIT:
      return state.set('wsInit', true);
    case SET_IS_ONLINE:
      return state.set('isOnline', action.isOnline);
    case TOGGLE_DISCONNECTED_SOUND:
      if(action.isEnabled === undefined) action.isEnabled = !state.get('disconnectedSoundEnabled'); 
      if(action.isEnabled) {
        localStorage.setItem('disconnectedSoundEnabled', true); 
      } else {
        localStorage.removeItem('disconnectedSoundEnabled');
      }
      return state.set('disconnectedSoundEnabled', !!action.isEnabled);
    default: return state;
  }
}

export default appReducer;
