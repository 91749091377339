import UIAvatar from '@mui/material/Avatar';

import React from 'react';
import * as styles from './style';
import Tooltip from '@mui/material/Tooltip';


export class Avatar extends React.PureComponent {

	render() {
		const {color, border, small, ...other} = this.props;
		return (
			<styles.Wrapper
				className="avatar"
				small={small}
				border={border}
				color={color}>
				<Tooltip title={this.props.tooltip || ''}>
					<UIAvatar {...other} />
				</Tooltip>
			</styles.Wrapper>
		)
	}

}