import styled from 'styled-components';

export const Wrapper = styled.div`
	display: inline-block;
	position: relative;
	svg {
		width: .8em;
    	height: .8em;
	}
	${props => props.isDisabled && `
		* {
			pointer-events: auto !important;
	  }
	`}
`;

export const ProcessIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .7;
  div, svg {
    width: 40px !important;
    height: 40px !important;
  }
`;