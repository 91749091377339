import { fromJS, Map } from 'immutable';
import Geofence from '../../utils/models/Geofence';
import {
  SAVE_GEOFENCE,
  SET_DETAILED_GEOFENCE,
  WS_DATA_GEOFENCE,
  WS_DATA_GEOFENCE_PRESENCE,
  WS_DATA_GEOFENCES,
} from '../constants/geofence.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';
import Presence from '../../utils/models/Presence';

export const initialState = fromJS({
	data: {},
	presence: {},
	detailedGeofence: null,
	loading: false,
	fetched: false
});

export default function GeofenceReducer(state: Map = initialState, action) {
	let geofences = state.get('data');
	switch (action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_GEOFENCE_PRESENCE:
			return state.set('presence', new Presence(action.data));
		case WS_DATA_GEOFENCE:
			return state.setIn(['data', action.data.id], new Geofence(action.data));
		case WS_DATA_GEOFENCES:
			geofences = Map(action.data.map(d => new Geofence(d)).map(d => [d.id, d]));
			return state.set('data', geofences).set('fetched', true);
		case SET_DETAILED_GEOFENCE:
			return state.set('detailedGeofence', action.geofence).set('loading', false);
		case SAVE_GEOFENCE:
			return state.set('loading', true);
		default:
			return state;
	}
}