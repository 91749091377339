import { fromJS, Map } from 'immutable';
import Protocol from '../../utils/models/Protocol';
import { WS_DATA_PROTOCOL, WS_DATA_PROTOCOLS } from '../constants/protocol.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	fetched: false,
	data: {},
});

function reducer(state: Map = initialState, action) {
	let protocols = state.get('data');
	switch(action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_PROTOCOL:
			return state.setIn(['data', action.data.id], new Protocol(action.data));
		case WS_DATA_PROTOCOLS:
			protocols = Map(action.data.map(q => new Protocol(q)).map(q => [q.id, q]));
			return state
				.set('data', protocols)
				.set('fetched', true);
		default:
			return state;
	}
}

export default reducer;
