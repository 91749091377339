import { APP, WS_DATA } from './app.constants';

export const USERS = `${APP}_users`;
export const WS_DATA_USERS = `${WS_DATA}/users`;

export const UPDATE_USER = `${USERS}/UPDATE_USER`;
export const CONNECT_APP = `${USERS}/CONNECT_APP`;
export const APP_SEATS_CHANGED = `${USERS}/APP_SEATS_CHANGED`;
export const GET_APP_SEATS = `${USERS}/GET_APP_SEATS`;
export const SEND_APP_WELCOME_MAIL = `${USERS}/SEND_APP_WELCOME_MAIL`;
export const SEND_WELCOME_MAIL = `${USERS}/SEND_WELCOME_MAIL`;
export const GET_USERS = `${USERS}/GET_USERS`;
export const USERS_CHANGED = `${USERS}/USERS_CHANGED`;
export const SET_ERROR = `${USERS}/SET_ERROR`;
export const SET_LOADING = `${USERS}/SET_LOADING`;