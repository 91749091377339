import React from 'react';
import { Comment, Secondary } from '../../utils/style/texts';
import * as styles from './style';
import dayjs from 'dayjs';
import { Flex } from '../Container/Flex';
import Chip from '../Chip';
import { Colors } from '../../utils/style/colors';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

const initState = {
  toState: null,
  fromDay: null,
  fromSlot: null,
  hoveringDay: null,
  hoveringSlot: null,
};

class WeekAvailability extends React.PureComponent {

  state = initState;

  flipBulk() {
    if(!this.props.onChange) return;
    const { toState } = this.state;
    const { availability } = this.props;
    const newAvailability = availability.map((dayData, day) => {
      return dayData.map((isAvailable, slot) => {
        if (this.isChanging(day, slot))
          return toState;
        return availability[day][slot];
      });
    });
    this.setState(initState);
    this.props.onChange(newAvailability);
  }

  isChanging(day, slot) {
    const { toState, hoveringDay, startDay, hoveringSlot, startSlot } = this.state;
    if (toState == null) return false;
    let isChangingDay = hoveringDay <= startDay && day >= hoveringDay && day <= startDay;
    if (!isChangingDay) {
      isChangingDay = hoveringDay >= startDay && day <= hoveringDay && day >= startDay;
    }
    let isChangingSlot = hoveringSlot <= startSlot && slot >= hoveringSlot && slot <= startSlot;
    if (!isChangingSlot) {
      isChangingSlot = hoveringSlot >= startSlot && slot <= hoveringSlot && slot >= startSlot;
    }
    return isChangingDay && isChangingSlot;
  }

  render() {
    const { availability, showCount, t, withPresets, withTimezone, disabled } = this.props;
    const data = availability;
    const onChange = disabled ? () => {} : this.props.onChange;
    return (
      <Flex col>
        <styles.AvailabilityTable editable={!disabled && !!onChange}>
          <thead>
          <tr>
            <th/>
            {Array.from(Array(24)).map((a, i) => (
              <th colSpan={2} key={i}><Secondary>{`${i}`.padStart(2, '0')}</Secondary></th>
            ))}
          </tr>
          </thead>
          <tbody>
          {data.map((dayData, day) => (
            <tr key={day}>
              <th><Secondary>{dayjs().day(day + 1).format('dd')}</Secondary></th>
              {dayData.map((available, slot) => {
                let isChanging = this.isChanging(day, slot);
                return (
                  <styles.AvailabilityCell
                    key={`${day}-${slot}`}
                    day={day}
                    slot={slot}
                    disabled={disabled}
                    isChanging={isChanging}
                    onMouseDown={() => {
                      if(!onChange) return;
                      this.setState({
                        startDay: day,
                        startSlot: slot,
                        hoveringDay: day,
                        hoveringSlot: slot,
                        toState: !available,
                      });
                    }}
                    onMouseOver={e => {
                      if(!onChange) return;
                      this.setState({ hoveringDay: day, hoveringSlot: slot });
                      if (this.state.toState !== null && !(e.buttons === 1 || e.buttons === 3)) {
                        this.flipBulk(day, slot);
                      }
                    }}
                    onMouseUp={() => this.flipBulk(day, slot)}
                    available={available}
                  >
                    {showCount === true ? available : ''}
                  </styles.AvailabilityCell>
                );
              })}
            </tr>
          ))}
          </tbody>
        </styles.AvailabilityTable>
        <Flex align="center" fullWidth>
          {withPresets && (
            <Flex align="center">
              <Secondary>{t('presets')}</Secondary>
              <Chip disabled={disabled} small label={t('empty')}
                    color={JSON.stringify(data) === JSON.stringify(presets.never) ? Colors.secondary() : undefined}
                    onClick={() => onChange(presets.never)}/>
              <Chip disabled={disabled} small label={t('alwaysAvailable')}
                    color={JSON.stringify(data) === JSON.stringify(presets.always) ? Colors.secondary() : undefined}
                    onClick={() => onChange(presets.always)}/>
              <Chip disabled={disabled} small label={t('officeHours')}
                    color={JSON.stringify(data) === JSON.stringify(presets.officeHours) ? Colors.secondary() : undefined}
                    onClick={() => onChange(presets.officeHours)}/>
              <Chip disabled={disabled} small label={t('weekend')}
                    color={JSON.stringify(data) === JSON.stringify(presets.weekend) ? Colors.secondary() : undefined}
                    onClick={() => onChange(presets.weekend)}/>
              <Chip disabled={disabled} small label={t('onWeekDays')}
                    color={JSON.stringify(data) === JSON.stringify(presets.week) ? Colors.secondary() : undefined}
                    onClick={() => onChange(presets.week)}/>
            </Flex>
          )}
          {withTimezone && (
            <Flex direction="column" style={{flex: 1, textAlign:'right', paddingRight: 20}}>
              <Comment>{t('basedOnTimezone')}: {withTimezone}</Comment>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }
}

export default compose(
  withTranslation()
)(WeekAvailability);

export const presets = {
	never: Array.from(Array(7)).map(() => {
		return Array.from(Array(48)).map(() => false);
	}),
	always: Array.from(Array(7)).map(() => {
		return Array.from(Array(48)).map(() => true);
	}),
	officeHours: Array.from(Array(7)).map((x, day) => {
		return Array.from(Array(48)).map((y, slot) => day < 5 && slot >= 18 && slot < 34);
	}),
	week: Array.from(Array(7)).map((x, day) => {
		// noinspection JSUnusedLocalSymbols
    return Array.from(Array(48)).map((y, slot) => day < 5);
	}),
	weekend: Array.from(Array(7)).map((x, day) => {
    // noinspection JSUnusedLocalSymbols
		return Array.from(Array(48)).map((y, slot) => day > 4);
	}),
};