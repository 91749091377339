import Immutable from 'immutable';
import {getTiles} from "../../containers/DashboardPage/tiles";
import _flatten from 'lodash/flatten';
import _uniq from 'lodash/uniq';

const tiles = getTiles(() => {});

const DashboardModel = Immutable.Record({
  id: 0,
  name: '',
  tiles: [],
  data: {},
  locked: false
});

export default class Dashboard extends DashboardModel {
  id: number;
  name: string;
  tiles: Object[];
  data: Object;
  locked: boolean;
  
  get wsTopics() {
    return _uniq(_flatten(Object.values(this.data)
        .map(d => tiles.find(t => t.type === d.type))
        .map(t => t.wsTopics)));
  }

  uuid() {
    return Date.now().toString();
  }

  clone() {
    return this.set('id', this.get('id'));
  }

}
