import Immutable from 'immutable';
import { UserTypes } from './UserType';
import { accountManagerModules, moduleIds } from './Module';
import dayjs from 'dayjs';
import {getCentroid} from "../map";
import timezoneBoxes from "../../assets/timezoneBoxes.json";

const UserModel = Immutable.Record({
	id: 0,
	language: 'nl-NL',
	arcKey: null,
	languages: [],
	token: null,
	email: null,
	lastLogin: null,
	groupId: 4,
	accountName: '',
	accountType: 0,
	name: '',
	alertExpiration: 'never',
	userType: UserTypes.NORMAL,
	modules: [],
	version: '',
	notifyEmail: false,
	showNewVersion: false,
	termsOfUseVersion: null,
	privacyVersion: null,
	showNewsletterOptIn: false,
	limitDevices: null,
	limitAccounts: null,
	accountId: 0,
	mustUseScript: false,
	trialEnd: null,
	appId: null,
	timezone: '',
	twoFA: false,
	incidentManagementUrl: null,
	arcs: [],
	scheduledReportLimit: 3
});

export default class User extends UserModel {
	id: number;
	language: string;
	languages: string[];
	arcKey: string;
	token: string;
	email: string;
	lastLogin: string;
	groupId: number;
	accountName: string;
	accountType: number;
	alertExpiration: string;
	name: string;
	userType: string;
	version: string;
	modules: number[];
	notifyEmail: boolean;
	showNewVersion: boolean;
	termsOfUseVersion: string;
	privacyVersion: string;
	showNewsletterOptIn: boolean;
	incidentManagementUrl: string;
	limitDevices: number;
	limitAccounts: number;
	accountId: number;
	mustUseScript: boolean;
	trialEnd: number;
	appId: number;
	timezone: string;
	twoFA: boolean;
	arcs: string[];
	scheduledReportLimit: number;
	get countryCode() {
		return this.language.split('-')[1].lc() || 'en';
	}
	get isSuperAdmin() : boolean {
		return [UserTypes.VEVIGO].indexOf(this.get('userType')) >= 0;
	}
	get isAdmin() : boolean {
		if(this.isShadowAdmin) return true;
		return [UserTypes.ADMIN, UserTypes.VEVIGO].indexOf(this.get('userType')) >= 0 || this.get('groupId') === 3;
	}
	get isAccountManager() : boolean {
		if(this.isShadowAccountManager) return true;
		return [UserTypes.ACCOUNT_MANAGER].indexOf(this.get('userType')) >= 0 || this.get('groupId') === 6;
	}
	get isUser() : boolean {
		if(this.isShadowAdmin) return true;
		return [UserTypes.NORMAL, UserTypes.ADMIN].indexOf(this.get('userType')) >= 0;
	}
	get isPortalUser() : boolean {
		return [UserTypes.NORMAL].indexOf(this.get('userType')) >= 0 || this.get('groupId') === 4;
	}
	get isAppOnly() : boolean {
		return [UserTypes.APP_ONLY].indexOf(this.get('userType')) >= 0 || this.get('groupId') === 11;
	}
	get isAlarmCenter() : boolean {
		return this.isARC || this.isMonitorUser;
	}
	get isARC() : boolean {
		return this.get('userType') === UserTypes.ARC;
	}
	get isPresentation() : boolean {
		return this.get('groupId') === 10;
	}
	get isHidden() : boolean {
		return this.isShadow || this.isPresentation;
	}
	get isShadow() : boolean {
		return this.isShadowAdmin || this.isShadowAccountManager;
	}
	get isShadowAdmin() : boolean {
		return this.get('groupId') === 13;
	}
	get isShadowAccountManager() : boolean {
		return this.get('groupId') === 14;
	}
	get isMonitorUser() : boolean {
		return this.get('groupId') === 8;
	}
	get viaAdmin() : boolean {
		return !this.isSuperAdmin && (!!localStorage.getItem('adminUser') || !!localStorage.getItem('accountManagerUser'));
	}
	get showCredits() : boolean {
		return this.hasModule(moduleIds.Credits);
	}
	get trialEnded() : boolean {
		return this.trialEnd && (this.trialEnd * 1000) < Date.now();
	}
	get hasAlarmCenter(): boolean {
		return !!this.arcKey;
	}
	get mapCenter() {
		return getCentroid(timezoneBoxes[this.timezone] || [[0, 0], [0, 0]]);
	}
	filterString(t) {
		return [
			this.email,
			t(`userGroup.${this.groupId}`)
		].join('').lc();
	}

	canManageModule(moduleId) {
		if(this.isSuperAdmin) return true;
		if(this.isAccountManager) {
			if(moduleId === moduleIds.EchoService) return this.hasModule(moduleIds.EchoService_AM)
			return accountManagerModules.indexOf(moduleId) >= 0;
		}
		return false;
	}

	hasModule(modId) : boolean {
		if(this.isAccountManager) {
			if (modId === moduleIds.Beacons) return true;
		}
		return this.get('modules').indexOf(+modId) >= 0;
	}

	get trialEndDate() {
		if(this.get('trialEnd'))
			return dayjs.unix(this.get('trialEnd'));
		return null;
	}
	
	clone() {
		return this.set('name', this.name);
	}

	constructor(props) {
		if(!props.userType) {
			props.userType = UserTypes.fromUser(props);
		}
		super(props);
	}

	static create(language = 'en-GB') {
		return new User({
			language,
			groupId: 4,
			email: '',
			userType: UserTypes.NORMAL
		});
	}
}
