import { fromJS, Map } from 'immutable';
import Alarm from '../../utils/models/Alarm';
import {
	CLOSE_ALARMS,
	OPEN_ALARMS,
	SAFETY_REGIONS_CHANGED,
	SET_ALARM_SOUND_ENABLED,
	SET_ALERT_SOUND_ENABLED,
	SET_ASS_REQ_SOUND_ENABLED, SET_DETAILED_ALARM, SET_RECORDING, TOGGLE_ARC_ALARMS,
	WS_DATA_ALARM,
	WS_DATA_ALARMS,
	WS_REQUEST_RELOAD_DATA
} from '../constants/alarm.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	fetched: false,
	data: {},
	safetyRegions: null,
	alarmSoundEnabled: localStorage.getItem('playAlarmSound') || false,
	alertSoundEnabled: localStorage.getItem('playAlertSound') || false,
	assReqSoundEnabled: localStorage.getItem('playAssReqSound') || false,
	selectedAlarms: [],
	detailedAlarm: null,
	loadingDetailedAlarm: false,
	recording: null,
	showARCAlarms: (localStorage.getItem('showARCAlarms') || 'false') === 'true'
});

function reducer(state: Map = initialState, action) {
	let alarms = state.get('data');
	switch(action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_REQUEST_RELOAD_DATA:
			return state.set('data', fromJS({}));
		case WS_DATA_ALARM:
			return state
				.set('data', new Map({
					...alarms.toObject(),
					[+action.data.id]: new Alarm(action.data)
				}));
		case WS_DATA_ALARMS:
			const newAlarms = Map(action.data.map(d => new Alarm(d)).map(d => [d.id, d]));
			return state
				.set('data', new Map({
					...alarms.toObject(),
					...newAlarms.toObject()
				}))
				.set('fetched', true);
		case SAFETY_REGIONS_CHANGED:
			return state.set('safetyRegions', action.data);
		case SET_ALARM_SOUND_ENABLED:
			if(action.isEnabled) localStorage.setItem("playAlarmSound", true);
			else localStorage.removeItem("playAlarmSound");
			return state.set('alarmSoundEnabled', !!action.isEnabled);
		case SET_ALERT_SOUND_ENABLED:
			if(action.isEnabled) localStorage.setItem("playAlertSound", true);
			else localStorage.removeItem("playAlertSound");
			return state.set('alertSoundEnabled', !!action.isEnabled);
		case SET_ASS_REQ_SOUND_ENABLED:
			if(action.isEnabled) localStorage.setItem("playAssReqSound", true);
			else localStorage.removeItem("playAssReqSound");
			return state.set('assReqSoundEnabled', !!action.isEnabled);
		case SET_DETAILED_ALARM:
			return state
				.set('recording', null)
				.set('detailedAlarm', action.alarm)
				.set('loadingDetailedAlarm', false);
		case TOGGLE_ARC_ALARMS:
			const enabled = !state.get('showARCAlarms');
			localStorage.setItem('showARCAlarms', enabled ? 'true' : 'false');
			return state.set('showARCAlarms', enabled);
		case OPEN_ALARMS:
		case CLOSE_ALARMS:
			if(state.get('detailedAlarm') && action.alarms.find(a => a.id === state.get('detailedAlarm').id)) {
				return state.set('loadingDetailedAlarm', true);
			}
			return state;
		case SET_RECORDING:
			return state.set('recording', action.recording);
		default:
			return state;
	}
}

export default reducer;
