import Immutable from "immutable";
import TextField from "../../../components/Controls/TextField";
import CurrencyInput from "../../../components/Controls/CurrencyInput";

export const PriceMutationType = {
    fixedPrice: 1,
    fixedItemPrice: 2,
    relativeItemPrice: 3
};

const PricingRuleMutationModel = Immutable.Record({
    min: 0,
    max: null,
    type: PriceMutationType.fixedItemPrice,
    value: 1.0,
    upcharge: 0.0
});

export default class PricingRuleMutation extends PricingRuleMutationModel {
    min: number;
    max: ?number;
    type: number;
    value: number;
    upcharge: ?number

    get isValid() {
        if(this.get('max') && +this.get('max') <= +this.get('min')) return false;
        if (this.get('value') === 0) return true;
        return !!this.get('value');
    }

    uom() {
        const type = this.get('type');
        if (type === PriceMutationType.fixedPrice) return 'Prijs';
        if (type === PriceMutationType.fixedItemPrice) return 'Stuksprijs';
        if (type === PriceMutationType.relativeItemPrice) return 'Percentage';
        return '';
    }
    get inputComponent() {
        const type = this.get('type');
        if (type === PriceMutationType.relativeItemPrice) return TextField;
        return CurrencyInput;
    }

    calculate(qty, itemPrice) {
        qty = Math.abs(+qty);
        if(this.get('min') > qty) return null;
        if(!!this.get('max') && this.get('max') < qty) return null;
        const value = parseFloat(this.get('value'));
        const type = this.get('type');
        if (type === PriceMutationType.fixedPrice) return value;
        const upcharge = parseFloat(this.get('upcharge'));
        if (type === PriceMutationType.fixedItemPrice)
            return (value * qty) + upcharge;
        itemPrice *= (value / 100);
        return (itemPrice * qty) + upcharge;
    }

}