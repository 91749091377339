import { UserAPI } from '../../utils/API/UserAPI';
import {takeLatest, select, put} from 'redux-saga/effects';
import {selectCurrentUser} from "../selectors/app.selectors";
import {moduleIds} from "../../utils/models/Module";
import {selectGeofences} from "../selectors/geofence.selector";
import {selectTeams} from "../selectors/team.selector";
import {setDeviceToConnect} from "../../containers/ConnectDeviceDialog/actions";
import Device from "../../utils/models/Device";
import {
    CONNECT_APP,
    GET_APP_SEATS,
    UPDATE_USER,
    GET_USERS,
    SEND_APP_WELCOME_MAIL,
    WS_DATA_USERS,
    SEND_WELCOME_MAIL
} from "../constants/user.constants";
import {appSeatsChanged, setError, setLoading, usersChanged} from "../actions/user.actions";

const api = new UserAPI();

function* getUsers() {
    const users = yield api.getUsers();
    yield put(usersChanged(users));
}

function* updateUser({user, inviteForApp, informUser, callback}) {
    if(!callback) callback = () => {};
    if(user.id) {
        yield api.updateUser(user, inviteForApp);
    } else {
        const res = yield api.addUser(user, informUser, inviteForApp);
        if(res === 'existing') {
            yield put(setError('existingUser.error'));
            yield put(setLoading(false));
            return callback(false);
        }
        if(user.isAppOnly) {
            yield put(setDeviceToConnect(new Device(res.appDevice)));
        }
    }
    const newUsers = yield api.getUsers(true);
    yield put(usersChanged(newUsers));
    callback(true);
}

function* connectApp({user, inviteForApp}) {
    const appDevice = yield api.connectApp(user, inviteForApp);
    const newUsers = yield api.getUsers(true);
    yield put(usersChanged(newUsers));

    const currentUser = yield select(selectCurrentUser());
    if(!currentUser.hasModule(moduleIds.Teams) && !currentUser.hasModule(moduleIds.Geofences)) return;
    const geofences = yield select(selectGeofences());
    const teams = yield select(selectTeams());
    if(!geofences.size && !teams.size) return;

    if(appDevice) {
        yield put(setDeviceToConnect(new Device(appDevice)));
    }
}
function* getAppSeats() {
    const appSeats = yield api.getAppSeats();
    yield put(appSeatsChanged(appSeats));
}
function* sendAppWelcomeMail({user}) {
    yield api.sendAppWelcomeMail(user.email);
}
function* sendWelcomeMail({user}) {
    yield api.sendWelcomeMail(user.email);
}

export const userSagas = [
    takeLatest(GET_USERS, getUsers),
    takeLatest(GET_APP_SEATS, getAppSeats),
    takeLatest(CONNECT_APP, connectApp),
    takeLatest(SEND_APP_WELCOME_MAIL, sendAppWelcomeMail),
    takeLatest(SEND_WELCOME_MAIL, sendWelcomeMail),
    takeLatest(WS_DATA_USERS, getAppSeats),
    takeLatest(UPDATE_USER, updateUser)
];