import { select, takeEvery, put, takeLatest } from 'redux-saga/effects';
import {CLOSE_ASS_REQUEST, START_ASS_REQUEST} from '../constants/assistanceRequest.constants';
import { AssistanceRequestAPI } from '../../utils/API/AssistanceRequestAPI';
import { selectOptions } from '../../containers/PresentationPage/selectors';
import { setActiveAssistanceRequestId} from "../actions/assistanceRequest.actions";

const api = new AssistanceRequestAPI();

function *startAssistanceRequest({config}) {
  let page = yield select(selectOptions());
  yield api.start(config, page ? page.id : null);
}

function *closeAssistanceRequest({assistanceRequest, notes}) {
  yield put(setActiveAssistanceRequestId(null));
  yield api.close(assistanceRequest.id, notes);
}

export const assistanceRequestSagas = [
  takeEvery(START_ASS_REQUEST, startAssistanceRequest),
  takeLatest(CLOSE_ASS_REQUEST, closeAssistanceRequest)
];