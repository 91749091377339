import { Colors } from '../style/colors';

export const OPEN = 0;
export const CLOSED = 1;
export const CANCELED = 2;
export const CLOSED_ARC = 3;
export const CLOSED_SEQ = 4;
export const CLOSED_EXPIRED = 5;

export const colorForState = state => {
	switch (state) {
		case OPEN: return Colors.orange;
		case CLOSED:
		case CLOSED_SEQ:
		case CLOSED_ARC: return Colors.green;
		case CLOSED_EXPIRED: return Colors.gray30;
		default: return Colors.gray30;
	}
};