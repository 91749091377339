const regex = /{{([a-z])-([0-9]+)}}/;

const table = {
    c: 'contact',
    d: 'device'
}
const getReference = value => {
    if(!value) return null;
    const match = regex.exec(value);
    if(!match) return null;
    const type = match[1];
    const id = +match[2];
    if(!table[type]) return null;
    return {
        [table[type]]: id
    };
}
export default getReference;