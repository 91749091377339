import React from 'react';
import styled from 'styled-components';
import { getWhitelabel } from '../env';
import { Colors } from '../utils/style/colors';

const Splashscreen = React.memo(() => {
  // noinspection HtmlUnknownTarget
  return (
    <StyledSplashscreen>
      <img alt={`${getWhitelabel().title} Logo`} src="/loading.svg"/>
    </StyledSplashscreen>
  );
});

export default Splashscreen;

const StyledSplashscreen = styled.div`
  background: ${Colors.palette?.splashscreen || Colors.background()};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {  
    height: 50px;
    width: 50px;
  }
`;