import styled from 'styled-components';

export const Wrapper = styled.div`
	* {
		cursor: pointer;
	}
	${props => !props.isSelected && `
		.actions {
			/*opacity: 0;*/
			transition: all .1s;
		}
		&:hover .actions {
			opacity: 1;
		}
	`}
	.actions button {
		
	}
`;