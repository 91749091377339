import Immutable from "immutable";
import dayjs from "dayjs";

const CustomerModel = Immutable.Record({
    id: '',
    customer_name: '',
    tax_id: '',
    creation: '',
    modified: ''
});

export default class Customer extends CustomerModel {
    id: '';
    customer_name: string;
    tax_id: string;
    creation: string;
    modified: string;
    
    get creationDate() {
        return dayjs(this.creation);
    }

    get modifiedDate() {
        return dayjs(this.modified);
    }
    
    get filterString() {
        return JSON.stringify([
           this.get('customer_name'),
            this.get('tax_id'),
           this.get('id') 
        ]).lc();
    }
    
}