import BaseAPI from './BaseAPI';
import Event from '../models/Event';


export class EventAPI extends BaseAPI {

	closeEvents(events: Event[]) : Promise {
		return this.request('/sec/event', 'PUT', events.map(a => ({
				id: a.id,
				uiState: 1
		})));
	}

	openEvents(events: Event[]) {
		return this.request('/sec/event', 'PUT', events.map(a => ({
			id: a.id,
			uiState: 0
		})));
	}

}
