import List from '@mui/material/List';
import Grid from '@mui/material/Grid';

import * as styles from './style';
import React from 'react';
import Alarm from '../../../../utils/models/Alarm';
import Event from '../../../../utils/models/Event';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {compactDateTimeFormat} from '../../../../utils/style/formats';
import {AlarmHandler} from '../../../../utils/models/AlarmHandler';
import {cancelAlarms, setDetailedAlarm} from '../../../../redux/actions/alarm.actions';
import * as Text from '../../../../utils/style/texts';
import {Secondary} from '../../../../utils/style/texts';
import ClickableAlarmListItem from './ClickableAlarmListItem';
import {useTranslation} from 'react-i18next';
import {compose} from 'redux';
import {CancelIcon, SMSIcon} from '../../../../utils/icons';
import {Avatar} from '../../../../components/Avatar';
import {Flex} from '../../../../components/Container/Flex';
import {closeEvents} from '../../../../redux/actions/event.actions';
import {showAlert} from '../../../AlertDialog/actions';
import {Tooltip} from '@mui/material';
import {selectCurrentUser} from '../../../../redux/selectors/app.selectors';
import {useNavigate} from 'react-router';
import dayjs from "dayjs";

export function hasBeenNotified(alert) {
    if (!alert.isOpen) return true;
    const key = alert instanceof Alarm ? `a${alert.id}` : `e${alert.id}`;
    const json = localStorage.getItem('events') || JSON.stringify({});
    const notifiedItems = JSON.parse(json);
    const notified = !!notifiedItems[key];
    if (!notified) {
        notifiedItems[key] = dayjs().unix();
        localStorage.setItem('events', JSON.stringify(notifiedItems));
    }
    return notified;
}

(function cleanNotifiedEvents() {
    const json = localStorage.getItem('events') || JSON.stringify({});
    const notifiedItems = JSON.parse(json);
    const expiryDate = dayjs().add(-100, 'days').unix();
    Object.keys(notifiedItems).forEach(key => {
        if (notifiedItems[key] < expiryDate) delete notifiedItems[key];
    });
    localStorage.setItem('events', JSON.stringify(notifiedItems));
})();

const AlarmListSection = props => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {
        user,
        closeEvent,
        showAlert,
        selectedAlertKeys,
        toggleAlert,
        setDetailedAlarm,
        onCancelAlarm,
        hideCheckbox,
        alerts,
        devices,
    } = props;

    const renderEvent = (e, device) => {
        const Icon = e.icon;

        if (user.isAlarmCenter) {
            return (
                <ClickableAlarmListItem
                    key={e.get('id')}
                    user={user}
                    date={e.dateObj}
                    typeName={e.label}
                    deviceName={device.getName(t)}
                    onClick={() => {
                        if (!e.isOpen) return;
                        showAlert(t('areYouSure?'), t('confirm'), {
                            onReject: () => {
                            },
                            onConfirm: () => closeEvent(e),
                            rejectText: t('cancel'),
                            confirmText: t('close.alarm'),
                        });
                    }}
                    title={e.get('title')}
                    message={e.get('message')}
                    isOpen={e.isOpen}
                    event={e}/>);
        }
        return (
            <styles.AlertItem
                key={e.get('id')}
                disabled={!!e.get('disabled')}
                hideCheckbox={user.isPresentation}
                needsAttention={!hasBeenNotified(e) && e.isOpen && !user.isPresentation}
                primary={(
                    <Flex row>
                        <Avatar color={e.color} style={{marginRight: 10}}>
                            <Icon/>
                        </Avatar>
                        <styles.Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Flex row align="center">
                                    <Text.Text>{(e.get('title') || t(e.label))}{e.get('isTest') && ` (${t('test')})`}</Text.Text>
                                    {!!e.get('message') && (
                                        <Tooltip title={e.get('message')} style={{marginLeft: 5}}>
											<span>
												<SMSIcon color="action"/>
											</span>
                                        </Tooltip>
                                    )}
                                </Flex>
                            </Grid>
                            <Grid item xs={6}>
                                <Secondary>{device.getName(t)}</Secondary>
                            </Grid>
                            <Grid item xs={6}>
                                <Text.Secondary>{e.dateObj.format(compactDateTimeFormat)}</Text.Secondary>
                            </Grid>
                        </styles.Grid>
                    </Flex>
                )}
                actions={[]}
                onClick={() => {
                }}
                onToggle={() => toggleAlert(e)}
                isSelected={selectedAlertKeys.indexOf(e.key) >= 0}
            />
        );
    }

    const renderAlarm = (alarm, device) => {

        if (user.isAlarmCenter) {
            return (
                <ClickableAlarmListItem
                    key={alarm.get('id')}
                    user={user}
                    deviceName={device.getName(t)}
                    accountName={device.get('accountName')}
                    onClick={() => navigate(`/alarm/${alarm.get('token')}`)}
                    alarm={alarm}/>);
        }

        const handler = AlarmHandler.forDevAndAlarm(device, alarm);
        const tooltip = handler && !alarm.isCancelled ? t(handler.handledByTitle) : t(alarm.stateName);
        const avatarColor = handler && !alarm.isCancelled ? handler.color : alarm.color;

        const actions = [];
        if (alarm.cancelable && alarm.isOpen)
            actions.push({icon: <CancelIcon/>, title: t('cancel'), onClick: () => onCancelAlarm(alarm)});
        const Icon = alarm.icon;
        return (
            <styles.AlertItem
                key={alarm.get('id')}
                disabled={!!alarm.get('disabled')}
                hideCheckbox={hideCheckbox || user.isPresentation}
                needsAttention={!hasBeenNotified(alarm) && alarm.isOpen && !user.isPresentation}
                primary={(
                    <Flex row>
                        <Avatar
                            color={avatarColor}
                            tooltip={tooltip}
                            style={{marginRight: 10}}>
                            <Icon/>
                        </Avatar>
                        <styles.Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Text.Bold>{t(alarm.typeName)}</Text.Bold>
                            </Grid>
                            <Grid item xs={6}>
                                <Secondary>{device.getName(t)}</Secondary>
                            </Grid>
                            <Grid item
                                  xs={6}><Text.Secondary>{alarm.alarmDate.format(compactDateTimeFormat)}</Text.Secondary></Grid>
                        </styles.Grid>
                    </Flex>
                )}
                actions={actions}
                onToggle={() => toggleAlert(alarm)}
                isSelected={selectedAlertKeys.indexOf(alarm.key) >= 0}
                onClick={() => setDetailedAlarm(alarm)}
            />
        );
    }

    return (
        <List disablePadding={true}>
            {alerts.map(a => {
                let dev = devices.find(d => d.get('id') === a.get('deviceId'));
                if (!dev) {
                    return null;
                }

                if (a instanceof Event) {
                    return renderEvent(a, dev);
                }
                return renderAlarm(a, dev);
            })}
        </List>
    );
};


function mapDispatchToProps(dispatch) {
    return {
        setDetailedAlarm: alarm => dispatch(setDetailedAlarm(alarm)),
        onCancelAlarm: alarm => dispatch(cancelAlarms([alarm])),
        closeEvent: event => dispatch(closeEvents([event])),
        showAlert: (t, b, a) => dispatch(showAlert(t, b, a)),
    };
}

const mapStateToProps = createStructuredSelector({
    user: selectCurrentUser(),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(AlarmListSection);