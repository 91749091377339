import { APP, WS_DATA } from './app.constants';

export const DEVICES = `${APP}_devices`;

export const SELECT_DEVICE = `${DEVICES}/SELECT_DEVICE`;
export const RESELECT_DEVICE = `${DEVICES}/RESELECT_DEVICE`;
export const SAVE_DEVICES = `${DEVICES}/SAVE_DEVICE`;
export const SEND_MESSAGE = `${DEVICES}/SEND_MESSAGE`;
export const SEND_COMMAND = `${DEVICES}/SEND_COMMAND`;
export const SEND_SMS = `${DEVICES}/SEND_SMS`;
export const REBOOT = `${DEVICES}/REBOOT`;
export const SEND_SERVER_CONFIG = `${DEVICES}/TRANSFER`;
export const REQUEST_LOCATION = `${DEVICES}/REQUEST_LOCATION`;
export const REQUEST_FIRMWARE = `${DEVICES}/REQUEST_FIRMWARE`;
export const TOGGLE_DEVCON = `${DEVICES}/TOGGLE_DEVCON`;
export const FORCE_SETTINGS = `${DEVICES}/FORCE_SETTINGS`;
export const PUSH_SETTING = `${DEVICES}/PUSH_SETTING`;
export const PUSH_SETTINGS = `${DEVICES}/PUSH_SETTINGS`
export const CLEAR_RECEIVED_SETTINGS = `${DEVICES}/CLEAR_RECEIVED_SETTINGS`;
export const SHUT_DOWN = `${DEVICES}/SHUT_DOWN`;
export const FACTORY_RESTORE = `${DEVICES}/FACTORY_RESTORE`;
export const SET_TRACK_SINGLE_DEVICE_ID = `${DEVICES}/SET_TRACK_SINGLE_DEVICE_ID`;

export const WS_DATA_DEVICE = `${WS_DATA}/device`;
export const WS_DATA_DEVICE_PING = `${WS_DATA}/device/ping`;
export const WS_DATA_DEVICES = `${WS_DATA}/devices`;
export const WS_DATA_SETTING = `${WS_DATA}/setting`;
export const WS_DATA_IO = `${WS_DATA}/io`;