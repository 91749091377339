import {useTranslation, withTranslation} from 'react-i18next';
import {compose} from 'redux';
import * as React from 'react';
import {Flex} from '../../../components/Container/Flex';
import PlaceholderTextArea from '../../../components/PlaceholderTextArea';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {selectTeams} from '../../../redux/selectors/team.selector';
import {selectGeofences} from '../../../redux/selectors/geofence.selector';
import DeviceSelector from '../../../components/DeviceSelector';
import TeamSelector from '../../../components/TeamSelector';
import GeofenceSelector from '../../../components/GeofenceSelector';
import {moduleIds} from '../../../utils/models/Module';
import LanguageSelector from '../../../components/LanguageSelector';
import {fontSize, Secondary, Text} from '../../../utils/style/texts';
import TextField from '../../../components/Controls/TextField';
import Alert from '../../../components/Alert';
import {selectQuickTexts} from '../../../redux/selectors/quickText.selector';
import QuickTextPicker from '../../../components/QuickTextPicker';
import {forConcerningObject} from "../../../utils/models/Scenario";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "../../../components/Controls/Checkbox";
import ScenarioAction from "../../../utils/models/ScenarioAction";
import FollowUpScenarioPicker from "./FollowUpScenarioPicker";
import Select from "../../../components/Controls/Select";
import {scenarioTriggers} from "../../../utils/models/scenarios/scenarioTriggers";

const AssistanceRequestAction = props => {
    const [t, i18n] = useTranslation();

    let {
        config,
        onChange,
        scenario, 
        tileConfig,
        placeholders,
        user,
        isDeviceTrigger,
        showStatus,
        hideOffline,
        showQuickTexts,
        quickTexts,
    } = props;

    React.useEffect(() => {
        if (!config.name) {
            config.name = scenario?.get('name');
        }
        if (!config.recipients) {
            config.recipients = {devices: [], teams: [], geofences: []};
        }
        if (tileConfig?.name) {
            config.name = tileConfig.name;
        }
        if (tileConfig?.text) {
            config.text = tileConfig.text;
        }
        if (tileConfig?.devices) {
            config.recipients.devices = tileConfig.devices;
        }
        if (tileConfig?.teams) {
            config.recipients.teams = tileConfig.teams;
        }
        if (tileConfig?.geofences) {
            config.recipients.geofences = tileConfig.geofences;
        }
        onChange(config);
    }, []); // eslint-disable-line

    if (!config.language) {
        config.language = i18n.language;
    }
    if (typeof config.waitForNewLocation !== 'boolean') {
        config.waitForNewLocation = false;
    }
    if (scenario) {
        isDeviceTrigger = scenario.isDeviceTrigger;
    }
    if (!config.recipients) {
        config.recipients = {devices: [], teams: [], geofences: [], excludeOriginator: true};
    }
    if (tileConfig && tileConfig?.useQuickTexts !== null) {
        showQuickTexts = tileConfig?.useQuickTexts === true;
    }
    if(+config.accepts < 1 || isNaN(config.accepts)) {
        setTimeout(() => onChange({...config, accepts: 1}));
        return null;
    }
    if(isNaN(config.delay)) {
        setTimeout(() => onChange({...config, delay: 30}));
        return null;
    }

    return (
        <Flex col fullWidth>
            <Flex col align="flex-start" style={{marginBottom: 20}}>
                <Secondary>{t('assistanceRequestAction.explanation')}</Secondary>
            </Flex>

            <TextField
                label={t('name')}
                value={config.name}
                error={!config.name}
                disabled={tileConfig?.nameEditable === false}
                fullWidth
                type="text"
                onChange={name => onChange({...config, name})}
            />

            <br/>

            <DeviceSelector
                disabled={user.isPortalUser || tileConfig?.devicesEditable === false}
                isDeviceTrigger={isDeviceTrigger}
                alsoConcerningDevice={isDeviceTrigger}
                incompatibleActionText={t('actionNotCompatible.plural', {action: t('scenario.osm')})}
                appsOnly
                hideOffline={hideOffline}
                showStatus={showStatus}
                value={config.recipients.devices}
                onChange={value => {
                    config.recipients.devices = value;
                    if (value.indexOf(forConcerningObject)) {
                        config.recipients.excludeOriginator = false;
                    }
                    onChange(config);
                }}/>
            <br/>
            {user.hasModule(moduleIds.Teams) && (
                <>
                    <TeamSelector
                        disabled={user.isPortalUser || tileConfig?.teamsEditable === false}
                        isDeviceTrigger={isDeviceTrigger}
                        value={config.recipients.teams}
                        onChange={value => {
                            config.recipients.teams = value;
                            onChange(config);
                        }}/>
                    <br/>
                </>
            )}
            {user.hasModule(moduleIds.Geofences) && (
                <>
                    <GeofenceSelector
                        disabled={user.isPortalUser || tileConfig?.geofencesEditable === false}
                        isDeviceTrigger={isDeviceTrigger}
                        value={config.recipients.geofences}
                        onChange={value => {
                            config.recipients.geofences = value;
                            onChange(config);
                        }}/>
                    <br/>
                </>
            )}
            {(!!config.recipients.geofences.length || !!config.recipients.teams.length) && (
                <Alert type="info" small>
                    <Text size={fontSize.s}>{t('OSMandOrClarification.app')}</Text>
                </Alert>
            )}
            <br/>
            <Flex row>
                <PlaceholderTextArea
                    fullWidth
                    minRows={1}
                    label={t('message')}
                    disabled={user.isPortalUser || tileConfig?.textEditable === false}
                    ignoredCharacters={'_#\n'}
                    placeholders={placeholders}
                    value={config.text || ''}
                    onChange={text => onChange({...config, text})}
                    whenEmpty={config.waitForNewLocation ? config.whenEmpty : false}
                    setEmpty={(p, v) => {
                        config.whenEmpty[p] = v;
                        onChange(config);
                    }}
                />
                {showQuickTexts && !!quickTexts.size && (
                    <QuickTextPicker onChange={text => onChange({...config, text})}/>
                )}
            </Flex>
            <br/>
            {ScenarioAction.containsPositionPlaceholders(config.text) && (
                <Flex row align="center">
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={!!config.waitForNewLocation}
                                onChange={(_, c) => onChange({...config, waitForNewLocation: c})}
                            />
                        )}
                        labelPlacement="end"
                        label={t('waitForPossibleNewLocation')}
                    />
                </Flex>
            )}
            <br/>
            <LanguageSelector
                lang={config.language}
                disabled={user.isPortalUser}
                label={t('whatLanguageShouldTheMessageBeSent?')}
                onChange={language => onChange({...config, language})}
            />
            <br/>
            <FollowUpScenarioPicker
                forTrigger={scenarioTriggers.checkAssistanceRequestResponses}
                {...props} 
            />
            {config.followUpScenarioId > 0 && (
                <Flex row gap={6} align="center" fullWidth>
                    <Select
                        style={{flex: 1}}
                        fullWidth
                        required
                        value={+config.delay || -1}
                        label={t('waitForMax')}
                        disabled={config.disabled}
                        onChange={delay => onChange({...config, delay})}
                        items={[
                            {label: `30 ${t('seconds')}`, value: 30},
                            {label: `60 ${t('seconds')}`, value: 60},
                            {label: `2 ${t('minutes')}`, value: 120},
                            {label: `5 ${t('minutes')}`, value: 300}
                        ]}
                    />
                    <TextField
                        style={{flex: 1}}
                        label={t('minimumAccepts')}
                        required
                        fullWidth
                        type="number"
                        disabled={config.disabled}
                        onChange={accepts => onChange({...config, accepts})}
                        min={1}
                        value={+config.accepts}/>
                </Flex>
            )}
        </Flex>
    );
}

export function mapDispatchToProps() {
    return {};
}

const mapStateToProps = createStructuredSelector({
    teams: selectTeams(),
    geofences: selectGeofences(),
    quickTexts: selectQuickTexts()
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withTranslation(),
    withConnect,
)(AssistanceRequestAction);