import BaseAPI from './BaseAPI';
import User from '../models/User';

export class UserAPI extends BaseAPI {

	async sendAppWelcomeMail(email) {
		return this.request('/sec/user/app/welcome', 'POST', { email });
	}
	async sendWelcomeMail(email) {
		return this.request('/sec/user/welcome', 'POST', { email });
	}
	async getSubscribedComponents() {
		return this.request(`/sec/subscriptions/components`, 'GET');
	}
	async saveSubscriptions(subscriptions) {
		return this.request(`/sec/subscriptions/components`, 'PUT', subscriptions);
	}
	
	async checkUserEmailForAccount(email) {
		return this.request(`/sec/account/trial/email/${email || '-'}`, 'GET');
	}

	async is2FAEnabled() {
		return this.request('/sec/account/2FA', 'GET');
	}
	async set2FAEnabled(enabled: boolean) {
		return this.request('/sec/account/2FA', 'PUT', { enabled });
	}
	async resetTwoFA(user) {
		return this.request('/sec/account/2FA/reset', 'PUT', { user });
	}

	async agreedToTermsOfUse() {
		return this.request('/sec/user/terms', 'PUT');
	}
	async agreedToPrivacy() {
		return this.request('/sec/user/privacy', 'PUT');
	}

	async saveLanguage(language: string) {
		return this.request('/sec/user/lang', 'PUT', {language});
	}

	async getUsers(force) : User[] {
		const res = await this.request(`/sec/user?force=${force ? 1 : 0}`, 'GET');
		return res.map(u => new User(u));
	}

	async deleteUsers(users, informUser) {
		return await this.request(`/sec/user`, 'DELETE', {
			userIds: users.map(u => u.id),
			informUser: !!informUser
		});
	}

	async updateUser(user, inviteForApp) {
		return this.request('/sec/user', 'PUT', {
			id: user.id,
			groupId: user.get('groupId'),
			language: user.get('language'),
			inviteForApp: !!inviteForApp
		});
	}

	async addUser(user, informUser, inviteForApp) {
		return this.request('/sec/user', 'POST', {
			email: user.get('email'),
			groupId: user.get('groupId'),
			language: user.get('language'),
			informUser: !!informUser,
			inviteForApp: !!inviteForApp
		});
	}

	sendModuleActivationRequest(moduleId, comment) {
		return this.request('/sec/module/request', 'POST', {moduleId, comment});
	}

	async getAppSeats() {
		return this.request('/sec/user/app', 'GET');
	}
	async connectApp(user, inviteForApp) {
		return this.request('/sec/user/app', 'PUT', {
			id: user.id,
			informUser: !!inviteForApp
		});
	}

	async processSubscription(optIn) {
		return this.request('/sec/user/notifications', 'PUT', {
			notification: 'newsletterNL',
			enabled: optIn
		});
	}

	async resetMyTwoFA(email, password) {
		return this.request('/pub/user/resetMyTwoFA', 'POST', { email, password });
	}

	async requestSecurityCode(email, password) {
		return this.request('/pub/user/twoFaCode', 'POST', { email, password });
	}
}
