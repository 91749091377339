import UIGrid from '@mui/material/Grid';

import styled from 'styled-components';
import SelectableRow from '../../../../components/SelectableRow';

export const Grid = styled(UIGrid)`
	* {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
    }
`;

export const AlertItem = styled(SelectableRow)`
	${props => `
		transition: all .3s;
		${!props.onClick && `
			pointer-events: none !important;
		`};
		${props.disabled && `
			opacity: .5;
			pointer-events: none;
		`};
		${props.alarm && props.alarm.handledBy && `
			position: relative;
			&:before {
				content: ' ';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
		`};
		
		${props.needsAttention && `
			animation: attention .7s 3;
		`};
		
		@keyframes attention {
			0% { background: white; }
			50% { background: orange; }
			100% { background: white; }
		}
	`}
`;