import Immutable from 'immutable';
import {moduleIds} from './Module';
import dayjs from 'dayjs';
import {AlarmHandler} from './AlarmHandler';

export const AccountType = {
    EndCustomer: 1,
    AlarmCenter: 2,
    AccountManager: 3,
    SuperAdmin: 6
};

const AccountModel = Immutable.Record({
    id: 0,
    name: '',
    type: '',
    email: '',
    password: '',
    modules: [],
    arcKey: '',
    arcConfig: {},
    accountManagerId: 0,
    topAccountManagerId: 0,
    customized: '',
    credits: 25,
    scheduledReportLimit: 0,
    trialCredits: 0,
    creditUsage: {},
    creatorId: 0,
    created: 0,
    deleted: 0,
    archived: 0,
    trialEnd: 0,
    appSeats: 0,
    timezone: 'Europe/Amsterdam',
    language: 'nl-NL',
    reference: '',
    limitDevices: null,
    limitAccounts: null,
    whitelabelKey: null,
    jasperCustomer: null,
    clientId: null,
    secretId: null,
    customer: null
});

export default class Account extends AccountModel {
    id: number;
    name: string;
    type: string;
    email: string;
    password: string;
    accountManagerId: number;
    topAccountManagerId: number;
    modules: [];
    arcKey: string;
    arcConfig: {};
    customized: string;
    credits: number;
    trialCredits: number;
    scheduledReportLimit: number;
    creditUsage: any;
    creatorId: number;
    created: number;
    deleted: number;
    archived: number;
    trialEnd: number;
    appSeats: number;
    timezone: string;
    language: string;
    reference: string;
    limitDevices: boolean;
    limitAccounts: boolean;
    whitelabelKey: string;
    jasperCustomer: string;
    clientId: string;
    secretId: string;
    customer: ?string;

    hasModule(modId): boolean {
        if(this.isAccountManager) {
            if (modId === moduleIds.Beacons) return true;
        }
        return this.get('modules').indexOf(+modId) >= 0;
    }

    get isArchived() {
        return !!this.get('archived');
    }

    get hasAlarmCenter(): boolean {
        return !!this.alarmHandler;
    }

    get alarmHandler(): AlarmHandler {
        if(this.hasModule(moduleIds.SequrixAlarm)) {
            return AlarmHandler.SequriX;
        } 
        if(this.arcKey) {
            return AlarmHandler.ARC;
        }
        return null;
    }

    get creditUsagePercentage() {
        const c = this.get('creditUsage');
        if (!c) return 0;
        if (this.trialEnd && this.trialCredits === 0) return 100;
        return this.creditsUsed / this.get('credits') * 100;
    }

    get creditsUsed() {
        const c = this.get('creditUsage');
        return c ? (+c.voice) + (+c.call) + (+c.sms) : 0;
    }


    get createdDate() {
        if (this.get('created'))
            return dayjs.unix(this.get('created'));
        return null;
    }

    get deletedDate() {
        if (this.get('deleted'))
            return dayjs.unix(this.get('deleted'));
        return null;
    }

    get trialEndDate() {
        if (this.get('trialEnd'))
            return dayjs.unix(this.get('trialEnd'));
        return null;
    }

    get isAccountManager() {
        return this.get('type') === AccountType.AccountManager;
    }

    get isARC() {
        return this.get('type') === AccountType.AlarmCenter;
    }

    get isEndCustomer() {
        return this.get('type') === AccountType.EndCustomer;
    }

    filterString() {
        return [
            this.reference,
            this.name,
            this.id
        ].join('').lc();
    }
    
    //Manageable ARCs as account manager
    get manageableARCs() {
        if(!this.isAccountManager) return [];
        return ((this.arcConfig || {}).arcs) || [];
    }
    setManageableARCs(arcs) {
        if(!this.isAccountManager) return this;
        const config = this.arcConfig || {};
        return this.set('arcConfig', {...config, arcs});
    }

    static create(language) {
        return new Account({
            language: language || 'en-GB',
            modules: [
                moduleIds.TrackingScenario,
                moduleIds.Geofences,
                moduleIds.Teams,
                moduleIds.DeviceActivityReport
            ],
        });
    }

    static createAccountManager() {
        return new Account({
            jasperCustomer: '',
            modules: [
                moduleIds.EditAccounts_AM,
                moduleIds.EditDevices_AM,
                moduleIds.Beacons_AM,
                moduleIds.EditBeacons_AM,
                moduleIds.Sites_AM,
                moduleIds.EditSites_AM,
                moduleIds.Wiki_AM,
            ],
        });
    }
}
