import Immutable from "immutable";

const SalesInvoiceItemModel = Immutable.Record({
    item_code: '',
    item_name: '',
    qty: 1,
    amount: null,
    rate: null,
    name: ''
});

export default class SalesInvoiceItem extends SalesInvoiceItemModel {
    item_code: string;
    item_name: string;
    qty: number;
    amount: number;
    rate: number;
    name: string;
    
    get isValid() {
        return this.get('item_code') && this.get('item_name') && this.get('qty') >= 0;
    }
    grossAmount(invoice) {
        if(isNaN(this.get('amount')) || this.get('amount') === null) return '-';
        return parseFloat(this.get('amount'));
    }
    netAmount(invoice) {
        if(isNaN(this.get('amount')) || this.get('amount') === null) return '-';
        const amount = parseFloat(this.get('amount'));
        const taxRate = invoice.taxRate;
        return amount + (amount * taxRate);
    }
    formattedRate(currency) {
        if(isNaN(this.get('rate')) || this.get('rate') === null) return '-';
        return `${parseFloat(this.get('rate')).toFixed(2)} ${currency}`;
    }

}