import {fromJS, List, Map} from 'immutable';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';
import {
	APP_SEATS_CHANGED,
	CONNECT_APP, GET_USERS,
	SET_ERROR,
	SET_LOADING, UPDATE_USER,
	USERS_CHANGED,
	WS_DATA_USERS
} from "../constants/user.constants";
import User from "../../utils/models/User";
import {DELETE_USERS} from "../../containers/UsersPage/constants";

export const initialState = fromJS({
	data: [],
	fetched: false,
	loading: false,
	error: null
});

export default function reducer(state: Map = initialState, action) {
	switch (action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case CONNECT_APP:
		case UPDATE_USER:
		case DELETE_USERS:
		case GET_USERS:
			return state.set('loading', true);
		case SET_LOADING:
			return state.set('loading', action.loading);
		case SET_ERROR:
			return state.set('error', action.error);
		case USERS_CHANGED:
			return state
				.set('loading', false)
				.set('data', List(action.users));
		case APP_SEATS_CHANGED:
			return state.set('appSeats', action.appSeats);
		case WS_DATA_USERS:
			return state
				.set('data', List(action.data.map(d => new User(d))))
				.set('fetched', true);
		default:
			return state;
	}
}