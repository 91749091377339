import { all, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { selectDevice } from '../actions/device.actions';
import { selectDevices } from '../selectors/device.selector';
import {
	CANCEL_ALARMS,
	CLOSE_ALARMS,
	SET_DETAILED_ALARM,
	GET_SAFETY_REGIONS,
	OPEN_ALARMS,
	SHARE_ALARMS, WS_DATA_ALARM
} from '../constants/alarm.constants';
import { AlarmAPI } from '../../utils/API/AlarmAPI';
import {safetyRegionsChanged, setRecording, setDetailedAlarm, updateAlarm} from '../actions/alarm.actions';
import BaseAPI from '../../utils/API/BaseAPI';
import { authSuccess } from '../../containers/LoginPage/actions';
import User from '../../utils/models/User';
import Alarm from '../../utils/models/Alarm';
import { DeviceAPI } from '../../utils/API/DeviceAPI';
import { APP_MOUNTED } from '../constants/app.constants';
import { selectCurrentUser } from '../selectors/app.selectors';
import {selectDetailedAlarm, selectSafetyRegions} from "../selectors/alarm.selector";
import Geofence from "../../utils/models/Geofence";
import {hasBeenNotified} from "../../containers/AlarmPage/AlarmList/AlarmListSection";
import {getI18n} from "react-i18next";
import Notifications from "../../utils/notifications";

const alarmAPI: AlarmAPI = new AlarmAPI();
const deviceAPI: DeviceAPI = new DeviceAPI();
const api: BaseAPI = new BaseAPI();

function* setActiveDevice({alarm}) {
	if(alarm) {
		const devices = yield select(selectDevices());
		const device = devices.find(d => d.get('id') === alarm.get('deviceId'));
		yield put(selectDevice(device));
	} else {
		yield put(selectDevice());
	}
}
function* loadSnapshot({alarm}) {
	if(alarm && !alarm.isOpen && !alarm.get('snapshot')?.device) {
		const snapshot = yield alarmAPI.loadSnapshot(alarm.id);
		yield put(setDetailedAlarm(alarm.set('snapshot', snapshot)));
	}
}
function* loadRecording({alarm}) {
	if(alarm && alarm.isOpen) {
		const recording = yield deviceAPI.loadRecording(alarm.deviceId);
		const openAlarm = yield select(selectDetailedAlarm());
		if(openAlarm && +openAlarm.id === +alarm.id) {
			yield put(setRecording(recording || null));
		}
	}
}

function* shareAlarms({alarms, recipients, language}) {
	yield all(alarms.map(a => alarmAPI.shareAlarm(a, recipients, language)));
}

function* closeAlarms({alarms, blackbox}) {
	yield disableAlarms(alarms);
	for(const i in alarms.filter(a => a.canClose)) {
		const alert = alarms[i];
		
		yield alarmAPI.closeAlarm(alert, blackbox);

		const key = alert instanceof Alarm ? `a${alert.id}` : `e${alert.id}`;
		const json = localStorage.getItem('events') || JSON.stringify({});
		const notifiedItems = JSON.parse(json);
		delete notifiedItems[key];
		localStorage.setItem('events', JSON.stringify(notifiedItems));
	}
}

function* openAlarms({alarms}) {
	yield disableAlarms(alarms);
	alarms.forEach(a => alarmAPI.openAlarm(a));
}

function* cancelAlarms({alarms}) {
	yield disableAlarms(alarms);
	alarms.forEach(a => alarmAPI.cancelAlarm(a));
}

function* disableAlarms(alarms: Alarm[]) {
	for(const i in alarms) {
		yield put(updateAlarm(alarms[i].set('disabled', true).toJS()));
	}
}

function* tryLoginARCViaToken() {
	const currentUser = yield select(selectCurrentUser());
	if(currentUser) return;

	if(!window.location.pathname.match(/\/alarm\/.*/)) return;
	const token = window.location.pathname.split('/')[2];
	const user: User = yield api.loginARCWithToken(token);
	yield put(authSuccess(user, false));
}
function* tryLoginPublicViaToken() {
	const currentUser = yield select(selectCurrentUser());
	if(currentUser) return;

	if(!window.location.pathname.match(/\/alarm-location\/.*/)) return;
	const token = window.location.pathname.split('/')[2];
	const user: User = yield api.loginPublicAlarmWithToken(token);
	yield put(authSuccess(user, false));
}

function *getSafetyRegions() {
	const existing = yield select(selectSafetyRegions());
	if(existing) return;
	try {
		const data = yield alarmAPI.getSafetyRegions();
		if (data) yield put(safetyRegionsChanged(data.map(g => new Geofence(g))));
	} catch (_) {}
}

function *newAlarmNotification({data}) {
	if(data.state) return; //Is closing alarm
	const alarm = new Alarm(data);
	if(hasBeenNotified(alarm)) return; //Already notified
	if(document.visibilityState === 'visible') return; //Only show notification when not active
	if(Notifications?.permission !== 'granted') return; //No notification permissions
	const t = getI18n().t;
	new Notification(t(alarm.typeName), {
		body: alarm.deviceName,
		icon: "/favicon.png",
		silent: false,
		requireInteraction: true
	});
	const audio = new Audio(require('../../assets/sound/alarm.mp3'));
	audio.loop = false;
	audio.play().catch(error => console.error("Audio playback failed:", error));
	yield Promise.resolve();
}

export const alarmSagas = [
	takeLatest(APP_MOUNTED, tryLoginARCViaToken),
	takeLatest(APP_MOUNTED, tryLoginPublicViaToken),
	takeLatest(SET_DETAILED_ALARM, setActiveDevice),
	takeLatest(SET_DETAILED_ALARM, loadSnapshot),
	takeLatest(SET_DETAILED_ALARM, loadRecording),
	takeLatest(SHARE_ALARMS, shareAlarms),
	takeLatest(OPEN_ALARMS, openAlarms),
	takeLatest(CANCEL_ALARMS, cancelAlarms),
	takeLeading(CLOSE_ALARMS, closeAlarms),
	takeEvery(GET_SAFETY_REGIONS, getSafetyRegions),
	takeLatest(WS_DATA_ALARM, newAlarmNotification),
];