import { createSelector } from 'reselect';
import { DEVICES } from '../constants/device.constants';
import { initialState } from '../reducers/device.reducer';
import {ADMIN_PAGE} from "../../containers/AdminPage/constants";
import {fromJS, List} from "immutable";

const selectState = state => state[DEVICES] || initialState;
const selectAdminState = state => state[ADMIN_PAGE] || fromJS({});

export const selectDevices = () => createSelector([selectState, selectAdminState], (state, adminState) => {
    let devices = state.get('data').toList();
    if(!devices?.size) {
        devices = List(adminState.get('devices'));
    }
    const trackSingleDeviceId = state.get('trackSingleDeviceId');
    return devices
        .filter(d => !trackSingleDeviceId || d.id === trackSingleDeviceId)
        .sortBy(d => d.accountName + d.name);    
});
export const selectDevicesFetched = () => createSelector(selectState, state => state.get('fetched'));
export const selectSelectedDevice = () => createSelector(selectState, state => state.get('selectedDevice'));
export const selectDevConOpen = () => createSelector(selectState, state => state.get('devConOpen'));