import * as styles from './style';
import React from 'react';
import renderDots from './dots-bg';
import { List } from 'immutable';
import dayjs from 'dayjs';

require('./snow.css');

const startSnow = dayjs().set('month', 11).set('date', 1);
const endSnow = dayjs().set('month', 0).set('date', 5);
export const isSnowing = () => !dayjs().isBetween(endSnow, startSnow);

export class ConnectedDots extends React.PureComponent {

  shouldComponentUpdate(): boolean {
    return false;
  }

  render() {

    if(isSnowing()) {
      return (
        <div className="snow-bg">
          {(new List()).setSize(200).map((x,i) => (
            <div className="snow" key={i} />
          ))}
          <styles.SnowFloor />
        </div>
      );
    }
    return (
      <styles.AppBackground>
        <styles.AppBackgroundDots id="dots-bg" ref={() => {
          renderDots();
        }} />
      </styles.AppBackground>
    );

  }

}