import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import {Colors} from "../../utils/style/colors";

export const MyIconButton = styled(IconButton)`
	${props => props.variant === 'outlined' && `
		border: 1px solid ${Colors.gray60};
		padding-bottom: 6px;
	`};
	${props => !!props.disabled && `
		opacity: .7;
	`}
	${props => props.strike === 1 && `
		opacity: .7;
	
		&:before {
			content: ' ';
			position: absolute;
			width: 2px;
			height: 25px;
			background: white;
			display: inline-block;
			transform: rotate(-45deg) translateX(2px);
		}
		&:after {
			content: ' ';
			position: absolute;
			width: 2px;
			height: 25px;
			background: rgb(108, 108, 108);
			display: inline-block;
			transform: rotate(-45deg);
		}
	`};
`;