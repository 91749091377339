import BaseAPI from './BaseAPI';


export class AssistanceRequestAPI extends BaseAPI {

	async loadData(assistanceRequestId) {
		return this.request(`/sec/assistanceRequest/${assistanceRequestId}`, 'GET');
	}
	async close(id, notes) {
		return this.request(`/sec/assistanceRequest/close`, 'DELETE', {id, notes});
	}
	async start(config, pageId) {
		return this.request(`/sec/assistanceRequest/start`, 'POST', {config, pageId});
	}
}
