import { createSelector } from 'reselect';
import { ADMINISTRATION } from '../constants/administration.constants';
import { initialState } from '../reducers/administration.reducer';

const selectState = state => state[ADMINISTRATION] || initialState;
export const selectSalesItems = () => createSelector(selectState, state => state.get('salesItems'));
export const selectSalesInvoices = () => createSelector(selectState, state => state.get('salesInvoices'));
export const selectCustomers = () => createSelector(selectState, state => state.get('customers'));
export const selectPricingRules = () => createSelector(selectState, state => state.get('pricingRules'));
export const selectGeneralLedgerAccounts = () => createSelector(selectState, state => state.get('generalLedgerAccounts'));
export const selectTaxTemplates = () => createSelector(selectState, state => state.get('taxTemplates'));
export const selectPaymentTermsTemplates = () => createSelector(selectState, state => state.get('paymentTermsTemplates'));
export const selectSubscriptions = () => createSelector(selectState, state => state.get('subscriptions'));

export const selectSalesItemsFetched = () => createSelector(selectState, state => state.get('salesItemsFetched'));
export const selectGeneralLedgerAccountsFetched = () => createSelector(selectState, state => state.get('generalLedgerAccountsFetched'));
export const selectSalesInvoicesFetched = () => createSelector(selectState, state => state.get('salesInvoicesFetched'));
export const selectCustomersFetched = () => createSelector(selectState, state => state.get('customersFetched'));
export const selectPricingRulesFetched = () => createSelector(selectState, state => state.get('pricingRulesFetched'));
export const selectSubscriptionsFetched = () => createSelector(selectState, state => state.get('subscriptionsFetched'));