import { SAVE_CONNECTIONS } from './constants';
import { takeLatest, select, put } from 'redux-saga/effects';
import { TeamAPI } from '../../utils/API/TeamAPI';
import { GeofenceAPI } from '../../utils/API/GeofenceAPI';
import { selectTeams } from '../../redux/selectors/team.selector';
import { selectGeofences, selectPresence } from '../../redux/selectors/geofence.selector';
import { setDeviceToConnect } from './actions';
import { selectDevices } from '../../redux/selectors/device.selector';
import {consoleWarn} from "../../utils/log";

const teamAPI = new TeamAPI();
const geofenceAPI = new GeofenceAPI();

function *saveConnections({device, teamIds, geofenceIds}) {
	const devices = yield select(selectDevices());
	device = devices.find(d => +d.id === +device.id);
	if(!device) return consoleWarn(`Cannot save connections: ${JSON.stringify({device})}`);

	const dId = +device.id;
	const teams = yield select(selectTeams());
	for (const i in teams.toArray()) {
		let t = teams.get(i);
		const hadDevice = t.get('deviceIds').indexOf(dId) >= 0;
		const hasDevice = teamIds.indexOf(+t.id) >= 0;
		if(hadDevice === hasDevice) continue;
		let deviceIds = [...t.get('deviceIds')];
		if(hasDevice) {
			deviceIds.push(dId);
		} else {
			const index = deviceIds.indexOf(dId);
			deviceIds.splice(index, 1);
		}
		yield teamAPI.updateTeam(t.set('deviceIds', deviceIds));
	}
	const geofences = yield select(selectGeofences());
	const presence = yield select(selectPresence());
	for (const i in geofences.toArray()) {
		let g = geofences.get(i);
		const hadDevice = presence.byGeofence(+g.id)[device.id] !== undefined;
		const hasDevice = geofenceIds.indexOf(+g.id) >= 0;
		if(hadDevice === hasDevice) continue;
		let newDevices = devices.filter(d => presence.byGeofence(+g.id)[d.id] !== undefined);
		if(hasDevice) {
			newDevices = newDevices.push(device);
		} else {
			newDevices = newDevices.filter(d => +d.id !== dId);
		}
		newDevices = newDevices.map(d => d.id);
		yield geofenceAPI.updateGeofenceDevices(g, newDevices.toJS());
	}
	yield put(setDeviceToConnect(null));
}

export default function* sendSaga() {
	yield takeLatest(SAVE_CONNECTIONS, saveConnections);
}
