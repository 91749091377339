import { fromJS, Map } from 'immutable';
import AssistanceRequest from '../../utils/models/AssistanceRequest';
import { WS_DATA_ASS_REQUEST, WS_DATA_ASS_REQUESTS } from '../constants/alarm.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';
import { SET_ACTIVE_ASS_REQUEST, SHOW_ASS_REQUEST_DIALOG } from '../constants/assistanceRequest.constants';

export const initialState = fromJS({
	fetched: false,
	data: {},
	selection: [],
	active: null,
	showDialog: false,
	tileConfig: null
});

function reducer(state: Map = initialState, action) {
	let assistanceRequests = state.get('data');
	switch(action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case SHOW_ASS_REQUEST_DIALOG:
			return state
				.set('showDialog', !!action.show)
				.set('tileConfig', action.show ? action.tileConfig || null : null);
		case WS_DATA_ASS_REQUEST:
			return state.setIn(['data', action.data.id], new AssistanceRequest(action.data));
		case SET_ACTIVE_ASS_REQUEST:
			return state.set('active', action.id);
		case WS_DATA_ASS_REQUESTS:
			assistanceRequests = Map(action.data.map(d => new AssistanceRequest(d)).map(d => [d.id, d]));
			return state
				.set('data', assistanceRequests)
				.set('fetched', true);
		default:
			return state;
	}
}

export default reducer;
