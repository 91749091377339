import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import React from 'react';
import { Colors } from '../../../utils/style/colors';
import Button from '../../Controls/Button';
import TextButton from '../../Controls/TextButton';
import styled from 'styled-components';
import TextField from '../../Controls/TextField';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectActivateModule } from '../../../redux/selectors/app.selectors';
import Dialog from '../../Dialog';


class ActivateModuleDialog extends React.PureComponent {

	state = {
		comment: ''
	};

	componentDidUpdate(nextProps, nextState) {
		if(this.props.open !== nextProps.open) {
			nextState.comment = '';
		}
	}

	render() {
		const {onClose, open, t, activateModule} = this.props;

		return (
			<Dialog
				onError={{
					catch: reset => {
						onClose(false);
						reset();
					}
				}}
				open={open}
				onClose={() => onClose(false)}
			>
				{activateModule && (
					<DialogTitle>{t('activateModule')}: '{t(`module.name.${activateModule}`)}'</DialogTitle>
				)}
				<DialogContent style={{minWidth:300}}>
					<DialogContentText>
						{t('activateModule.explanation')}
					</DialogContentText>
					<br />
					<TextField
						variant="outlined"
						label={`${t('extraRemark')} (${t('optional')})`}
						fullWidth
						value={this.state.comment}
						multiline
						minRows={4}
						onChange={comment => this.setState({comment})}
						/>
					<ButtonContainer>
						<Button color={Colors.success} fullWidth onClick={() => onClose(this.state.comment)}>
							{t('doRequest')}
						</Button>
					</ButtonContainer>
				</DialogContent>
				<DialogActions>
					<TextButton onClick={() => onClose(false)}>
						{t('cancel')}
					</TextButton>
				</DialogActions>
			</Dialog>
		)
	}

	// activateModule: selectActivateModule(),
}

const ButtonContainer = styled.div`
	margin-top: 30px;
	padding: 20px 0;
	text-align: center;
`;


function mapDispatchToProps() {
	return {
	};
}

const mapStateToProps = createStructuredSelector({
	activateModule: selectActivateModule(),
});

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(ActivateModuleDialog);