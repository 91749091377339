import BaseAPI from './BaseAPI';
import Device from '../models/Device';
import {formatPhoneToPlain} from "../functions";


export class DeviceAPI extends BaseAPI {

	async saveDeviceByUrl(device) {
		const path = window.location.pathname.replace('/d/', '');
		return await this.request(`/pub/device/url/${path}`, 'PUT', device.toJS());
	}
	async getDeviceByUrl() {
		const path = window.location.pathname.replace('/d/', '');
		return await this.request(`/pub/device/url/${path}`, 'GET');
	}
	async createDeviceUrl(device, url) {
		return await this.request(`/sec/device/url`, 'POST', {
			deviceId: device.id, url
		});
	}
	async updateDeviceUrl(device, url) {
		return await this.request(`/sec/device/url`, 'PUT', {
			deviceId: device.id, url
		});
	}
	async deleteDeviceUrl(device, token) {
		return await this.request(`/sec/device/url/${device.id}/${token}`, 'DELETE');
	}
	async getDeviceProtocols() {
		return await this.request(`/sec/device/protocols`, 'GET');
	}
	async setFixedPosition(deviceId, latitude, longitude, altitude) {
		return await this.request(`/sec/device/fixedPosition`, 'PUT', {
			deviceId, latitude, longitude, altitude
		});
	}
	async getRemoteButtons(deviceId) {
		return await this.request(`/sec/device/remoteButtons/${deviceId}`, 'GET');
	}
	async getTwigCapabilities(imei) {
		return this.request(`/sec/device/twig/capabilities/${imei}`);
	}
	
	async notifyNewAppVersion(device) {
		return this.request(`/sec/device/notifyAppVersion/${device.id}`, 'POST');
	}
	async notifyAppPermissions(device) {
		return this.request(`/sec/device/notifyAppPermissions/${device.id}`, 'POST');
	}
	
	async getSmsHistory(phone) {
		phone = formatPhoneToPlain(phone, false); 
		return this.request(`/sec/device/smsHistory/${phone}`);
	}
	
	async disconnect(deviceId) {
		return this.request(`/sec/device/${deviceId}/disconnect`, 'POST');
	}
	async shutDown(deviceId) {
		return this.request(`/sec/device/${deviceId}/shutDown`, 'POST');
	} 
	async factoryRestore(deviceId) {
		return this.request(`/sec/device/${deviceId}/factoryRestore`, 'POST');
	}
	
	async loadRecording(deviceId) {
		return this.request(`/sec/device/${deviceId}/recording`);
	}

	async saveDevice(device: Device) {
		return this.request('/sec/device', 'PUT', { device: device.toJS(), forAllDevices: false });
	}

	async getStatus(device: Device) {
		return this.request(`/sec/device/${device.id}/status`, 'GET');
	}

	sendMessage(message: string, devices: Device[]) {
		return this.request('/sec/device/message', 'POST', {
			message,
			devices: devices.map(d => d.toJS())
		});
	}

	async sendCommand(command: string, device: Device) {
		return this.request('/sec/command', 'POST', {
			message: command,
			deviceId: device.get('id')
		});
	}
	async sendSMS(command: string, device: Device) {
		return this.request('/sec/command/sms', 'POST', {
			message: command,
			deviceId: device.get('id'),
		});
	}

	async reboot(device: Device) {
		return this.request('/sec/device/reboot', 'POST', {
			deviceId: device.id
		});
	}

	async requestLocation(device: Device) {
		return this.request('/sec/device/locationRequest', 'POST', { deviceId: device.id });
	}

	async requestFirmware(device: Device) {
		return this.request('/sec/device/firmware/' + device.id);
	}

	async sendServerConfig(device) {
		if(device.toJS) device = device.toJS();
		return this.request('/sec/device/admin/environment', 'PUT', device);
	}

	async checkCode(device: Device, code: string) {
		return this.request('/sec/device/activate', 'PUT', {
			deviceId: device.get('id'), code
		});
	}
	async refreshDevice(deviceId: number) {
		return this.request(`/sec/device/${deviceId}`, 'GET');
	}

	async forceSettings(device: Device, onlyFailed: boolean) {
		return this.request('/sec/devcon/force', 'PUT', {device, onlyFailed});
	}

	async requestAppLogs(deviceId) {
		return this.request(`/sec/app/${deviceId}/logs`, 'GET');
	}
	
	async getDeviceByToken(token) {
		return await this.request(`/sec/device/token/${token}`, 'GET');	
	}

}
