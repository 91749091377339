import React from 'react';
import UICheckbox from '@mui/material/Checkbox';

export default class Checkbox extends React.Component {

	render() {
		let {...other} = this.props;

		return (
			<UICheckbox color="secondary" {...other} />
		);
	}

}