import { fromJS } from 'immutable';
import { SAVE_CONNECTIONS, SET_DEVICE_TO_CONNECT } from './constants';

export const initialState = fromJS({
  deviceToConnect: null,
  isSaving: false,
});

function reducer(state: Map = initialState, action) {
  switch (action.type) {
    case SET_DEVICE_TO_CONNECT:
      return state.set('deviceToConnect', action.device).set('isSaving', false);
    case SAVE_CONNECTIONS:
      return state.set('isSaving', true);
    default:
      return state;
  }
}

export default reducer;
