import { APP, WS_DATA } from './app.constants';

export const DASHBOARD = `${APP}_DASHBOARD`;

export const CREATE_DASHBOARD = `${DASHBOARD}/CREATE_DASHBOARD`;
export const SAVE_DASHBOARD = `${DASHBOARD}/SAVE_DASHBOARD`;
export const SET_EDIT_DASHBOARD = `${DASHBOARD}/SET_EDIT_DASHBOARD`;
export const DELETE_DASHBOARD = `${DASHBOARD}/DELETE_DASHBOARD`;
export const SET_SELECTED_DASHBOARD = `${DASHBOARD}/SET_SELECTED_DASHBOARD`;
export const SET_LOADING = `${DASHBOARD}/SET_LOADING`;
export const SUBSCRIBE_WS_TOPICS = `${DASHBOARD}/SUBSCRIBE_WS_TOPICS`;

export const WS_DATA_DASHBOARDS = `${WS_DATA}/dashboards`;
export const WS_DATA_DASHBOARD = `${WS_DATA}/dashboard`;

export const WS_DATA_PRESENTATION_PAGE = `${WS_DATA}/presentationPage`;