import React from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectQuickTexts } from '../../redux/selectors/quickText.selector';
import { IconButton } from '../IconButton';
import { QuickTextsIcon } from '../../utils/icons';
import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

function mapDispatchToProps() {
  return {};
}

const mapStateToProps = createStructuredSelector({
  quickTexts: selectQuickTexts()
});

const QuickTextPicker = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(({quickTexts, onChange}) => {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [el, setEl] = React.useState();

  return <>
    <IconButton
      tooltip={t('quickTexts')}
      onClick={() => setOpen(true)}
      onRef={setEl}
      size="large">
      <QuickTextsIcon />
    </IconButton>
    <Menu
      anchorEl={el}
      keepMounted
      open={open}
      onClose={() => setOpen(false)}
    >
      {quickTexts.map(q => (
        <MenuItem
          onClick={() => {
            onChange(q.get('text'));
            setOpen(false);
          }}
          key={q.id}>{q.get('text').ellipsis(20)}</MenuItem>
      ))}
    </Menu>
  </>;

});

export default QuickTextPicker;