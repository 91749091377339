import { createSelector } from 'reselect';
import { PRESENTATION_PAGE_DIALOG } from './constants';
import { initialState } from './reducer';

const selectState = state => state[PRESENTATION_PAGE_DIALOG] || initialState;

export const selectShow = () => createSelector(selectState, state => state.get('show'));
export const selectLoading = () => createSelector(selectState, state => state.get('loading'));
export const selectURL = () => createSelector(selectState, state => state.get('url'));
export const selectPageType = () => createSelector(selectState, state => state.get('pageType'));
export const selectOptions = () => createSelector(selectState, state => state.get('options'));