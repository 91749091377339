import Immutable from 'immutable';

const QuickTextModel = Immutable.Record({
	id: 0,
	text: '',
	accountId: 0
});

export default class QuickText extends QuickTextModel {
	id: number;
	text: string;
	accountId: number;

	clone() {
		return this.set('id', this.id);
	}

	static create() {
		return new QuickText({});
	}
}
