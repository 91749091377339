import React, { Fragment } from 'react';
import { IconButton } from '../../components/IconButton';
import { CloseIcon } from '../icons';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';

const notistackRef = React.createRef();

const DismissAction = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <Fragment>
      <IconButton onClick={() => closeSnackbar(id)} size="large">
        <IconWrapper>
          <CloseIcon fontSize="small" size="small" />
        </IconWrapper>
      </IconButton>
    </Fragment>
  );
};

export const snackbarProps = {
  ref: notistackRef,
  classes: {
    root: 'no-borders-deep'
  },
  action: id => (
    <DismissAction id={id} />
  )
};

const IconWrapper = styled.section`
  svg {
    filter: contrast(1) brightness(0) invert(1);
  }
`;