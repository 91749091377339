import Tooltip from '@mui/material/Tooltip';

import React from 'react';
import * as styles from './style';
import { MyIconButton } from '../Controls/MyIconButton';
import { CircularProgress } from '@mui/material';


export class IconButton extends React.PureComponent {

	render() {
		const {onRef, tooltip, tooltipPlacement, isLoading, disabled, wrapperStyle, color} = this.props;
		return (
			<styles.Wrapper ref={r => onRef && onRef(r)} color={color} isDisabled={disabled} style={wrapperStyle}>
				{tooltip ? (
					<Tooltip title={tooltip || ''} placement={tooltipPlacement || 'bottom'}>
						<div>
							{this.renderIcon()}
						</div>
					</Tooltip>
				) : this.renderIcon()}
				{isLoading && (
					<styles.ProcessIndicator>
						<CircularProgress />
					</styles.ProcessIndicator>
				)}
			</styles.Wrapper>
		)
	}

	renderIcon() {
		let {color, onClick, disabled, type, strike, tooltipPlacement, small, isLoading, onRef, wrapperStyle, ...other} = this.props;
		if(disabled) onClick = () => {};
		return (
			<MyIconButton
				onClick={onClick}
				disabled={disabled}
				strike={strike ? 1 : 0}
				color={type}
				ref={r => onRef && onRef(r)}
				small={small ? 'yes' : undefined}
				{...other}
			/>
		);
	}

}