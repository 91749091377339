import {compose} from 'redux';
import React from 'react';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {showAssistanceRequestDialog, startAssistanceRequest} from '../../../redux/actions/assistanceRequest.actions';
import {
    selectAssistanceRequestTileConfig,
    selectShowAssistantRequestDialog,
} from '../../../redux/selectors/assistanceRequest.selector';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useTranslation} from 'react-i18next';
import SlideUpTransition from '../../../components/SlideUpTransition';
import AssistanceRequestAction from '../../ScenariosPage/ActionItem/AssistanceRequestAction';
import {useSnackbar} from 'notistack';
import {selectCurrentUser} from '../../../redux/selectors/app.selectors';
import TextButton from '../../../components/Controls/TextButton';

const validateConfig = ({name, text, recipients}) => {
    if (!name) return false;
    if (!text) return false;
    const {geofences, teams, devices} = recipients;
    return !!geofences.length || !!teams.length || !!devices.length;
};

function mapDispatchToProps(dispatch) {
    return {
        close: () => dispatch(showAssistanceRequestDialog(false)),
        start: c => dispatch(startAssistanceRequest(c))
    };
}

const mapStateToProps = createStructuredSelector({
    show: selectShowAssistantRequestDialog(),
    tileConfig: selectAssistanceRequestTileConfig(),
    user: selectCurrentUser()
});

const AssistanceRequestDialog = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(({user, show, close, start, tileConfig}) => {
    const [t] = useTranslation();
    const {enqueueSnackbar} = useSnackbar()
    const [config, setConfig] = React.useState({});
    const [, setUpdate] = React.useState(0);
    React.useEffect(() => {
        if (show) setTimeout(() => setConfig({
            ...config,
            name: config?.name || t('newAssistanceRequest')
        }));
    }, [show, t, setConfig]); // eslint-disable-line

    const configValid = validateConfig(config);

    return (
        <Dialog
            open={!!show}
            onClose={(e, reason) => {
                if (reason === 'backdropClick') return false;
                close();
            }}
            TransitionComponent={SlideUpTransition}
            fullWidth
            maxWidth="sm">
            <DialogTitle>{t('newAssistanceRequest')}</DialogTitle>
            <DialogContent>
                <AssistanceRequestAction
                    isDeviceTrigger={false}
                    tileConfig={tileConfig}
                    hideOffline
                    showStatus
                    user={user}
                    showQuickTexts
                    placeholders={{
                        date: 'dateOfEvent',
                        time: 'timeOfEvent'
                    }}
                    onChange={c => {
                        setConfig(c);
                        setUpdate(Date.now()); //Force rerender
                    }}
                    config={config}
                />
            </DialogContent>
            <DialogActions>
                <TextButton onClick={close}>{t('cancel')}</TextButton>
                <TextButton
                    onClick={() => {
                        start(config);
                        enqueueSnackbar(t('startingAssistanceRequest'));
                        close();
                    }}
                    color="primary"
                    disabled={!configValid}>{t('sendRequest')}</TextButton>
            </DialogActions>
        </Dialog>
    );

});

export default AssistanceRequestDialog;