import React from 'react';
import UISwitch from '@mui/material/Switch';

export default class Switch extends React.PureComponent {

	render() {
		let {onChange, className, ...other} = this.props;

		return (
			<UISwitch
				color="secondary"
				className={`${className} ui-switch-fix ${other.size}`}
				{...other}
				onChange={(e,val) => {
					if(onChange) onChange(val, e);
				}} />
		);
	}

}