import { createSelector } from 'reselect';
import { ASS_REQUESTS } from '../constants/assistanceRequest.constants';
import { initialState } from '../reducers/assistanceRequest.reducer';

const selectState = state => state[ASS_REQUESTS] || initialState;

export const selectAssistanceRequests = () => createSelector(selectState, state => state.get('data').toList().sortBy(a => -a.get('time')));
export const selectAssistanceRequestsFetched = () => createSelector(selectState, state => state.get('fetched'));
export const selectOpenAssistanceRequests = () => createSelector(selectState, state => state.get('data').toList().sortBy(a => -a.get('id')).filter(a => a.isOpen));
export const selectActiveAssistantRequestId = () => createSelector(selectState, state => state.get('active'));
export const selectShowAssistantRequestDialog = () => createSelector(selectState, state => state.get('showDialog'));
export const selectAssistanceRequestTileConfig = () => createSelector(selectState, state => state.get('tileConfig'));