import { Responsive } from '../../utils/style/responsive';
import styled from 'styled-components';
import { Shadows } from '../../utils/style/shadows';

export const Content = styled.div`
	background: white;
	box-shadow: ${Shadows.small};
	flex: 1;
	display: flex;
	animation pageFlyIn .3s;
	height: 100%;
`;

export const Wrapper = styled.div`
	flex: 1;
	display: flex;
	padding-top: 50px;
	width: calc(100% - 100px);
	min-width: 0; /*FireFox fix*/
	min-height: 0; /*FireFox fix*/
	transition: all .3s;
	${Responsive.compact`
		padding-top: 0;
		width: 100%;
	`}
`;