import Immutable from "immutable";

const GeneralLedgerModel = Immutable.Record({
    id: '',
    name: '',
    account_number: ''
});

export default class GeneralLedger extends GeneralLedgerModel {
    id: string;
    name: string;
    account_number: string;    
}