import { createSelector } from 'reselect';
import { initialState } from '../reducers/dashboard.reducer';
import { DASHBOARD } from '../constants/dashboard.constants';

const selectState = state => state[DASHBOARD] || initialState;

export const selectDashboards = () => createSelector(selectState, state => state.get('data').valueSeq().toList());
export const selectDashboardsFetched = () => createSelector(selectState, state => state.get('fetched'));
export const selectSelectedDashboard = () => createSelector(selectState, state => state.get('selected'));
export const selectEditDashboard = () => createSelector(selectState, state => state.get('editDashboard'));
export const selectLoading = () => createSelector(selectState, state => state.get('loading'));