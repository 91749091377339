import React from 'react';
import TextField from '@mui/material/TextField';
import Chip from '../Chip';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import {Flex} from '../Container/Flex';
import * as styles from './style';
import {Colors} from '../../utils/style/colors';
import {setCursorPosition} from '../../utils/functions';
import Tooltip from '@mui/material/Tooltip';
import {capitalize} from 'lodash/string';
import Alert from '../Alert';
import ScenarioAction from '../../utils/models/ScenarioAction';
import {createStructuredSelector} from "reselect";
import {
    selectDevices
} from "../../redux/selectors/device.selector";
import {connect} from "react-redux";

class PlaceholderTextArea extends React.PureComponent {

    cursor = 0;
    changeCursor = false;

    setCursorPos(pos) {
        this.cursor = pos;
    }

    // noinspection JSCheckFunctionSignatures,JSUnusedLocalSymbols
    componentDidUpdate(prevProps, prevState) {
        if (this.changeCursor) {
            const newPos = this.changeCursor;
            this.changeCursor = false;
            this.setCursorPos(newPos);
            setCursorPosition(this.ref, this.cursor);
        }
    }

    render() {
        const {
            devices,
            t,
            ignoredCharacters,
            disabled,
            label,
            maxLength,
            rows,
            whenEmpty,
            setEmpty,
            tReady,
            ...other
        } = this.props;
        if (!other.value) other.value = '';
        let showGPSWarning = !!ScenarioAction.gpsPlaceholders.filter(p => other.value.indexOf(`{{${p}}}`) >= 0).length;

        const extraFields = Object.fromEntries(
            devices
                .map(d => (d.get('extraFields') || [])
                    .map(f => f.name))
                .filter(f => f.length)
                .toJS().flat()
                .map(f => [f, {label: f, tooltipRaw: f, extra: true}])
        );
        const placeholders = {...this.props.placeholders, ...extraFields};

        return (
            <Flex fullWidth direction="column">
                <TextField
                    multiline
                    {...other}
                    disabled={disabled}
                    value={other.value}
                    label={label}
                    maxLength={maxLength}
                    minRows={rows || 2}
                    onChange={e => {
                        if (maxLength) {
                            e.target.value = e.target.value.substring(0, maxLength);
                        }
                        if (ignoredCharacters && ignoredCharacters.split('').find(ic => e.target.value.indexOf(ic) >= 0)) {
                            return;
                        }
                        this.props.onChange(e.target.value);
                    }}
                    inputRef={r => this.ref = r}
                    onKeyUp={() => this.setCursorPos(this.ref.selectionStart)}
                    onFocus={() => this.setCursorPos(this.ref.selectionStart)}
                    onClick={() => this.setCursorPos(this.ref.selectionStart)}
                    variant="outlined"
                />
                <styles.Chips>
                    {!disabled && Object.keys(placeholders).map(p => (
                        <Tooltip
                            key={p}
                            title={capitalize(placeholders[p].tooltipRaw || t(placeholders[p].tooltip || placeholders[p]))}>
              <span>
                <Chip
                    label={placeholders[p].label || t(p)}
                    color={placeholders[p].extra ? Colors.blue : Colors.lightGreen}
                    onClick={() => {
                        const part1 = this.props.value.substring(0, this.cursor);
                        const part2 = this.props.value.substring(this.cursor);
                        const placeholder = `{{${placeholders[p].key || p}}}`;
                        const newValue = `${part1}${placeholder}${part2}`;
                        this.changeCursor = placeholder.length + this.cursor;
                        this.props.onChange(newValue);
                    }}
                    small/>
                </span>
                        </Tooltip>
                    ))}
                </styles.Chips>
                {whenEmpty && showGPSWarning && (
                    <Flex col>
                        <Alert type="info" dismissable={false}>
                            {t('scenarioActionGPSFix.explanation')}
                        </Alert>
                        {ScenarioAction.gpsPlaceholders.filter(p => other.value.indexOf(`{{${p}}}`) >= 0).map(p => (
                            <TextField
                                key={p}
                                style={{marginTop: 5}}
                                disabled={disabled || !setEmpty}
                                label={`${t(p)} {{${p}}}`}
                                fullWidth
                                value={whenEmpty[p]}
                                onChange={e => setEmpty(p, e.target.value)}
                            />
                        ))}
                    </Flex>
                )}
            </Flex>
        );
    }
}

function mapDispatchToProps() {
    return {};
}

const mapStateToProps = createStructuredSelector({
    devices: selectDevices()
});
export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(PlaceholderTextArea);