import { createSelector } from 'reselect';
import { TEAMS } from '../constants/team.constants';
import { initialState } from '../reducers/team.reducer';
import {ADMIN_PAGE} from "../../containers/AdminPage/constants";
import {fromJS, List} from "immutable";

const selectState = state => state[TEAMS] || initialState;
const selectAdminState = state => state[ADMIN_PAGE] || fromJS({});

export const selectTeams = () => createSelector([selectState, selectAdminState], (state, adminState) => {
    let teams = state.get('data').toList();
    if(!teams?.size) {
        teams = List(adminState.get('teams'));
    }
    return teams.sortBy(t => t.accountName + t.name);
});
export const selectSelectedTeam = () => createSelector(selectState, state => state.get('selectedTeam'));
export const selectTeamsFetched = () => createSelector(selectState, state => state.get('fetched'));