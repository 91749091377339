import {Colors} from "../../style/colors";
import {scenarioTriggers} from "./scenarioTriggers";

const scenarioTriggerCategories = {
    personalAlarms: 'personalAlarms',
    deviceStatus: 'deviceStatus',
    localisation: 'localisation',
    followUp: 'followUp',
    other: 'other'
};
export const scenarioTriggersMap = {
    [scenarioTriggerCategories.personalAlarms]: {
        color: Colors.danger,
        triggers: [
            scenarioTriggers.allAlarmTypes,
            scenarioTriggers.genericAlarm,
            scenarioTriggers.sosButton,
            scenarioTriggers.manDown,
            scenarioTriggers.ripcord,
            scenarioTriggers.timer,
            scenarioTriggers.alarmClosed,
            scenarioTriggers.assistanceRequestClosed,
            scenarioTriggers.arcAlarm
        ]
    },
    [scenarioTriggerCategories.deviceStatus]: {
        color: Colors.blue,
        triggers: [
            scenarioTriggers.connectionChanged,
            scenarioTriggers.docked,
            scenarioTriggers.undocked,
            scenarioTriggers.lowBattery,
            scenarioTriggers.configError
        ]
    },
    [scenarioTriggerCategories.localisation]: {
        color: Colors.green,
        triggers: [
            scenarioTriggers.geofenceEntered,
            scenarioTriggers.geofenceExited,
            scenarioTriggers.beaconBattery
        ]
    },
    [scenarioTriggerCategories.followUp]: {
        color: Colors.lightYellow,
        triggers: [
            scenarioTriggers.messageNotAcknowledged,
            scenarioTriggers.checkAssistanceRequestResponses
        ]
    },
    [scenarioTriggerCategories.other]: {
        color: Colors.gray60,
        triggers: [
            scenarioTriggers.smsMessage,
            scenarioTriggers.webhook,
            scenarioTriggers.quickButton,
            scenarioTriggers.ioChange,
            scenarioTriggers.email,
            scenarioTriggers.espa,
            scenarioTriggers.multiTenant,
            scenarioTriggers.scenarioDisabled
        ]
    }
};