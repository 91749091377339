import {showAlert} from "./AlertDialog/actions";
import {useTranslation} from "react-i18next";
import {createStructuredSelector} from "reselect";
import {compose} from "redux";
import {connect} from "react-redux";
import {getNavigator} from "../Navigator";

const TestAlarmLinkDialog = props => {
    const [t] = useTranslation();
    if(window.location.search?.indexOf('testAlarmLocation') === -1)
        return null;

    props.showAlert(t('testAlarmLink.explanation'), t('testAlarmLink.title'), {
        onConfirm: () => getNavigator()('/')
    });
    return null;
};

function mapDispatchToProps(dispatch) {
    return {
        showAlert: (t, b, a) => dispatch(showAlert(t, b, a))
    };
}

const mapStateToProps = createStructuredSelector({
    
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(TestAlarmLinkDialog);