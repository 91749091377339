import {getWhitelabel} from '../../env';

const whitelabel = getWhitelabel();

const user = localStorage.getItem('user');
let overridePalette = null;
if (user) {
    const info = JSON.parse(user);
    if (info.palette) {
        if (Array.isArray(info.palette)) {
            info.palette = {
                primary: info.palette[0],
                secondary: info.palette[4],
                background: '#999999'
            }
        }
        overridePalette = Object.assign(whitelabel.palette, info.palette);
    }
}

export class Colors {
    static transparent = '#FFFFFFFF';

    static palette = overridePalette || whitelabel.palette;

    static setPalette(palette) {
        if (!palette) return;
        const changed = JSON.stringify(Colors.palette) !== JSON.stringify(palette);
        Colors.palette = palette;
        return changed;
    }

    static green = '#7AC943';
    static lightGreen = '#C5E7AC';
    static yellow = '#F3AD3B';
    static lightYellow = '#f3e69e';
    static orange = '#ffa000';
    static blue = '#497ac5';
    static lightBlue = '#D6E1F2';
    static lightRed = '#dc8492';

    static primary = () => Colors.palette.colorPrimary;
    static secondary = () => Colors.palette.colorSecondary;
    static background = () => Colors.palette.colorBackground;

    static white = '#ffffff';
    static black = '#17191c';

    static info = '#497ac5';
    static success = '#28a745';
    static warning = '#fccb7c';
    static danger = '#dc3545';

    static gray05 = '#f8f8f8'; //248
    static gray10 = '#F2F2F2'; //242
    static gray20 = '#ebebeb'; //235
    static gray30 = '#BFBFBF'; //191
    static gray40 = '#999999'; //153
    static gray50 = '#808080'; //128
    static gray60 = '#393E44'; //~62
    
    static idle = '#0CB8DF';
    
    static vhd = '#FF7330';
    static sequrix = '#006c39';
    static stanley = '#FC273F';
    static egdom = '#00409e';
    static securitas = '#FC273F';
    static hofland = '#de2708';
    static westvliet = '#dd3333';
    static europac = '#0082ba';
    static siemens = '#40b3b3';

    static Android = '#3ddc84';
    static iOS = '#636366';


    static beacons = {
        bt: '#0060a9',
        srd: '#72a75f',
        srdTag: '#72a75f',
        srdDoor: '#72a75f',
        wifi: '#dc6c70',
        nfc: '#09a2e1'
    };

    static reportColor = i => {
        const colors = [
            '#8931EF',
            '#F2CA19',
            '#FF00BD',
            '#0057E9',
            '#87E911',
            '#E11845',
            '#c3e484',
            '#2e4e1f',
            '#9739ec',
            '#4830f4'
        ];
        return colors[+i % colors.length];
    };

    static geofence = '#C3996B'; //palet 4

    static actionIcon = '#757575';

}

export function toRGB(hex) {
    hex = hex.substr(0, 7);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}

export function toHex(rgb) {
    return '#' + rgb.map(c => c.toString(16).padStart(2, 0)).join('');
}

export function alpha(hex, a) {
    const rgba = toRGB(hex);
    rgba.push(a);
    return `rgba(${rgba.join(',')})`;
}

export function contrastColor(backgroundColorHex, darkColor = Colors.gray60, lightColor = Colors.white) {
    return isDark(backgroundColorHex) ? lightColor : darkColor;
}

export function isDark(color, threshold = 0.3) {
    if (!color) return false;
    try {
        const [r, g, b] = toRGB(color);
        const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return a > threshold;
    } catch (e) {
        return false;
    }
}

export function lighten(color, percentage, isHex = true) {
    const rgb = isHex ? toRGB(color) : color;
    return toHex(rgb.map(r => Math.abs(Math.min(255, Math.round(r * percentage)))));
}

export function darken(color, percentage, isHex = true) {
    return lighten(color, 1 / percentage, isHex);
}

export function noHash(clr) {
    return clr.replace('#', '');
}
