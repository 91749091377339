const myLanguageDetector = {
  name: 'myLanguageDetector',

  lookup() {
    if(localStorage.getItem('i18nextLng')) {
      return localStorage.getItem('i18nextLng');
    }
    return null;
  }
};
export default myLanguageDetector;