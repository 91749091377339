import {
  AUTH_FAIL,
  AUTH_SEND,
  AUTH_SUCCESS,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CODE_ERROR,
  LOGIN_2FA,
  RESET_2FA,
  TWO_FA_IMAGE_CHANGED,
  BACK_TO_LOGIN,
  REQUEST_CODE_VIA_EMAIL
} from './constants';
import User from '../../utils/models/User';

export const sendAuth = twoFaMethod => ({ type: AUTH_SEND, twoFaMethod });
export const authSuccess = (user: User, store) => ({ type: AUTH_SUCCESS, user, store });
export const authFail = (error) => ({ type: AUTH_FAIL, error });
export const changeEmail = (email) => ({ type: CHANGE_EMAIL, email });
export const twoFAImageChanged = (img,manual) => ({ type: TWO_FA_IMAGE_CHANGED, img, manual });
export const loginTwoFA = (code, dontAsk, method) => ({ type: LOGIN_2FA, code, dontAsk, method });
export const codeError = () => ({ type: CODE_ERROR });
export const resetTwoFA = () => ({ type: RESET_2FA })
export const changePassword = password => ({ type: CHANGE_PASSWORD, password });
export const backToLogin = () => ({ type: BACK_TO_LOGIN });
export const requestCodeViaEmail = () => ({ type: REQUEST_CODE_VIA_EMAIL });
