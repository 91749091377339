import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import BaseAPI from './API/BaseAPI';
import { getStore } from '../configureStore';
import {
	checkJWT,
	connectionChanged,
	dataReceived,
	newToken,
	reconnectWebsocket,
	websocketInitialized,
} from '../redux/actions/app.actions';
import * as Comlink from "comlink";
import {consoleDebug, consoleError, consoleWarn} from "./log";

let wsUrl = process.env.REACT_APP_WS_URL;
if(!wsUrl) {
	wsUrl = window.location.origin.replace('http', 'ws') + "/ws";
}
if(localStorage.getItem("wsUrl")) {
	wsUrl = localStorage.getItem("wsUrl");
}

const clientPing = '>';
const clientPong = '<';
const serverPing = '?';
const serverPong = '!';

let instance;

const WebsocketWrapper = Comlink.wrap(new Worker('/websocketWorker.js'));

export class MyWebSocket {

	static retryInterval = null;
	token = null;
	lastMessage = 0;
	ws: WebSocket = null;

	static inst() {
		if(!instance) {
			instance = new MyWebSocket();
			global.ws = instance;
		}
		return instance;
	}

	constructor() {
		if(MyWebSocket.retryInterval) clearInterval(MyWebSocket.retryInterval);
		MyWebSocket.retryInterval = setInterval(async () => {
			this.isOpen().then(async isOpen => {
				if(!isOpen) return;
				this.send(clientPing);
				const lastMsg = dayjs().unix() - this.lastMessage;
				this.debug(`[websocket] last message ${lastMsg}s ago`);
				if(lastMsg < 125) return;
				await this.restart();
			});
		}, 60 * 1000);
	}
	
	subscribe(topic) {
		consoleDebug(`[websocket] subscribing to ${topic}`);
		this.send(JSON.stringify({type: 'subscribe', topic, token: BaseAPI.token}));
	}

	debug(line) {
		consoleDebug(line);
	}

	dispatch(action) {
		getStore().dispatch(action);
	}
	
	async heartBeat() {
		if(!BaseAPI.token) return;
		const now = dayjs().unix();
		try {
			const { exp } = jwtDecode(BaseAPI.token);
			if(exp - now < 600 && await this.isOpen()) { //token expires in 10 minutes
				this.debug('[websocket] requesting new token');
				this.send(JSON.stringify({type: 'newToken', token: BaseAPI.token}));
			}
		} catch (_) {
			//Possibly token error
		}
	}

	connectionChanged(isConnected) {
		try {
			this.dispatch(connectionChanged(isConnected));
		} catch (e) {
			consoleWarn(e);
		}
	}

	send(data) {
        this.ws?.send(data);
	}

	async open() {
		if(await this.isOpen()) this.close();

		this.lastMessage = dayjs().unix();
		return new Promise(async (resolve) => {
			if(!BaseAPI.token) return consoleError('token not defined');
			this.debug('Opening websocket');
			try {
				this.ws = await new WebsocketWrapper(wsUrl);
			} catch(e) {}
			this.ws.onmessage = Comlink.proxy(msg => {
				this.lastMessage = dayjs().unix();
				if(msg.data.toString() === serverPing) {
					this.debug('[websocket] heartbeat server');
					this.heartBeat();
					return this.ws.send(serverPong);
				}
				if(msg.data.toString() === clientPong) {
					return this.debug('[websocket] heartbeat');
				}
				const {type, data} = JSON.parse(msg.data);
				if(type === 'unauthorized') {
					this.debug('[websocket] unauthorized');
					this.dispatch(checkJWT());
				} else if(type === 'connected') {
					this.debug('[websocket] authorized');
					this.dispatch(websocketInitialized());
					resolve();
					this.connectionChanged(true);
				} else if(type === 'newToken') {
					this.debug('[websocket] new token');
					this.dispatch(newToken(data));
				} else {
					this.debug('[websocket] message received');
					this.dispatch(dataReceived(data.type, data.data));
				}
			});
			this.ws.onclose = Comlink.proxy(e => {
				this.debug(`[websocket] closed: ${e.reason} (${e.code}). Was clean: ${e.wasClean}`);
				getStore().dispatch(reconnectWebsocket(500, true));
				this.connectionChanged(false);
				this.close();
			});
			this.ws.onopen = Comlink.proxy(() => {
				this.debug('[websocket] opened');
				this.send(JSON.stringify({ type: 'auth', token: BaseAPI.token }));
			});
		});
	}

	async isOpen() {
		return !!await this.ws?.isOpen();
	}

	close(reconnect = true) {
		this.lastMessage = 0;
		this.ws?.close();
		this.ws = null;
		if(!reconnect) return;
		getStore().dispatch(reconnectWebsocket(10000, true));
	}

	async restart() {
		this.debug('Restarting websocket');
		this.close(false);
		await new Promise(r => setTimeout(r, 100));
		await this.open();
	}
}