import React from 'react';
import {useTranslation} from 'react-i18next';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {compose} from 'redux';
import FormControl from '@mui/material/FormControl';
import Checkbox from '../Controls/Checkbox';
import {forAnyObject} from '../../utils/models/Scenario';
import {selectTeams, selectTeamsFetched} from '../../redux/selectors/team.selector';
import {capitalize} from 'lodash/string';
import {CircularProgress} from '@mui/material';
import Alert from '../Alert';
import MuiAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {CheckBoxCheckedIcon, CheckBoxUncheckedIcon} from "../../utils/icons";
import {Flex} from "../Container/Flex";
import {selectCurrentUser} from "../../redux/selectors/app.selectors";
import {moduleIds} from "../../utils/models/Module";

/* eslint jsx-a11y/role-supports-aria-props: 0 */

const inputLabelRef = React.createRef();
const TeamSelector = props => {
  const [t] = useTranslation();
  const {fetched, renderValue, teams, user, disabled, isSource, isDeviceTrigger} = props;
  if(!user.hasModule(moduleIds.Teams)) return null;
  
  const accountIds = props.accountIds || [user.accountId];
  
  let value = props.value;
  
  const onChange = newValue => {
    newValue = newValue.filter(v => v <= 0 || teams.find(d => +d.id === +v));
    props.onChange(newValue);
  };
  
  const defaultLabel = () => {
    if (value.indexOf(forAnyObject) >= 0) return capitalize(isDeviceTrigger ? t('allConnectedTeams') : t('allTeams'));
    const items = value.filter(v => v <= 0 || teams.find(d => +d.id === +v));
    if (items.length > 1) return t('teams.count', {count: items.length});
    if (items.length === 1) return (teams.find(d => d.id === items[0]) || {}).name;
    return '';
  }

  if (!Array.isArray(value)) {
    setTimeout(() => onChange([]));
    return null;
  }

  const options = [{
    label: capitalize(isDeviceTrigger ? t('allConnectedTeams') : isSource ? t('anyTeam') : t('allTeams')),
    value: forAnyObject,
  }, ...teams.map(g => ({
    label: g.name,
    value: g.id,
    team: g
  }))];
  const teamMap= {};
  teams.map(g => teamMap[g.id] = g);

  if (value && value.length) {
    value = value.filter(v => v <= 0 || !teams[v]);
  }

  const forAny = value.indexOf(forAnyObject) >= 0;

  return (
      <Flex>
        {teams.size ? (
            <FormControl ref={inputLabelRef} fullWidth disabled={!!disabled}>
              <MuiAutocomplete
                  multiple
                  value={value}
                  renderOption={({dataOptionIndex, ...props}, o, {selected}) => (
                      <li {...props}
                          aria-selected={false}
                          data-teamid={o.value}
                          data-checked={value.indexOf(o.value) >= 0 ? 'yes' : 'no'}
                      >
                        <Checkbox
                            icon={<CheckBoxUncheckedIcon/>}
                            checkedIcon={<CheckBoxCheckedIcon/>}
                            indeterminate={forAny && o.value > 0}
                            color={o.value > 0 ? 'primary' : 'secondary'}
                            style={{marginRight: 8, pointerEvents: 'none'}}
                            checked={value.indexOf(o.value) >= 0 || forAny}
                        />
                        {o.label}
                      </li>
                  )}
                  isOptionEqualToValue={(o, v) => value.indexOf(v) >= 0}
                  disabled={!!disabled}
                  disableCloseOnSelect
                  groupBy={o => accountIds?.length > 1 ? o.team?.accountName : null}
                  renderTags={() => renderValue ? renderValue() : defaultLabel()}
                  getOptionLabel={o => o.team?.filterString() || 'any'}
                  onChange={e => {
                    const clickedTeamId = +e.target.dataset.teamid;
                    if(isNaN(clickedTeamId)) return onChange([]);
                    const wasChecked = e.target.dataset.checked === 'yes';
                    if(wasChecked) {
                      if(clickedTeamId === forAnyObject) {
                        onChange([]);
                      } else {
                        onChange(value.filter(v => v !== clickedTeamId));
                      }
                    } else {
                      if(clickedTeamId === forAnyObject) {
                        onChange([forAnyObject]);
                      } else {
                        if(forAny) {
                          onChange(options.filter(o => o.value > 0 && o.value !== clickedTeamId).map(o => o.value));
                        } else {
                          onChange([...value, clickedTeamId]);
                        }
                      }
                    }
                  }}
                  renderInput={(params) => (
                      <TextField
                          fullWidth
                          {...params}
                          label={t('teams')}
                      />
                  )}
                  options={options}
              />
            </FormControl>
        ) : (
            <>
              {fetched ? <Alert type="info" dismissable={false}>{t('noTeams')}</Alert> : (
                  <CircularProgress size={16}/>
              )}
            </>
        )}
      </Flex>
  );
}


export const mapDispatchToProps = () => ({});

const mapStateToProps = createStructuredSelector({
  teams: selectTeams(),
  user: selectCurrentUser(),
  fetched: selectTeamsFetched()
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(TeamSelector);