import BaseAPI from './BaseAPI';
import Geofence from '../models/Geofence';


export class GeofenceAPI extends BaseAPI {

	async addGeofence(geofence: Geofence) {
		return await this.request('/sec/geofence', 'POST', geofence.toJS());
	}
	async updateGeofence(geofence: Geofence) {
		return await this.request('/sec/geofence', 'PUT', geofence.toJS());
	}

	async deleteGeofence(geofence: Geofence) {
		return await this.request('/sec/geofence', 'DELETE', { id: geofence.id });
	}

	async updateGeofenceDevices(geofence: Geofence, deviceIds: number[], teamIds: ?number[]) {
		return await this.request('/sec/geofence/devices', 'PUT', {
			geofenceId: geofence.id,
			deviceIds: deviceIds,
			teamIds: teamIds
		});
	}
}
