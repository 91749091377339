import {scenarioTriggers} from "./scenarioTriggers";

export const triggerConfigKeys = {
    devices: 'devices',
    geofences: 'geofences',
    beacons: 'beacons',
    connectionChanged: 'connectionChanged',
    io: 'io',
    sms: 'sms',
    webhook: 'webhook',
    email: 'email',
    espa: 'espa',
    multiTenant: 'multiTenant',
    quickButton: 'quickButton',
    scenarios: 'scenarios'
};
export const triggerConfigs = {
    [scenarioTriggers.allAlarmTypes]: triggerConfigKeys.devices,
    [scenarioTriggers.genericAlarm]: triggerConfigKeys.devices,
    [scenarioTriggers.manDown]: triggerConfigKeys.devices,
    [scenarioTriggers.sosButton]: triggerConfigKeys.devices,
    [scenarioTriggers.ripcord]: triggerConfigKeys.devices,
    [scenarioTriggers.timer]: triggerConfigKeys.devices,
    [scenarioTriggers.alarmClosed]: triggerConfigKeys.devices,
    [scenarioTriggers.configError]: triggerConfigKeys.devices,
    [scenarioTriggers.arcAlarm]: triggerConfigKeys.devices,
    [scenarioTriggers.connectionChanged]: triggerConfigKeys.connectionChanged,
    [scenarioTriggers.docked]: triggerConfigKeys.devices,
    [scenarioTriggers.undocked]: triggerConfigKeys.devices,
    [scenarioTriggers.lowBattery]: triggerConfigKeys.devices,
    [scenarioTriggers.ioChange]: triggerConfigKeys.io,
    [scenarioTriggers.beaconBattery]: triggerConfigKeys.beacons,
    [scenarioTriggers.geofenceEntered]: triggerConfigKeys.geofences,
    [scenarioTriggers.geofenceExited]: triggerConfigKeys.geofences,
    [scenarioTriggers.smsMessage]: triggerConfigKeys.sms,
    [scenarioTriggers.webhook]: triggerConfigKeys.webhook,
    [scenarioTriggers.email]: triggerConfigKeys.email,
    [scenarioTriggers.espa]: triggerConfigKeys.espa,
    [scenarioTriggers.multiTenant]: triggerConfigKeys.multiTenant,
    [scenarioTriggers.quickButton]: triggerConfigKeys.quickButton,
    [scenarioTriggers.scenarioDisabled]: triggerConfigKeys.scenarios
};