import { fromJS, Map } from 'immutable';
import Team from '../../utils/models/Team';
import { SELECT_TEAM, WS_DATA_TEAM, WS_DATA_TEAMS } from '../constants/team.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	data: {},
	fetched: false,
	selectedTeam: null,
	error: null
});

export default function teamsReducer(state: Map = initialState, action) {
	let teams = state.get('data');
	switch (action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_TEAM:
			return state
				.setIn(['data', action.data.id], new Team(action.data))
				.set('fetched', true);
		case WS_DATA_TEAMS:
			teams = Map(action.data.map(d => new Team(d)).map(d => [d.id, d]));
			return state
				.set('data', teams)
				.set('fetched', true);
		case SELECT_TEAM:
			return state.set('selectedTeam', action.team);
		default:
			return state;
	}
}