import Immutable from 'immutable';
import dayjs from 'dayjs';
import { OPEN, CLOSED } from './AlarmState';

const AssistanceRequestModel = Immutable.Record({
	id: 0,
	name: '',
	message: '',
	groupName: '',
	time: 0,
	checked: 0,
	snapshot: {},
	payload: '',
	state: OPEN,
	deviceId: 0,
	scenarioId: 0,
	eventId: 0,
	responses: {},
	notes: ''
});

export default class AssistanceRequest extends AssistanceRequestModel {

	id: number;
	name: string;
	message: string;
	groupName: string;
	checked: number;
	time: number;
	payload: string;
	deviceId: number;
	eventId: number;
	scenarioId: number;
	responses: Object;
	notes: string;

	state: [OPEN, CLOSED];
	snapshot: Object;

	get checkedDate() : dayjs {
		return dayjs.unix(this.get('checked'));
	}

	get date() : dayjs {
		return dayjs.unix(this.get('time'));
	}

	get isOpen() : boolean {
		return +this.get('state') === OPEN;
	}

}
