import React, {useEffect} from 'react';
import {compose} from 'redux';
import {createStructuredSelector} from 'reselect';
import {
    selectAlarmSoundEnabled,
    selectAlertSoundEnabled,
    selectAssistanceRequestSoundEnabled, selectDetailedAlarm,
    selectOpenAlarms,
} from '../../redux/selectors/alarm.selector';
import {connect} from 'react-redux';
import {selectOpenEvents} from '../../redux/selectors/event.selector';
import {
    selectActiveAssistantRequestId,
    selectOpenAssistanceRequests,
} from '../../redux/selectors/assistanceRequest.selector';
import {isSupportedBrowser} from '../..';
import {selectCurrentUser} from '../../redux/selectors/app.selectors';
import {consoleWarn, consoleDebug} from "../../utils/log";

let playAudio = null;
const onFocus = () => {
    if (!isSupportedBrowser) return;
    if (playAudio) setTimeout(playAudio);
}
window.addEventListener('focus', onFocus);

const AlarmSound = props => {
    let {
        alarmSoundEnabled,
        alerts,
        assistanceRequests,
        alertSoundEnabled,
        assistanceRequestSoundEnabled,
        user,
        alarms,
        detailedAlarm,
        selectedAssistanceRequest
    } = props;
    const audioRef = React.useRef();
    useEffect(() => {
        if (!isSupportedBrowser) return;
        if (!audioRef?.current) return;
        playAudio = () => {
            setTimeout(() => {
                audioRef.current?.play().then(
                    (_) => consoleDebug('Starting audio'),
                    (e) => consoleWarn(e)
                );
            }, 50);
        };
        window.addEventListener('focus', playAudio);
        playAudio();
        return () => window.removeEventListener('focus', playAudio);
    }, [audioRef]);
    if (!isSupportedBrowser) return null;

    if (user.isSuperAdmin || user.isAccountManager) return null;
    if (user.isMonitorUser) {
        alarms = alarms.filter(a => !a.get('arc'));
    }

    let soundEnabled = false;

    if (alarms.size && (alarmSoundEnabled || user.isARC)) soundEnabled = true;
    else if (alerts.size && alertSoundEnabled && !user.isARC) soundEnabled = true;
    else if (assistanceRequests.size && assistanceRequestSoundEnabled && !user.isARC) soundEnabled = true;

    let assistanceRequest = assistanceRequests.find(a => a.groupName === selectedAssistanceRequest);

    if ((detailedAlarm && detailedAlarm.isOpen) || (assistanceRequest && assistanceRequest.isOpen)) {
        soundEnabled = false;
    }

    return (
        <div style={{position: 'fixed', zIndex: -100, left: -100, top: -100, width: 0, height: 0}}>
            <audio
                autoPlay
                ref={audioRef}
                muted={!soundEnabled}
                loop>
                <source src={require('../../assets/sound/alarm.mp3')} type="audio/mpeg"/>
            </audio>
        </div>
    );

}

function mapDispatchToProps() {
    return {};
}

const mapStateToProps = createStructuredSelector({
    user: selectCurrentUser(),
    detailedAlarm: selectDetailedAlarm(),
    selectedAssistanceRequest: selectActiveAssistantRequestId(),
    alarms: selectOpenAlarms(),
    alerts: selectOpenEvents(),
    assistanceRequests: selectOpenAssistanceRequests(),
    alarmSoundEnabled: selectAlarmSoundEnabled(),
    alertSoundEnabled: selectAlertSoundEnabled(),
    assistanceRequestSoundEnabled: selectAssistanceRequestSoundEnabled(),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(AlarmSound);