import MaterialButton from '@mui/material/Button';

import React from 'react';

import styled from 'styled-components';
import {Colors} from '../../utils/style/colors';
import ButtonProgressBar from './ButtonProgressBar';

const TextButton = props => {

    const {loading, classes, onRef, small, ...other} = props;
    return (
        <Wrapper {...other} classes={classes} onClick={() => {
        }}>
            <MaterialButton
                ref={el => {
                    if (onRef) onRef(el);
                }}
                size={small ? 'small' : 'large'}
                {...other}
            />
            {loading && <ButtonProgressBar isVisible={loading}/>}
        </Wrapper>
    );
};

export default TextButton;

const Wrapper = styled.div`
	display: inline;
	border: 0 !important;
	${props => props.disabled && 'opacity: 0.5'};
	${props => props.fullWidth && 'width: 100%'};
	${props => !props.color && `
		button {
			color: ${Colors.gray50};
		}
	`};
	.progressBar {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
`;