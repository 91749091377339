import Immutable, {fromJS} from 'immutable';

const PromModel = Immutable.Record({
	arc: '',
	accountId: 0,
	accountName: '',
	deviceId: 0,
	deviceAccountId: 0,
	deviceName: '',
	note: '',
	prom: 0,
	accountEligible: false,
	forAccountId: 0,
	isUsed: false,
	
	_group: []
});

export default class Prom extends PromModel {

	arc: string;
	note: string;
	prom: number;

	accountId: number;
	accountName: string;
	deviceId: number;
	deviceAccountId: number;
	deviceName: string;
	accountEligible: boolean;
	forAccountId: number;
	isUsed: boolean;
	
	_group: [];

	get key() {
		return `${this.get('arc')}:${this.get('prom')}`
	}
	
	get name() {
		if(!this.deviceName) return '';
		return `${this.deviceName} (${this.deviceId})`;
	}
	
	get groupNames() {
		if(!this.get('_group')) return [this.name];
		return fromJS(this.get('_group').map(p => p.name)).toJS().filter(g => !!g);
	}
	
	containsDevice(deviceId) {
		if(!this.get('_group')) return this.deviceId === deviceId;
		return !!this.get('_group').find(p => p.deviceId === deviceId);
	} 

}
