import {
    APP_SEATS_CHANGED,
    CONNECT_APP,
    GET_APP_SEATS,
    SEND_APP_WELCOME_MAIL,
    GET_USERS,
    USERS_CHANGED,
    UPDATE_USER,
    SET_LOADING,
    SET_ERROR, SEND_WELCOME_MAIL
} from "../constants/user.constants";

export const getAppSeats = () => ({ type: GET_APP_SEATS });
export const appSeatsChanged = appSeats => ({ type: APP_SEATS_CHANGED, appSeats });
export const sendAppWelcomeMail = user => ({type: SEND_APP_WELCOME_MAIL, user});
export const sendWelcomeMail = user => ({type: SEND_WELCOME_MAIL, user});
export const connectApp = (user, inviteForApp) => ({ type: CONNECT_APP, user, inviteForApp });
export const getUsers = force => ({ type: GET_USERS, force });
export const usersChanged = users => ({ type: USERS_CHANGED, users });
export const updateUser = (user, inviteForApp, informUser, callback) => ({ type: UPDATE_USER, user, inviteForApp, informUser, callback });
export const setError = error => ({type: SET_ERROR, error});
export const setLoading = loading => ({type: SET_LOADING, loading});
