import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItem from '@mui/material/ListItem';
import Checkbox from '../Controls/Checkbox';

import React from 'react';

import * as styles from './style';
import { compose } from 'redux';
import { IconButton } from '../IconButton';
import {ListItemButton} from "@mui/material";


class SelectableRow extends React.PureComponent {

	render() {
		const {
			onClick,
			isSelected,
			onToggle,
			primary,
			secondary,
			actions,
			hideCheckbox,
			...other
		} = this.props;
		return (
            <styles.Wrapper isSelected={isSelected} {...other}>
				<ListItem
					dense
					divider
					onClick={() => onClick()}>
					<ListItemButton>
						{hideCheckbox !== true && (
							<Checkbox
								checked={isSelected}
								tabIndex={-1}
								disableRipple
								onClick={e => {
									e.stopPropagation();
									onToggle();
								}}
							/>
						)}
						<ListItemText primary={primary} secondary={secondary}/>
						{actions && (
							<ListItemSecondaryAction className="actions">
								{actions.map((a, i) => (
									<IconButton
										key={i}
										tooltip={a.title}
										tooltipPlacement="bottom"
										onClick={() => a.onClick()} size="large">
										{a.icon}
									</IconButton>
								))}
							</ListItemSecondaryAction>
						)}
					</ListItemButton>
				</ListItem>
			</styles.Wrapper>
        );
	}

}


export default compose(
)(SelectableRow);