import {scenarioTriggers} from "./scenarioTriggers";

export const eventToTrigger = {
    'GENERIC_ALARM': scenarioTriggers.genericAlarm,
    'ALARM_CLOSED': scenarioTriggers.alarmClosed,
    'ASS_REQ_CLOSED': scenarioTriggers.assistanceRequestClosed,
    'SOS': scenarioTriggers.sosButton,
    'MAN DOWN': scenarioTriggers.manDown,
    'AMBER': scenarioTriggers.timer,
    'AMBER_SERVER': scenarioTriggers.timer,
    'RIPCORD': scenarioTriggers.ripcord,
    'ON': scenarioTriggers.connectionChanged,
    'OFF': scenarioTriggers.connectionChanged,
    'DOCKED': scenarioTriggers.docked,
    'UNDOCKED': scenarioTriggers.undocked,
    'LOW_BAT': scenarioTriggers.lowBattery,
    'ENTER': scenarioTriggers.geofenceEntered,
    'EXIT': scenarioTriggers.geofenceExited,
    'IO_CHANGE': scenarioTriggers.ioChange,
    'ASS_1 QUICK': scenarioTriggers.quickButton,
    'ASS_2 QUICK': scenarioTriggers.quickButton,
    'ASS_3 QUICK': scenarioTriggers.quickButton,
    'ASS_4 QUICK': scenarioTriggers.quickButton,
    'WEBHOOK': scenarioTriggers.webhook,
    'ESPA': scenarioTriggers.espa,
    'EMAIL': scenarioTriggers.email,
    'CONFIG_ERROR': scenarioTriggers.configError,
    'MULTI_TENANT': scenarioTriggers.multiTenant,
    'NO_MSG_ACK': scenarioTriggers.messageNotAcknowledged,
    'CHECK_ASS_REQ_RESPONSES': scenarioTriggers.checkAssistanceRequestResponses,
    'ARC_ALARM': scenarioTriggers.arcAlarm
}