import Immutable, {List} from "immutable";
import dayjs from "dayjs";
import PricingRuleMutation from "./PricingRuleMutation";
import _maxBy from 'lodash/maxBy';

const PricingRuleModel = Immutable.Record({
    id: 0,
    customer: '',
    item: '',
    validFrom: dayjs().unix(),
    mutations: [new PricingRuleMutation()]
});

export default class PricingRule extends PricingRuleModel {
    id: number;
    customer: string;
    item: string;
    validFrom: number;
    mutations: PricingRuleMutation[];

    get groupKey() {
        return `${this.item}-${this.customer}`;
    }

    isActive(otherRules, onDate) {
        if(!onDate) onDate = dayjs();
        otherRules = otherRules.filter(r => r.validFromDate < onDate);
        const groups = List(otherRules).groupBy(g => g.groupKey).toJS();
        const activeRules = Object.values(groups)
            .map(g => _maxBy(g, x => x.validFrom).id);
        return activeRules.indexOf(this.id) >= 0;
    }

    get validFromDate() {
        return dayjs.unix(this.validFrom).startOf('day');
    }

    get isValid() {
        if (!this.get('item')) return false;
        const mutations = this.get('mutations');
        const mutationWithoutCeiling = mutations.find(m => !m.max);
        if (mutationWithoutCeiling) {
            if (mutations.indexOf(mutationWithoutCeiling) < mutations.length - 1) return false;
        } else {
            if (!this.customer) return false;
        }
        return !mutations.find(m => !m.isValid);
    }

    get filterString() {
        return JSON.stringify([
            this.get('customer'),
            this.get('item'),
            this.get('id')
        ]).lc();
    }

    isDefaultForItem(item, id) {
        if (!this.isActive) return false;
        if (this.customer) return false;
        if (this.id === id) return false;
        return this.item === item;
    }

    calculate(qty, itemPrice) {
        qty = Math.abs(+qty);
        itemPrice = parseFloat(itemPrice);
        const price = this.get('mutations')
            .map(m => m.calculate(qty, itemPrice))
            .find(p => p !== null);
        return price || (itemPrice * qty);
    }

    constructor(props) {
        if (!props) props = {};
        if (!props.mutations) props.mutations = [{}];
        props.mutations = props.mutations.map(m => new PricingRuleMutation(m))
        super(props);
    }

}