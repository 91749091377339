import { APP } from '../../redux/constants/app.constants';

export const MAP_PAGE = `${APP}/mapPage`;
export const CHANGE_LAYOUT = `${MAP_PAGE}/CHANGE_LAYOUT`;
export const PAN_TO_DEVICE = `${MAP_PAGE}/PAN_TO_DEVICE`;
export const SET_FIXED_POSITION = `${MAP_PAGE}/SET_FIXED_POSITION`;
export const CLICKED_ON_MAP = `${MAP_PAGE}/CLICKED_ON_MAP`;
export const PAN_TO_BEACON = `${MAP_PAGE}/PAN_TO_BEACON`;
export const PAN_TO_SITE = `${MAP_PAGE}/PAN_TO_SITE`;
export const PAN_TO_GEOFENCE = `${MAP_PAGE}/PAN_TO_GEOFENCE`;

export const SET_MAP = `${MAP_PAGE}/SET_MAP`;

export const LAYOUT_NO_MAP = 1;
export const LAYOUT_MAP_SECONDARY = 2;
export const LAYOUT_MAP_PRIMARY = 3;
export const LAYOUT_ONLY_MAP = 4;
export const LAYOUT_IFRAME_MAP = 5;
export const LAYOUT_ONLY_PRIMARY = 6;