import {
    CLEAR_RECEIVED_SETTINGS,
    FORCE_SETTINGS,
    PUSH_SETTING,
    REBOOT, REQUEST_FIRMWARE,
    REQUEST_LOCATION,
    RESELECT_DEVICE,
    SAVE_DEVICES,
    SELECT_DEVICE,
    SEND_COMMAND,
    SEND_MESSAGE,
    SEND_SERVER_CONFIG,
    SEND_SMS,
    TOGGLE_DEVCON,
    SHUT_DOWN, SET_TRACK_SINGLE_DEVICE_ID, FACTORY_RESTORE, PUSH_SETTINGS
} from '../constants/device.constants';

export const selectDevice = device => ({type: SELECT_DEVICE, device});
export const reselectDevice = device => ({type: RESELECT_DEVICE, device});
export const saveDevices = devices => ({type: SAVE_DEVICES, devices});
export const sendMessage = (message, devices) => ({type: SEND_MESSAGE, message, devices});
export const sendCommand = (command, device) => ({type: SEND_COMMAND, command, device});
export const sendSMS = (command, device) => ({type: SEND_SMS, command, device});
export const forceSettings = (device, onlyFailed) => ({type: FORCE_SETTINGS, device, onlyFailed});
export const reboot = (device) => ({type: REBOOT, device});
export const sendServerSetup = (device, config) => ({type: SEND_SERVER_CONFIG, device, config});
export const requestLocation = (device) => ({type: REQUEST_LOCATION, device});
export const requestFirmware = (device) => ({type: REQUEST_FIRMWARE, device});
export const toggleDevCon = (isOpen) => ({type: TOGGLE_DEVCON, isOpen});
export const pushSetting = (setting, value, notes, device, callback) => ({type: PUSH_SETTING, setting, value, device, notes, callback});
export const pushSettings = (list, device) => ({type: PUSH_SETTINGS, list, device});
export const clearReceivedSettings = () => ({type: CLEAR_RECEIVED_SETTINGS});
export const shutDown = device => ({type: SHUT_DOWN, device});
export const factoryRestore = device => ({type: FACTORY_RESTORE, device});
export const setTrackSingleDeviceId = deviceId => ({type: SET_TRACK_SINGLE_DEVICE_ID, deviceId});