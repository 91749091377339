export const UserTypes = {
	NORMAL: 'NORMAL',
	ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
	ADMIN: 'ADMIN',
	VEVIGO: 'VEVIGO',
	INTERNAL: 'INTERNAL',
	TEST: 'TEST',
	MONITORING: 'MONITORING',
	ALL_IN: 'ALL_IN',
	PRESENTATION: 'PRESENTATION',
	APP_ONLY: 'APP_ONLY',
	ARC: 'ARC',
	SHADOW_ADMIN: 'SHADOW_ADMIN',
	SHADOW_ACCOUNT_MANAGER: 'SHADOW_ACCOUNT_MANAGER',

	fromUser(user) {
		if(user.groupId === 2) return UserTypes.VEVIGO;
		else if(user.groupId === 3) return UserTypes.ADMIN;
		else if(user.groupId === 6) return UserTypes.ACCOUNT_MANAGER;
		else if(user.groupId === 8) return UserTypes.MONITORING;
		else if(user.groupId === 10) return UserTypes.PRESENTATION;
		else if(user.groupId === 11) return UserTypes.APP_ONLY;
		else if(user.groupId === 12) return UserTypes.ARC;
		else if(user.groupId === 13) return UserTypes.SHADOW_ADMIN;
		else if(user.groupId === 14) return UserTypes.SHADOW_ACCOUNT_MANAGER;
		return UserTypes.NORMAL;
	}
};