import React from 'react';
import * as styles from './style';
import {CheckIcon, CloseIcon, ErrorIcon, InfoIcon, WarningIcon} from '../../utils/icons';
import {Text} from "../../utils/style/texts";
import {Clickable} from "./style";
import {useTranslation} from "react-i18next";
import {Flex} from "../Container/Flex";

const Alert = props => {
    const [t] = useTranslation();
    const {
        text,
        iconRight,
        animation,
        fullWidth,
        inline,
        type,
        children,
        style,
        small,
        collapsedLength
    } = props;
    const [closed, setClosed] = React.useState(false);
    const [collapsed, setCollapsed] = React.useState(!collapsedLength || text?.length > collapsedLength);
    
    const dismissable = props.dismissable !== false;

    const icon = (() => {
        if (props.icon) {
            return props.icon;
        }
        switch (props.type) {
            case 'info':
                return <InfoIcon/>;
            case 'warning':
                return <WarningIcon/>;
            case 'error':
                return <ErrorIcon/>;
            case 'success':
                return <CheckIcon/>;
            default:
                return null;
        }
    })();

    if (closed) return null;
    return (
        <styles.Wrapper
            animation={animation}
            iconRight={iconRight}
            dismissable={dismissable}
            inline={inline}
            small={small}
            fullWidth={fullWidth}
            type={type}
            style={{...style}}
        >
            {!iconRight && icon}
            <styles.Content>
                {(children || !text) ? children : (
                    <Text>
                        {collapsed ? (
                            <span>
                                {text?.substr(0, collapsedLength)}...&nbsp;&nbsp;
                                <Clickable inline onClick={() => setCollapsed(false)}>{t('readMore')}</Clickable>
                            </span>
                        ) : (
                            <Flex col gap={3}>
                                {text}
                                <Clickable inline onClick={() => setCollapsed(true)}>{t('readLess')}</Clickable>
                            </Flex>
                        )}
                    </Text>
                )}
            </styles.Content>
            {iconRight && icon}
            {!!dismissable && (
                <styles.Clickable onClick={() => setClosed(true)}>
                    <CloseIcon className="closeIcon"/>
                </styles.Clickable>
            )}
        </styles.Wrapper>
    );
}
export default Alert;