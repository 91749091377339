import { fromJS, Map } from 'immutable';
import Contact from '../../utils/models/Contact';
import { CONTACTS_CHANGED, WS_DATA_CONTACT, WS_DATA_CONTACTS } from '../constants/contact.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	fetched: false,
	data: {},
});

function reducer(state: Map = initialState, action) {
	let contacts = state.get('data');
	switch(action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_CONTACT:
			return state.setIn(['data', action.data.id], new Contact(action.data));
		case CONTACTS_CHANGED:
			return state.set('data', action.contacts);
		case WS_DATA_CONTACTS:
			contacts = Map(action.data.map(q => new Contact(q)).map(q => [q.id, q]));
			return state
				.set('data', contacts)
				.set('fetched', true);
		default:
			return state;
	}
}

export default reducer;
