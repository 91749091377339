import { fromJS, Map } from 'immutable';
import Beacon from '../../utils/models/Beacon';
import { SELECT_BEACON, WS_DATA_BEACON, WS_DATA_BEACONS } from '../constants/beacon.constants';
import { AUTH_SUCCESS } from '../../containers/LoginPage/constants';

export const initialState = fromJS({
	data: {},
	fetched: false,
	selectedBeacon: null
});

export default function beaconReducer(state: Map = initialState, action) {
	let beacons = state.get('data');
	switch (action.type) {
		case AUTH_SUCCESS:
			return initialState;
		case WS_DATA_BEACON:
			return state.setIn(['data', action.data.id], new Beacon(action.data));
		case WS_DATA_BEACONS:
			beacons = Map(action.data.map(d => new Beacon(d)).map(d => [d.id, d]));
			return state
				.set('data', beacons)
				.set('fetched', true);
		case SELECT_BEACON:
			return state.set('selectedBeacon', action.beacon);
		default:
			return state;
	}
}