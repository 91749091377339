export const apiBase = () => {
  if(localStorage.getItem("apiBase")) {
    return localStorage.getItem("apiBase");
  }
  if(process.env.REACT_APP_API_BASE) {
    return process.env.REACT_APP_API_BASE;
  }
  return window.location.origin + "/api";
};
export const isLive = () => process.env.REACT_APP_ENV === 'live';
export const isStaging = () => process.env.REACT_APP_ENV === 'staging';
export const isDevelopment = () => process.env.REACT_APP_ENV === 'dev';
export const isLocal = () => process.env.REACT_APP_ENV === 'local';
export const getEnv = () => process.env.REACT_APP_ENV || 'dev';

export const getVersion = () => process.env.REACT_APP_VERSION || '0.0.0';

let whitelabel = null;
export const getWhitelabel = () => {
  if(whitelabel === null) {
    whitelabel = require('../environmentOverride')?.default;
    if(!whitelabel) {
      const whitelabelJson = document.getElementById("environment").innerHTML;
      whitelabel = JSON.parse(whitelabelJson);
    }
  }
  return whitelabel;
};