import React from 'react';
import * as styles from './style';

export default function PageContainer({children}) {
  return (
    <styles.Wrapper className="pageWrapper">
	    <styles.Content>
            {children}
	    </styles.Content>
    </styles.Wrapper>
  );
}