import { Colors } from '../style/colors';
import type Device from './Device';
import type Alarm from './Alarm';
import { moduleIds } from './Module';

export class AlarmHandler {
	key = '';
	logo = '';
	name = '';
	handledByText = '';
	handledByTextPast = '';
	// noinspection JSUnusedGlobalSymbols
	handledByTextShort = '';
	handledByTextShortPast = '';
	handledByTitle = '';


	static Platform= new AlarmHandler({
		key: 'platform',
		logo: 'platform',
		name: 'Platform',
		handledByTitle: 'expired',
		handledByTextPast: 'alarmAutomaticallyClosed',
		handledByTextShortPast: 'alarmAutomaticallyClosed',
	});
	static SequriX= new AlarmHandler({
		key: 'sequrix',
		logo: 'SequriX',
		name: 'SequriX',
		module: moduleIds.SequrixAlarm,
		handledByTitle: 'securityGuard',
		handledByText: 'alarmWillBeClosedByGuard',
		handledByTextPast: 'alarmClosedByGuard',
		handledByTextShort: 'willBeHandledByGuard',
		handledByTextShortPast: 'closedByGuard',
	});
	static ARC = new AlarmHandler({
		key: 'arc',
		logo: 'ARC',
		name: 'ARC',
		handledByTitle: 'alarmCenter',
		handledByText: 'thisAlarmWillBeHandledByAlarmCenter',
		handledByTextPast: 'thisAlarmIsClosedByAlarmCenter',
		handledByTextShort: 'willBeHandledByAlarmCenter',
		handledByTextShortPast: 'isClosedByAlarmCenter'
	});

	constructor(data) {
		if (data) {
			Object.keys(data).forEach(k => {
				this[k] = data[k];
			});
		}
	}

	static forDevAndAlarm(device: Device, alarm: Alarm) {
		if (alarm.handledBy) return alarm.handledBy;
		if(!alarm.arc) return null;
		return AlarmHandler.ARC;
	}

	get color() {
		return Colors.gray60;
	}
}