import Immutable from "immutable";
import dayjs from "dayjs";
import SalesInvoiceItem from "./SalesInvoiceItem";

const SalesInvoiceModel = Immutable.Record({
    id: '',
    customer: '',
    customer_name: '',
    posting_date: '',
    due_date: '',
    net_total: null,
    total: null,
    currency: 'EUR',
    items: [],
    url: '',
    docstatus: 0,
    taxes_and_charges: '',
    payment_terms_template: '',
    subscription_id: 0
});

export default class SalesInvoice extends SalesInvoiceModel {
    id: string;
    customer: string;
    customer_name: string;
    posting_date: string;
    due_date: string;
    net_total: number;
    total: number;
    currency: string;
    items: SalesInvoiceItem[];
    url: string;
    docstatus: number;
    taxes_and_charges: string;
    payment_terms_template: string;
    subscription_id: ?number;

    get isFetched() {
        if(!this.get('id')) return null; //nothing to fetch
        if(!this.get('items').size) return true; //no items anyway
        return !!this.get('items').find(i => !!i.get('item_name')); 
    }
    get taxRate() {
        const template = this.get('taxes_and_charges');
        if(!template) return 0.0;
        const regex = /([0-9]{1,2})%/g;
        const res = regex.exec(template);
        if(!res) return 0.0;
        return (+res[1]) / 100;
    }
    get grossTotal() {
        if(isNaN(this.get('total')) || this.get('total') === null) return '-';
        return parseFloat(this.get('total'));
    }
    get VATAmount() {
        if(isNaN(this.get('total')) || this.get('total') === null) return '-';
        const total = parseFloat(this.get('total'));
        const taxRate = this.taxRate;
        return total * taxRate;
    }
    get netTotal() {
        if(isNaN(this.get('total')) || this.get('total') === null) return '-';
        const total = parseFloat(this.get('total'));
        const taxRate = this.taxRate;
        return total + (total * taxRate);
    }
    get currencySign() {
        return {
            'EUR': '€'
        }[this.get('currency')] || this.get('currency');
    }

    get postingDate() {
        if (!this.get('posting_date')) return null;
        return dayjs(this.get('posting_date'));
    }

    get isSubmitted() {
        return this.get('docstatus') === 1;
    }
    get status() {
        return this.isSubmitted ? 'invoice.submitted' : 'invoice.draft';
    }

    get filterString() {
        return JSON.stringify([
            this.get('id'),
            this.get('customer'),
            this.get('customer_name'),
            this.get('status')
        ]).lc();
    }
}