import Immutable from 'immutable';
import Contact from './Contact';

const ProtocolModel = Immutable.Record({
	id: 0,
	name: '',
	safeWord: '',
	panicWord: '',
	listenTimeout: 15,
	instructions: '',
	environment: '',
	guardianIds: [],
	accountId: 0,
	isDefault: false
});

export default class Protocol extends ProtocolModel {
	id: number;
	name: string;
	safeWord: string;
	panicWord: string;
	listenTimeout: number;
	instructions: string;
	environment: string;
	guardianIds: number[];
	accountId: number;
	isDefault: boolean;

	canBeDefault(allContacts) {
		if(!this.get('environment')) return false; //Instruction must be filled
		const guardians = this.get('guardianIds').map(gId => allContacts.find(g => g.id === gId));
		const availability = Contact.mergeAvailability(guardians);
		const alwaysSomeoneAvailable = !availability.flat().filter(s => !s).length // 24/7 availability
		return alwaysSomeoneAvailable;
		
	}

	clone() {
		return this.set('id', this.id);
	}

	static create() {
		return new Protocol({});
	}
}
