import { fromJS } from 'immutable';
import { SET_OPTIONS, SET_PAGE_TYPE } from './constants';

export const initialState = fromJS({
	options: null,
	pageType: null
});

function reducer(state: Map = initialState, action) {
	switch (action.type) {
		case SET_PAGE_TYPE:
			return state.set('pageType', action.pageType);
		case SET_OPTIONS:
			return state.set('options', action.options);
		default: return state;
	}
}

export default reducer;
