import { SHOW_ALERT } from './constants';

export const showAlert = (text,title,opts) => ({type: SHOW_ALERT, alert: {text,title,opts} });
export const confirmDelete = (t,name,onConfirm) => ({type: SHOW_ALERT, alert: {
    text: t('deleteItem?', {name}),
        title: t('areYouSure?'),
        opts: {
            onReject: () => {},
            rejectText: t('cancel'),
            confirmText: t('delete'),
            onConfirm
        }
} });