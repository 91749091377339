import styled from 'styled-components';
import {contrastColor, darken} from '../../utils/style/colors';

export const Wrapper = styled.div`${props => `
	
	display: inline-block;
	margin: 2px;
	overflow: hidden;
	
	* {
		font-size: 8pt;
	}
	
	.MuiChip-icon {
		margin-right: 1px;
	}
	
	${props.disabled && `
		opacity: .5;
	`};
	
	${props.small && `
		vertical-align: bottom;
	`};
	
	${props.collapse && `
		> div:not(:hover) {
			max-width: 19px;
			span {
				opacity: 0;
			}
		}
	`};
	
	> div {
		height: 32px;
		
		${props.collapsible && `max-width: 140px;`};
		
		transition: all .3s;
		
		${props.small && `
			height: ${props.medium ? 28 : 18}px;
			span {
				padding: 0 ${props.onDelete ? 10 : 5}px;
				transition: all .3s;
			}
			.avatar {
				transform: scale(.7);
				width: 15px;
				margin-left: 1px;
    		margin-right: 7px;
			}
		`};
		
		text-transform: uppercase;
		
		${props.color && `
			background-color: ${props.color} !important;
			color: ${contrastColor(props.color)} !important;
			
			${props.clickable && `
				&:hover {
					background-color: ${darken(props.color, 1.5)} !important;
				}
			`};
		`};
		
	}
`}`;