import React from 'react';
import {withTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Flex} from '../Container/Flex';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Secondary} from '../../utils/style/texts';
import * as Sentry from '@sentry/browser';
import {getLocaleInfo, languages, locales} from '../../../i18n';
import {Colors} from '../../utils/style/colors';
import {Menu, MenuItem, Select, Tooltip} from '@mui/material';
import Flag from '../flag';
import {IconButton} from '../IconButton';
import {selectCurrentUser} from '../../redux/selectors/app.selectors';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

export const FlagType = {
    flag: 'flag',
    short: 'short',
    full: 'full'
};

class LanguageSelector extends React.PureComponent {

    state = {
        anchorEl: null,
        tooltipOpen: false,
        menuOpen: false
    }

    languageLabel(lang, flag, type) {
        if (!lang) return null;
        if (!type) type = this.props.type || FlagType.short;
        return (
            <Flex align="center">
                <Flag className="flagIcon" flag={flag}/>
                {type !== FlagType.flag && (
                    <>
                        &nbsp;
                        {type === FlagType.short && lang.uc()}
                        {type === FlagType.full && getLocaleInfo(lang).name}
                    </>
                )}
            </Flex>
        );
    }

    options(onClick) {
        const langs = this.props.langs;
        return languages
            .filter(l => l.isEnabled(l))
            .filter(l => !langs || langs.indexOf(l.key) >= 0)
            .map(l => (
                <MenuItem
                    value={l.lang}
                    key={l.lang}
                    onClick={() => {
                        if (onClick) onClick(l);
                    }}>
                    {this.languageLabel(l.key, l.flag, FlagType.full)}
                </MenuItem>
            ));
    }

    render() {
        try {
            const {
                onChange,
                lang,
                label,
                t,
                disabled,
                user,
                fullWidth,
                asFormField,
                nativeLabel,
                type,
                ...other
            } = this.props;

            if (user?.get && user.get('languages')?.length && !localStorage.getItem('extraLangs')) {
                localStorage.setItem('extraLangs', JSON.stringify(user.get('languages')));
                window.location.reload();
            }

            const selectControl = (
                <Select
                    size="small"
                    className="select"
                    label={nativeLabel}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    variant="standard"
                    onMouseEnter={() => {
                        if (this.state.menuOpen) return;
                        this.setState({tooltipOpen: true})
                    }}
                    onMouseLeave={() => this.setState({tooltipOpen: false})}
                    onOpen={() => this.setState({tooltipOpen: false, menuOpen: true})}
                    onClose={() => this.setState({tooltipOpen: false, menuOpen: false})}
                    value={locales[lang] || lang}
                    renderValue={() => {
                        const info = getLocaleInfo(lang);
                        return this.languageLabel(info?.key, info?.flag);
                    }}
                    onChange={e => onChange(e.target.value)}
                >
                    {this.options()}
                </Select>
            );

            return (
                <Wrapper {...other} fullWidth={fullWidth} disabled={disabled}>
                    <label>
                        {label && (
                            <Secondary>{label}</Secondary>
                        )}
                        {asFormField ? (
                            <FormControl variant="standard" fullWidth={fullWidth}>
                                <InputLabel>{t('language')}</InputLabel>
                                {selectControl}
                            </FormControl>
                        ) : (
                            <Tooltip
                                open={this.state.tooltipOpen}
                                title="Change language">
                                {type === FlagType.flag ? (
                                    <div>
                                        <IconButton
                                            aria-controls="languageMenu"
                                            aria-haspopup="true"
                                            onClick={e => this.setState({anchorEl: e.currentTarget})}
                                            size="large">
                                            <Flag className="flagIcon" flag={getLocaleInfo(lang)?.flag}/>
                                        </IconButton>
                                        <Menu
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={!!this.state.anchorEl}
                                            onClose={() => this.setState({anchorEl: null})}
                                        >
                                            {this.options(l => {
                                                this.setState({anchorEl: null});
                                                onChange(l.lang);
                                            })}
                                        </Menu>
                                    </div>
                                ) : selectControl}
                            </Tooltip>
                        )}
                    </label>
                </Wrapper>
            );
        } catch (e) {
            Sentry.captureException(e);
        }
        return null;
    }

}

const Wrapper = styled.div`
    display: inline-block;
    width: ${props => props.fullWidth ? '100%' : 'auto'};;

    div:not(.flagIcon) {
        width: ${props => props.fullWidth ? '100%' : 'auto'};;
        min-width: auto;
    }

    label {
        display: flex;
        align-items: center;

        > span {
            margin-right: 5px;
        }
    }

    ${props => props.disabled && props.appearValueWhenDisabled && `
		*:before {
			border-bottom-style: none !important;
		}
		svg { display: none; }
		* {
			color: ${Colors.gray60} !important;
		}
	`};
    ${props => props.small && `
		transform: scale(.7);
	`};
    ${props => props.invert && `
	  filter: invert(1);
	  .flagIcon {
	    filter: invert(1);
	  }
	`};
`;

function mapDispatchToProps() {
    return {};
}

const mapStateToProps = createStructuredSelector({
    user: selectCurrentUser(),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
)(LanguageSelector);
