import MDIconWrapper from '@mdi/react';
import {
    mdiAccessPoint,
    mdiAccountCogOutline,
    mdiAccountCowboyHat,
    mdiAccountHeartOutline,
    mdiAccountMultipleOutline,
    mdiAccountTieVoiceOutline,
    mdiAlarmLightOutline,
    mdiAlarmNote,
    mdiAlertOctagonOutline,
    mdiAlertOctagramOutline,
    mdiAlertRhombusOutline,
    mdiAndroid,
    mdiApple,
    mdiArchiveArrowUpOutline,
    mdiArchiveOutline,
    mdiArrowLeft,
    mdiArrowRight,
    mdiCellphoneMessage,
    mdiArrowUpBoldHexagonOutline,
    mdiBallotOutline,
    mdiBatteryAlert,
    mdiBatteryChargingMedium,
    mdiBatteryHigh,
    mdiBellAlertOutline,
    mdiBellCancelOutline,
    mdiBellCheckOutline,
    mdiBellOutline,
    mdiBellPlusOutline,
    mdiBellRingOutline,
    mdiBluetooth,
    mdiBluetoothConnect,
    mdiBug,
    mdiCalendarClock,
    mdiCallReceived,
    mdiCardTextOutline,
    mdiCashMultiple,
    mdiCellphone,
    mdiCellphoneRemove,
    mdiCellphoneSettings,
    mdiChatAlertOutline,
    mdiCheck,
    mdiCheckboxBlankCircleOutline,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiCheckCircle,
    mdiChevronDoubleLeft,
    mdiChevronDown,
    mdiChevronUp,
    mdiCircleSlice1,
    mdiClipboardAccountOutline,
    mdiClipboardAlertOutline,
    mdiClipboardListOutline,
    mdiClockAlertOutline,
    mdiClose,
    mdiCloseCircleOutline,
    mdiCogOutline,
    mdiCommentMultipleOutline,
    mdiCommentQuestionOutline,
    mdiContentCopy,
    mdiContentSaveCogOutline,
    mdiContentSaveOutline,
    mdiCrosshairsGps,
    mdiDatabase,
    mdiDeleteOutline,
    mdiDevices,
    mdiDotsVertical,
    mdiDownloadOutline,
    mdiDrag,
    mdiEmailOutline,
    mdiEyeOffOutline,
    mdiEyeOutline,
    mdiFaceAgent,
    mdiFaceMan,
    mdiFileChartOutline,
    mdiFilterOutline,
    mdiFolderKeyNetworkOutline,
    mdiFolderOpenOutline,
    mdiFrequentlyAskedQuestions,
    mdiGestureTap,
    mdiGoogle,
    mdiGoogleTranslate,
    mdiHandshakeOutline,
    mdiHeartPulse,
    mdiHelp,
    mdiHistory,
    mdiHomeOutline,
    mdiInformationOutline,
    mdiIobroker,
    mdiLayersOutline,
    mdiLink,
    mdiLocationEnter,
    mdiLocationExit,
    mdiLockOutline,
    mdiLockReset,
    mdiLogin,
    mdiLogout,
    mdiMagnify,
    mdiMap,
    mdiMapMarkerAlertOutline,
    mdiMapMarkerCheckOutline,
    mdiMapMarkerOutline,
    mdiMapMarkerRadiusOutline,
    mdiMenu,
    mdiMessageArrowRightOutline,
    mdiMessageTextOutline,
    mdiMinusCircle,
    mdiMotionPauseOutline,
    mdiMusicNoteOutline,
    mdiNecklace,
    mdiNetworkOutline,
    mdiNumeric1BoxOutline,
    mdiNumeric2BoxOutline,
    mdiNumeric3BoxOutline,
    mdiNumeric4BoxOutline,
    mdiNumeric5BoxOutline,
    mdiOpenInNew,
    mdiPencilOutline,
    mdiPhoneInTalkOutline,
    mdiPhoneMessageOutline,
    mdiPhoneOutline,
    mdiPlayCircleOutline,
    mdiPlus,
    mdiPower,
    mdiPrinter,
    mdiProgressClock,
    mdiProgressQuestion,
    mdiPuzzleOutline,
    mdiRadioTower,
    mdiRefresh,
    mdiReload,
    mdiReplay,
    mdiRestart,
    mdiRotateOrbit,
    mdiRun,
    mdiSack,
    mdiSatellite,
    mdiSendCircle,
    mdiSendOutline,
    mdiShareOutline,
    mdiShieldAccount,
    mdiShieldLockOutline,
    mdiSignalOff,
    mdiSimOutline,
    mdiSleep,
    mdiSleepOff,
    mdiSourceBranch,
    mdiSpaceInvaders,
    mdiTagOutline,
    mdiTelevisionGuide,
    mdiTextBox,
    mdiTimelineClockOutline,
    mdiTimerOutline,
    mdiToggleSwitchOutline,
    mdiTranslate,
    mdiTuneVertical,
    mdiTwoFactorAuthentication,
    mdiUndo,
    mdiUploadOutline,
    mdiVectorCircleVariant,
    mdiVectorPolygon,
    mdiVibrate,
    mdiViewDashboardOutline,
    mdiVolumeHigh,
    mdiVolumeOff,
    mdiWalletGiftcard,
    mdiWeb,
    mdiWebhook,
    mdiWifi,
    mdiWifiOff,
    mdiWifiStar,
    mdiInboxMultiple,
    mdiShoppingOutline,
    mdiSatelliteUplink,
    mdiSortAscending,
    mdiCellphoneDock,
    mdiCrosshairsQuestion,
    mdiWallFire,
    mdiLeaf,
    mdiFlagOutline,
    mdiCheckboxMarkedCircleOutline,
    mdiFilePdfBox,
    mdiCardAccountPhoneOutline,
    mdiPriorityHigh,
    mdiCashRegister,
    mdiFileDocumentCheckOutline,
    mdiFileDocumentOutline,
    mdiFileSyncOutline,
    mdiArchivePlusOutline,
    mdiArchiveSettingsOutline,
    mdiMapMarkerDown,
    mdiOfficeBuilding,
    mdiTimelapse,
    mdiBatteryOutline,
    mdiBatteryMedium,
    mdiPhoneCancelOutline,
    mdiTable,
    mdiViewStream,
    mdiPresentation,
    mdiContentSaveAlertOutline,
    mdiSwapHorizontal,
    mdiTools,
    mdiBatteryOffOutline,
    mdiAutoFix,
    mdiMicrosoftExcel,
    mdiNfc,
    mdiPhoneIncomingOutline,
    mdiPhoneRingOutline,
    mdiBookAccountOutline,
    mdiServerNetwork,
    mdiAccountPlusOutline,
    mdiFactory,
    mdiPencilPlusOutline,
    mdiDialpad,
    mdiBullhornOutline, mdiWindowMaximize, mdiWindowMinimize, mdiPaletteOutline, mdiImage, mdiCog
} from '@mdi/js';
import React from 'react';
import {Colors} from './style/colors';
import {Tooltip} from "@mui/material";
import {mdiClipboardTextClockOutline} from "@mdi/js/commonjs/mdi";

const md = ({...props}) => {
    if (props.color === 'action') props.color = Colors.actionIcon;
    if (props.color === 'primary') props.color = Colors.primary();
    if (props.color === 'secondary') props.color = Colors.secondary();
    if (props.color === 'inherit') props.color = undefined;
    return props;
}

const MDIcon = props => {
    const icon = <MDIconWrapper size={.8} {...md(props)} />;
    if (props.tooltip) {
        return (
            <Tooltip title={props.tooltip}>
                <span style={{lineHeight: 0}}>
                    {icon}
                </span>
            </Tooltip>
        );
    }
    return icon;
}

export const AGPSIcon = props => <MDIcon {...props} path={mdiFlagOutline}/>;
export const AcceptedIcon = props => <MDIcon {...props} path={mdiCheckCircle}/>;
export const AccountIcon = props => <MDIcon {...props} path={mdiAccountMultipleOutline}/>;
export const AccountManagerIcon = props => <MDIcon {...props} path={mdiClipboardAccountOutline}/>;
export const ActionsIcon = props => <MDIcon {...props} path={mdiBallotOutline}/>;
export const ActivateIcon = props => <MDIcon {...props} path={mdiPower}/>;
export const AddressBookIcon = props => <MDIcon {...props} path={mdiBookAccountOutline}/>;
export const AdministrationIcon = props => <MDIcon {...props} path={mdiInboxMultiple}/>;
export const AlarmCenterIcon = props => <MDIcon {...props} path={mdiFaceAgent}/>;
export const AlarmIcon = props => <MDIcon {...props} path={mdiBellRingOutline}/>;
export const AlarmPlusIcon = props => <MDIcon {...props} path={mdiBellPlusOutline}/>;
export const AlarmVolumeIcon = props => <MDIcon {...props} path={mdiBellOutline}/>;
export const AndroidIcon = props => <MDIcon {...props} path={mdiAndroid}/>;
export const AngleIcon = props => <MDIcon {...props} path={mdiRotateOrbit}/>;
export const AppIcon = props => <MDIcon {...props} path={mdiCellphone}/>;
export const AppleIcon = props => <MDIcon {...props} path={mdiApple}/>;
export const ArchiveIcon = props => <MDIcon {...props} path={mdiArchiveOutline}/>;
export const ArrowLeftIcon = props => <MDIcon {...props} path={mdiArrowLeft}/>;
export const ArrowRightIcon = props => <MDIcon {...props} path={mdiArrowRight}/>;
export const AssistanceRequestIcon = props => <MDIcon {...props} path={mdiFrequentlyAskedQuestions}/>;
export const AutoFixIcon = props => <MDIcon {...props} path={mdiAutoFix}/>;
export const BTIcon = props => <MDIcon {...props} path={mdiBluetooth}/>;
export const BTSearchIcon = props => <MDIcon {...props} path={mdiBluetoothConnect}/>;
export const BackIcon = ArrowLeftIcon;
export const BatteryAlertIcon = props => <MDIcon {...props} path={mdiBatteryAlert}/>;
export const BatteryChargingIcon = props => <MDIcon {...props} path={mdiBatteryChargingMedium}/>;
export const BatteryFullIcon = props => <MDIcon {...props} path={mdiBatteryHigh}/>;
export const BatteryHalfIcon = props => <MDIcon {...props} path={mdiBatteryMedium}/>;
export const BatteryIcon = props => <MDIcon {...props} path={mdiBatteryHigh}/>;
export const BatteryOffOutlineIcon = props => <MDIcon {...props} path={mdiBatteryOffOutline}/>;
export const BatteryOutlineIcon = props => <MDIcon {...props} path={mdiBatteryOutline}/>;
export const BeaconIcon = props => <MDIcon {...props} path={mdiAccessPoint}/>;
export const Button1Icon = props => <MDIcon {...props} path={mdiNumeric1BoxOutline}/>;
export const Button2Icon = props => <MDIcon {...props} path={mdiNumeric2BoxOutline}/>;
export const Button3Icon = props => <MDIcon {...props} path={mdiNumeric3BoxOutline}/>;
export const Button4Icon = props => <MDIcon {...props} path={mdiNumeric4BoxOutline}/>;
export const Button5Icon = props => <MDIcon {...props} path={mdiNumeric5BoxOutline}/>;
export const ButtonIcon = props => <MDIcon {...props} path={mdiGestureTap}/>;
export const CallerIdIcon = props => <MDIcon {...props} path={mdiCardAccountPhoneOutline}/>;
export const CallOutIcon = props => <MDIcon {...props} path={mdiBullhornOutline}/>;
export const CancelAlarmIcon = props => <MDIcon {...props} path={mdiBellCancelOutline}/>;
export const CancelIcon = props => <MDIcon {...props} path={mdiClose}/>;
export const CardsIcon = props => <MDIcon {...props} path={mdiViewStream}/>;
export const CautionIcon = props => <MDIcon {...props} path={mdiChatAlertOutline}/>;
export const CheckBoxCheckedIcon = props => <MDIcon {...props} path={mdiCheckboxMarked}/>;
export const CheckBoxUncheckedIcon = props => <MDIcon {...props} path={mdiCheckboxBlankOutline}/>;
export const CheckIcon = props => <MDIcon {...props} path={mdiCheck}/>;
export const ChevronDoubleLeftIcon = props => <MDIcon {...props} path={mdiChevronDoubleLeft}/>;
export const ClearFilterIcon = props => <MDIcon {...props} path={mdiCloseCircleOutline}/>;
export const CloseIcon = props => <MDIcon {...props} path={mdiClose}/>;
export const CollapseIcon = props => <MDIcon {...props} path={mdiChevronUp}/>;
export const CommunicationIcon = props => <MDIcon {...props} path={mdiCommentMultipleOutline}/>;
export const ConfigIcon = props => <MDIcon {...props} path={mdiCogOutline}/>;
export const ConfigCustomIcon = props => <MDIcon {...props} path={mdiCog}/>;
export const ConnectDeviceIcon = props => <MDIcon {...props} path={mdiNetworkOutline}/>;
export const ConnectedIcon = props => <MDIcon {...props} path={mdiWifi}/>;
export const CopyIcon = props => <MDIcon {...props} path={mdiContentCopy}/>;
export const AddToArchiveIcon = props => <MDIcon {...props} path={mdiArchivePlusOutline}/>;
export const CowboyIcon = props => <MDIcon {...props} path={mdiAccountCowboyHat}/>;
export const CrashIcon = props => <MDIcon {...props} path={mdiSpaceInvaders}/>;
export const CreateUserIcon = props => <MDIcon {...props} path={mdiAccountPlusOutline}/>;
export const CreditsIcon = props => <MDIcon {...props} path={mdiSack}/>;
export const DashboardIcon = props => <MDIcon {...props} path={mdiViewDashboardOutline}/>;
export const DebugIcon = props => <MDIcon {...props} path={mdiBug}/>;
export const DeleteIcon = props => <MDIcon {...props} path={mdiDeleteOutline}/>;
export const DeviceIcon = props => <MDIcon {...props} path={mdiCellphone}/>;
export const DevicesIcon = props => <MDIcon {...props} path={mdiDevices}/>;
export const DiagnosticsIcon = props => <MDIcon {...props} path={mdiHeartPulse}/>;
export const DialPadIcon = props => <MDIcon {...props} path={mdiDialpad}/>;
export const DisconnectedIcon = props => <MDIcon {...props} path={mdiWifiOff}/>;
export const DisplayIcon = props => <MDIcon {...props} path={mdiCardTextOutline}/>;
export const DockingIcon = props => <MDIcon {...props} path={mdiCellphoneDock}/>;
export const DownloadIcon = props => <MDIcon {...props} path={mdiDownloadOutline}/>;
export const DragIcon = props => <MDIcon {...props} path={mdiDrag}/>;
export const DrawCircleIcon = props => <MDIcon {...props} path={mdiVectorCircleVariant}/>;
export const DrawPolygonIcon = props => <MDIcon {...props} path={mdiVectorPolygon}/>;
export const DuplicateIcon = props => <MDIcon {...props} path={mdiContentCopy}/>;
export const EcoIcon = props => <MDIcon {...props} path={mdiLeaf}/>;
export const EditIcon = props => <MDIcon {...props} path={mdiPencilOutline}/>;
export const EmailIcon = props => <MDIcon {...props} path={mdiEmailOutline}/>;
export const ESPAIcon = props => <MDIcon {...props} path={mdiServerNetwork}/>;
export const ExcelIcon = props => <MDIcon {...props} path={mdiMicrosoftExcel}/>;
export const BuildingIcon = props => <MDIcon {...props} path={mdiOfficeBuilding}/>;
export const ErrorIcon = props => <MDIcon {...props} path={mdiAlertRhombusOutline}/>;
export const EventIcon = props => <MDIcon {...props} path={mdiAlarmLightOutline}/>;
export const ExpandIcon = props => <MDIcon {...props} path={mdiChevronDown}/>;
export const ExpiredIcon = props => <MDIcon {...props} path={mdiClockAlertOutline}/>;
export const ExternalLinkIcon = props => <MDIcon {...props} path={mdiOpenInNew}/>;
export const EyeIcon = props => <MDIcon {...props} path={mdiEyeOutline}/>;
export const FactoryIcon = props => <MDIcon {...props} path={mdiFactory}/>;
export const FilterIcon = props => <MDIcon {...props} path={mdiFilterOutline}/>;
export const FinanceIcon = props => <MDIcon {...props} path={mdiCashMultiple}/>;
export const FirewallIcon = props => <MDIcon {...props} path={mdiWallFire}/>;
export const ForceSaveIcon = props => <MDIcon {...props} path={mdiContentSaveAlertOutline}/>;
export const GPSIcon = props => <MDIcon {...props} path={mdiSatelliteUplink}/>;
export const GeofenceIcon = props => <MDIcon {...props} path={mdiMapMarkerRadiusOutline}/>;
export const GoogleIcon = props => <MDIcon {...props} path={mdiGoogle}/>;
export const GoogleTranslateIcon = props => <MDIcon {...props} path={mdiGoogleTranslate}/>;
export const ContactIcon = props => <MDIcon {...props} path={mdiShieldAccount}/>;
export const HelpIcon = props => <MDIcon {...props} path={mdiCommentQuestionOutline}/>;
export const HistoryIcon = props => <MDIcon {...props} path={mdiHistory}/>;
export const HomeIcon = props => <MDIcon {...props} path={mdiHomeOutline}/>;
export const IOIcon = props => <MDIcon {...props} path={mdiIobroker}/>;
export const IframeIcon = props => <MDIcon {...props} path={mdiWeb}/>;
export const ImageIcon = props => <MDIcon {...props} path={mdiImage}/>;
export const ImpactIcon = props => <MDIcon {...props} path={mdiAlertOctagramOutline}/>;
export const IncompleteDeviceIcon = props => <MDIcon {...props} path={mdiCellphoneSettings}/>;
export const InfoIcon = props => <MDIcon {...props} path={mdiInformationOutline}/>;
export const LinkIcon = props => <MDIcon {...props} path={mdiLink}/>;
export const ListIcon = props => <MDIcon {...props} path={mdiClipboardListOutline}/>;
export const LoadIcon = props => <MDIcon {...props} path={mdiFolderOpenOutline}/>;
export const LoadingIcon = props => <MDIcon {...props} path={mdiCircleSlice1} spin/>;
export const LocateIcon = props => <MDIcon {...props} path={mdiCrosshairsGps}/>;
export const FixedPositionIcon = props => <MDIcon {...props} path={mdiMapMarkerDown}/>;
export const LanguageIcon = props => <MDIcon {...props} path={mdiWeb}/>;
export const LocateQuestionIcon = props => <MDIcon {...props} path={mdiCrosshairsQuestion}/>;
export const LocationEnterIcon = props => <MDIcon {...props} path={mdiLocationEnter}/>;
export const LocationExitIcon = props => <MDIcon {...props} path={mdiLocationExit}/>;
export const LocationIcon = props => <MDIcon {...props} path={mdiMapMarkerOutline}/>;
export const LockIcon = props => <MDIcon {...props} path={mdiLockOutline}/>;
export const LoginIcon = props => <MDIcon {...props} path={mdiLogin}/>;
export const LogoutIcon = props => <MDIcon {...props} path={mdiLogout}/>;
export const MapIcon = props => <MDIcon {...props} path={mdiMap}/>;
export const MenuDotsIcon = props => <MDIcon {...props} path={mdiDotsVertical}/>;
export const MenuIcon = props => <MDIcon {...props} path={mdiMenu}/>;
export const MinimizeIcon = props => <MDIcon {...props} path={mdiWindowMinimize}/>;
export const MaximizeIcon = props => <MDIcon {...props} path={mdiWindowMaximize}/>;
export const ModulesIcon = props => <MDIcon {...props} path={mdiPuzzleOutline}/>;
export const MyAccountIcon = props => <MDIcon {...props} path={mdiFaceMan}/>;
export const NewSettingIcon = props => <MDIcon {...props} path={mdiPencilPlusOutline}/>;
export const NFCIcon = props => <MDIcon {...props} path={mdiNfc}/>;
export const NoAppIcon = props => <MDIcon {...props} path={mdiCellphoneRemove}/>;
export const NoSignalIcon = props => <MDIcon {...props} path={mdiSignalOff}/>;
export const OutOfOfficeIcon = props => <MDIcon {...props} path={mdiCalendarClock}/>;
export const PDFIcon = props => <MDIcon {...props} path={mdiFilePdfBox}/>;
export const PauseIcon = props => <MDIcon {...props} path={mdiMotionPauseOutline}/>;
export const PhoneAnswerIcon = props => <MDIcon {...props} path={mdiPhoneIncomingOutline}/>;
export const PhoneIcon = props => <MDIcon {...props} path={mdiPhoneOutline}/>;
export const PhoneRingIcon = props => <MDIcon {...props} path={mdiPhoneRingOutline}/>;
export const PhoneVolumeIcon = props => <MDIcon {...props} path={mdiPhoneInTalkOutline}/>;
export const PlayIcon = props => <MDIcon {...props} path={mdiPlayCircleOutline}/>;
export const PlusIcon = props => <MDIcon {...props} path={mdiPlus}/>;
export const CreateFromArchiveIcon = props => <MDIcon {...props} path={mdiArchiveSettingsOutline}/>;
export const PositionErrorIcon = props => <MDIcon {...props} path={mdiMapMarkerAlertOutline}/>;
export const PositionErrorOffIcon = props => <MDIcon {...props} path={mdiMapMarkerCheckOutline}/>;
export const PowerIcon = props => <MDIcon {...props} path={mdiPower}/>;
export const PreAlarmDurationIcon = props => <MDIcon {...props} path={mdiAlarmNote}/>;
export const PresentationModeIcon = props => <MDIcon {...props} path={mdiPresentation}/>;
export const PricingIcon = props => <MDIcon {...props} path={mdiCashRegister}/>;
export const PrintIcon = props => <MDIcon {...props} path={mdiPrinter}/>;
export const PriorityIcon = props => <MDIcon {...props} path={mdiPriorityHigh}/>;
export const ProgressQuestionIcon = props => <MDIcon {...props} path={mdiProgressQuestion}/>;
export const PromsIcon = props => <MDIcon {...props} path={mdiFolderKeyNetworkOutline}/>;
export const ProtocolsIcon = props => <MDIcon {...props} path={mdiClipboardAlertOutline}/>;
export const PwdResetIcon = props => <MDIcon {...props} path={mdiLockReset}/>;
export const QuestionIcon = props => <MDIcon {...props} path={mdiHelp}/>;
export const QuickTextsIcon = props => <MDIcon {...props} path={mdiMessageArrowRightOutline}/>;
export const RadioSignalIcon = props => <MDIcon {...props} path={mdiRadioTower}/>;
export const RebootIcon = props => <MDIcon {...props} path={mdiRestart}/>;
export const ReceivedIcon = props => <MDIcon {...props} path={mdiCallReceived}/>;
export const RefreshIcon = props => <MDIcon {...props} path={mdiRefresh}/>;
export const SubmitIcon = props => <MDIcon {...props} path={mdiFileDocumentCheckOutline}/>;
export const SubscriptionIcon = props => <MDIcon {...props} path={mdiFileSyncOutline}/>;
export const InvoiceIcon = props => <MDIcon {...props} path={mdiFileDocumentOutline}/>;
export const RejectedIcon = props => <MDIcon {...props} path={mdiMinusCircle}/>;
export const ReloadIcon = props => <MDIcon {...props} path={mdiReload}/>;
export const ReportsIcon = props => <MDIcon {...props} path={mdiFileChartOutline}/>;
export const ResetIcon = props => <MDIcon {...props} path={mdiReplay}/>;
export const ReplaceIcon = props => <MDIcon {...props} path={mdiSwapHorizontal}/>;
export const RingToneIcon = props => <MDIcon {...props} path={mdiMusicNoteOutline}/>;
export const RipcordIcon = props => <MDIcon {...props} path={mdiNecklace}/>;
export const RunIcon = props => <MDIcon {...props} path={mdiRun}/>;
export const SMSIcon = props => <MDIcon {...props} path={mdiMessageTextOutline}/>;
export const SOSButtonIcon = props => <MDIcon {...props} path={mdiBellAlertOutline}/>;
export const SalesItemsIcon = props => <MDIcon {...props} path={mdiShoppingOutline}/>;
export const SatelliteIcon = props => <MDIcon {...props} path={mdiSatellite}/>;
export const SaveConfigIcon = props => <MDIcon {...props} path={mdiContentSaveCogOutline}/>;
export const SaveIcon = props => <MDIcon {...props} path={mdiContentSaveOutline}/>;
export const ScenarioIcon = props => <MDIcon {...props} path={mdiSourceBranch}/>;
export const ScheduledReportIcon = props => <MDIcon {...props} path={mdiClipboardTextClockOutline}/>;
export const SearchIcon = props => <MDIcon {...props} path={mdiMagnify}/>;
export const SecurityIcon = props => <MDIcon {...props} path={mdiShieldLockOutline}/>;
export const SelfTestIcon = props => <MDIcon {...props} path={mdiCheckboxMarkedCircleOutline}/>;
export const EndCallIcon = props => <MDIcon {...props} path={mdiPhoneCancelOutline}/>;
export const SendAppNotificationIcon = props => <MDIcon {...props} path={mdiCellphoneMessage}/>;
export const SendIcon = props => <MDIcon {...props} path={mdiSendOutline}/>;
export const SensitivityIcon = props => <MDIcon {...props} path={mdiTuneVertical}/>;
export const ShareIcon = props => <MDIcon {...props} path={mdiShareOutline}/>;
export const SignageIcon = props => <MDIcon {...props} path={mdiTelevisionGuide}/>;
export const SimCardIcon = props => <MDIcon {...props} path={mdiSimOutline}/>;
export const SiteIcon = props => <MDIcon {...props} path={mdiLayersOutline}/>;
export const SleepIcon = props => <MDIcon {...props} path={mdiSleep}/>;
export const SleepOffIcon = props => <MDIcon {...props} path={mdiSleepOff}/>;
export const SortIcon = props => <MDIcon {...props} path={mdiSortAscending}/>;
export const SoundIcon = props => <MDIcon {...props} path={mdiVolumeHigh}/>;
export const SoundOffIcon = props => <MDIcon {...props} path={mdiVolumeOff}/>;
export const StorageIcon = props => <MDIcon {...props} path={mdiDatabase}/>;
export const SwitchIcon = props => <MDIcon {...props} path={mdiToggleSwitchOutline}/>;
export const TableIcon = props => <MDIcon {...props} path={mdiTable}/>;
export const TagIcon = props => <MDIcon {...props} path={mdiTagOutline}/>;
export const TalkIcon = props => <MDIcon {...props} path={mdiAccountTieVoiceOutline}/>;
export const TeamsIcon = props => <MDIcon {...props} path={mdiHandshakeOutline}/>;
export const TelegramIcon = props => <MDIcon {...props} path={mdiSendCircle}/>;
export const TextIcon = props => <MDIcon {...props} path={mdiTextBox}/>;
export const TimeToAlarmIcon = props => <MDIcon {...props} path={mdiTimelineClockOutline}/>;
export const TimerIcon = props => <MDIcon {...props} path={mdiTimerOutline}/>;
export const TimeoutIcon = props => <MDIcon {...props} path={mdiTimelapse}/>;
export const TranslationIcon = props => <MDIcon {...props} path={mdiTranslate}/>;
export const TrialIcon = props => <MDIcon {...props} path={mdiWalletGiftcard}/>;
export const ToolsIcon = props => <MDIcon {...props} path={mdiTools}/>;
export const TwoFAIcon = props => <MDIcon {...props} path={mdiTwoFactorAuthentication}/>;
export const UnarchiveIcon = props => <MDIcon {...props} path={mdiArchiveArrowUpOutline}/>;
export const UndoIcon = props => <MDIcon {...props} path={mdiUndo}/>;
export const UpgradeIcon = props => <MDIcon {...props} path={mdiArrowUpBoldHexagonOutline}/>;
export const UploadIcon = props => <MDIcon {...props} path={mdiUploadOutline}/>;
export const UserConfigurableIcon = props => <MDIcon {...props} path={mdiAccountCogOutline}/>;
export const UsersIcon = props => <MDIcon {...props} path={mdiAccountMultipleOutline}/>;
export const VibrateIcon = props => <MDIcon {...props} path={mdiVibrate}/>;
export const VisibilityIcon = props => <MDIcon {...props} path={mdiEyeOutline}/>;
export const VisibilityOffIcon = props => <MDIcon {...props} path={mdiEyeOffOutline}/>;
export const VoiceMessageIcon = props => <MDIcon {...props} path={mdiPhoneMessageOutline}/>;
export const VolteIcon = props => <MDIcon {...props} path={mdiPhoneInTalkOutline}/>;
export const WaitingForAckIcon = props => <MDIcon {...props} path={mdiCheckboxBlankCircleOutline}/>;
export const WaitingForResponseIcon = props => <MDIcon {...props} path={mdiProgressClock}/>;
export const WarningIcon = props => <MDIcon {...props} path={mdiAlertOctagonOutline}/>;
export const WebhookIcon = props => <MDIcon {...props} path={mdiWebhook}/>;
export const WhiteLabelIcon = props => <MDIcon {...props} path={mdiPaletteOutline}/>;
export const WhiteListIcon = props => <MDIcon {...props} path={mdiAccountHeartOutline}/>;
export const WiFiIcon = props => <MDIcon {...props} path={mdiWifi}/>;
export const WifiStarIcon = props => <MDIcon {...props} path={mdiWifiStar}/>;
export const WikiIcon = props => <MDIcon {...props} path={mdiWeb}/>;
export const WithoutAlarmIcon = props => <MDIcon {...props} path={mdiBellCheckOutline}/>;